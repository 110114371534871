import { Component, Input, OnInit } from '@angular/core';
import { DashboardcoreService } from '../../dashboardcore.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-dashbdashboardcomponent-mytopcontacts',
  templateUrl: './dashbdashboardcomponent-mytopcontacts.component.html',
  styleUrls: ['./dashbdashboardcomponent-mytopcontacts.component.scss']
})
export class DashbdashboardcomponentMytopcontactsComponent implements OnInit {
  contacts = [];
  loadingData = false;
  page = 0;
  selector;
  noData=false

  @Input() dashboard: string | null ;
  

  constructor(
    private dashboardcoreService: DashboardcoreService,
    private methodService: MethodService,
    public applicationInfoService: ApplicationInfoService,
    private axivasTranslateService: AxivasTranslateService,
    public commonService: CommonService    
  ) { }

  ngOnInit(): void {
    this.getDatashboardData();
  }

  getDatashboardData() {
    const params = [];
    let dashboardId = 2;
    params.push({ key: '_max_items', value: 20 });
    

    if (this.applicationInfoService.applicationSettings['isSelfServiceProject'] == 'true') {
      dashboardId = 27;
    } else {
      if (this.applicationInfoService.myBestContactsOnlyAI) {
        params.push({ key: '_min_leadscore', value: this.getMinPepperoniValue() });
        dashboardId = 6;
      }
    }
    
    this.loadingData = true;
    this.dashboardcoreService.getDashboardData(dashboardId, params).then(getDatashboardDataResult => {
      this.contacts = getDatashboardDataResult[0];

      if(this.contacts.length == 0){
        this.noData= false;
      }
      this.loadingData = false;
    }).catch(() => {
      this.loadingData = false;
    });
  }

  loadContact(contact) {
    this.methodService.launchInternalMethod(InternalMethodType.LoadConversionContact, [contact]);
  }

  getMinPepperoniValue() {
    return Number(this.applicationInfoService.applicationSettings['aiLeadscoreMinvalue']);
  }


  checkIfArrayHasAi(array){
      return array.some((item) => item.aiContact || item.aipotential || item.aiSuggestion);
  }

  getAITitle(contact) {
    let returnValue = '';
    if (contact.aisuggestion) {
      returnValue = returnValue.concat(
        this.axivasTranslateService.getTranslationTextForToken('AIMessage.Label.AIWeblead'),
        '\n',
        '\n'
      );
    }
    if (contact.aicontact) {
      returnValue = returnValue.concat(
        this.axivasTranslateService.getTranslationTextForToken('AIMessage.Label.AIContact')
      );
    }
    return returnValue;
  }

  getLeadStateName(leadStateId) {    
    const leadStateItem = this.applicationInfoService.leadstates.toArray().find(leadState => leadState.id == leadStateId);
    if (leadStateItem) {
      return this.axivasTranslateService.getTranslationTextForObject(leadStateItem.lookupTable, 'nameTranslationToken');
    } else {
      return '';
    }
  }

  toggleSearchMode() {
    this.applicationInfoService.myBestContactsOnlyAI = !this.applicationInfoService.myBestContactsOnlyAI;
    this.getDatashboardData();
  }

  showContacts() {
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['contacts', 'contacts']);
  }
}
