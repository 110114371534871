import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-dashboardmaincontainer',
  templateUrl: './dashboardmaincontainer.component.html',
  styleUrl: './dashboardmaincontainer.component.scss'
})
export class DashboardmaincontainerComponent implements OnInit {
  reports = null;
  selectedReport = null;
  dashboards = [];
  
  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.getReports();
    const dashboards = JSON.parse(this.applicationInfoService.applicationSettings['defaultDashboards']);
    dashboards.forEach(dashboard => {
      if (this.userService.hasPermissions(dashboard.requiredRights)) {
        this.dashboards.push(dashboard);
      }
    });
  }
  
  getReports() {
    this.externaldatasourceService.executeExternalDataSource(956).then(getReportsResult => {
      this.reports = getReportsResult;      
    });
  }

  getDashboardDefaultName() {
    const dashboard = this.dashboards.find(dashboard => dashboard.id == this.selectedReport);
    if (dashboard) {
      return dashboard.defaultName;
    } else {
      return '';
    }
  }
}
