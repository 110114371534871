import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrl: './qualification.component.scss'
})

export class QualificationComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  tab = 0;
  enrichementWasAlreadyMade = false;
  enrichmentData = null;
  enrichmentDataDetails = null;
  responseData = null;
  qualificationStates = [];
  userCreditsLeft = 0;
  featureNotFound = null;
  featureId = 35;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    private uiService: UiService,
    private commonService: CommonService,
    private packagefeatureService: PackagefeatureService
  ) {}

  ngOnInit(): void {
    if (this.packagefeatureService.selectedPackageHasFeature(this.featureId) == false) {
      this.featureNotFound = true;
    } else {
      this.featureNotFound = false;
	  }
    
    if (!this.featureNotFound) {
      this.getAlreadyRequestedAccountData();  
      this.getQualificationStates();
      this.getUserCreditsLeft();
  
      this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
        if (event.id == 'refreshQualificationOverviewpage') {
          this.getAlreadyRequestedAccountData();  
        }
        if (event.id == 'refreshCredits') {
          this.getUserCreditsLeft();
        }
      });  
    }
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  enrichAccount() {
    this.applicationInfoService.miscSettings['enrichDataWithExternalInterfaceBrokerInfo'] = {
      resultData: this.responseData,
      logEntry: this.enrichmentData
    }
    this.applicationInfoService.miscSettings['enrichDataWithExternalInterfaceRefId'] = this.applicationInfoService.currentAccount.id;
    this.eventService.showJjPopup('DataInterface.Label.EnrichDataWithExternalInterface', 'echobotinterface', '90');
  }

  getAlreadyRequestedAccountData() {
    this.externaldatasourceService.executeExternalDataSource(983, [10004, this.applicationInfoService.currentAccount.id, 1])
    .then(getAlreadyRequestedAccountDataResult => {
      this.enrichmentData = getAlreadyRequestedAccountDataResult;
      this.enrichmentDataDetails = [];
      if (this.enrichmentData != null) {
        this.enrichementWasAlreadyMade = true;
        let responseData = JSON.parse(this.enrichmentData.brokerResponse);
        if (responseData[0] != undefined) {
          if (responseData[0][0] != undefined) {
            responseData = responseData[0][0];
          }
        }
        this.responseData = responseData;
        Object.keys(responseData).forEach(key => { 
          this.enrichmentDataDetails.push({ key: key, value: responseData[key] }) ;
        });        
      }
    })
  }

  getControlHeight(designControl, substractValue = 0) {
    let controlHeight = Number(this.uiService.getDesignSpanPosition(designControl, 20));
    return (controlHeight - substractValue) + 'px'; 
  }

  getValueContent(value) {
    let returnValue = value;
    if (typeof value === 'object' && value !== null) {
      returnValue = '';
      Object.keys(value).forEach(key => {   
        returnValue = returnValue.concat(value[key], '; ');
      });
      return returnValue;
    } else {
      return returnValue;
    }        
  }

  getQualificationStates() {
    this.qualificationStates = this.commonService.getProjectLookupTableValues(113, 'central');
  }

  updateAccount() {
    this.externaldatasourceService.executeExternalDataSource(800, [
      this.commonService.getModifyArrayBody({
        id : this.applicationInfoService.currentAccount.id,
        qualifyEditingState: this.applicationInfoService.currentAccount.qualifyEditingState
      }, [])
    ])
  }
  
  getUserCreditsLeft() {    
    this.externaldatasourceService.executeExternalDataSource(987, [10004, this.applicationInfoService.userID]).then(getUserCreditsLeftResult => {
      this.userCreditsLeft = getUserCreditsLeftResult;
    });
  }

  loadUrl(url) {
    this.commonService.openUrl(url);
  }
}

