<app-layout [header]="'Dashboard.Label.Updates' | texttransform">

    <div class="dashboardupdates">
        <div class="dashboardupdates__inner">

            <div class="dashboardupdates__tabsMobile">
                <div class="everleadMobileTab">
                    <div class="everleadMobileTab__valueWrapper">
                        <select [(ngModel)]="page" class="selectTab__box">
                            <option [ngValue]="0">{{'WebsiteDashboard.Label.Prospects' | texttransform }}</option>
                            <option [ngValue]="1">{{'WebsiteDashboard.Label.Conversions' | texttransform }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="dashboardupdates__tabs">
                <app-tab svg="calendar-dots" [active]="page == 0" [label]=" 'WebsiteDashboard.Label.Prospects' | texttransform" (onClick)="page = 0"></app-tab>
                <app-tab svg="calendar-dots" [active]="page == 1" [label]=" 'WebsiteDashboard.Label.Conversions' | texttransform" (onClick)="page = 1"></app-tab>
                <!-- <app-tab svg="calendar-dots" [active]="page == 2" [label]=" 'WebsiteDashboard.Label.Reaction' | texttransform" (onClick)="page = 2"></app-tab> -->
            </div>

            <div *ngIf="page === 0" class="dashboardupdates__page">
                <div class="dashboardupdates__page__header">
                    <h2>  {{ 'Dashboards.Label.Webbesucher' | texttransform }}</h2>
                    <app-select-input display="displayName" bind="id" [options]="visitorOptions" 
                        [(ngModel)]="selectorVip" (valueChange)="getVisitor()"></app-select-input> 
                </div>
                <div class="dashboardupdates__page__content">
                    <div *ngIf="visitors && visitors?.jsonData?.length > 0">
                        <app-visitors-list *ngFor="let visitor of visitors?.jsonData; let rowIndex = index" [data]="visitor"
                            [isOdd]="commonService.isOdd(rowIndex)"></app-visitors-list>                        
                    </div>
                    <div *ngIf="visitorsNoData" class="dashboardupdates__page__content__infoLabel">
                        <div >
                            {{ 'General.Label.NoDataAvailable' | texttransform }}<br>
                            <br>
                        </div>
                    </div>
                    <div *ngIf="visitorsLoading" class="dashboardupdates__page__content__infoLabel">
                        <span class="loadingDataSpinner">
                            <br><i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
                        </span>
                    </div>
                   
                </div>
            </div>
            <div *ngIf="page === 1" class="dashboardupdates__page">
                <div class="dashboardupdates__page__header">
                    <h2>  {{ 'Dashboards.Label.Webleads' | texttransform }}</h2>
                    <!-- <app-select-input [options]="[{id:1, name:'neuste'},{id:2, name: 'latest'}]" [(ngModel)]="selector"></app-select-input>  -->
                </div>
                <div class="dashboardupdates__page__content">
                    <div *ngIf=" webleads && webleads.jsonData.length > 0">
                        <app-webleads-list *ngFor="let weblead of webleads.jsonData; let rowIndex = index" [data]="weblead"
                            [isOdd]="commonService.isOdd(rowIndex)"></app-webleads-list>
                    </div>

                    <div *ngIf="webleadsNoData" class="dashboardupdates__page__content__infoLabel">
                        <div >
                            {{ 'General.Label.NoDataAvailable' | texttransform }}<br>
                            <br>
                        </div>
                    </div>
                    <div *ngIf="webleadsLoading" class="dashboardupdates__page__content__infoLabel">
                        <span class="loadingDataSpinner">
                            <br><i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf="page === 2" class="dashboardupdates__page">
                <div class="dashboardupdates__page__header">
                    <h2>  {{ 'WebsiteDashboard.Label.Reaction' | texttransform }}</h2>
                    <!-- <app-select-input [options]="[{id:1, name:'neuste'},{id:2, name: 'latest'}]" [(ngModel)]="selector"></app-select-input>  -->
                </div>
                <div class="dashboardupdates__page__content">
                  
                </div>
            </div>
        </div>

        <div class="footer">
            <app-main-button [label]="getLabelPage()" svg="rocket" (click)="loadSection()"></app-main-button>
        </div>
    </div>

</app-layout>
