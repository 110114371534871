import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { SettingsService } from 'app/shared/service/settings.service';


interface Setting{
  id?: number,
  userId?: number
  value: string | null;
  key:string;
}

@Component({
  selector: 'app-usertoken-distribution',
  templateUrl: './usertoken-distribution.component.html',
  styleUrl: './usertoken-distribution.component.scss'
})
export class UsertokenDistributionComponent implements OnInit{
  constructor(
    private applicationInfoService: ApplicationInfoService,
    private userService: UserService,
    private externaldatasourceService: ExternaldatasourceService,
    private settingsService : SettingsService,
    private eventService : EventService,
    private messagingService:MessagingService,
  ){}

  userid:number | null=null;


  availableUserCredits;
  currentUserSettings;

  selectedSetting:Setting = { key:'BrokerQuota', value:'0' };
  createSetting:boolean = false;
  detailInfoToken= null;

  loading = false;
  loadingDetailInfo;
  noData=false;
  distributionMode = 1
  distributionModes = [
    { id: 1, defaultName: 'UserToken.Label.DistributionModeAddToUser' },
    { id: 2, defaultName: 'UserToken.Label.DistributionModeAddToPool' }
  ];
  maxValue = 0;
  currentValue = 0;
  userData = 0;

  ngOnInit(): void {
    this.userid = this.applicationInfoService.miscSettings['tokenDistribution'];
    this.getcurrentUserSettings();
    this.getDetailedInfo();
    this.getUserQuotaData();
  }



   getcurrentUserSettings(){
    this.loading = true;
     this.settingsService.getGQLUserSettings().then((result) =>{      
      this.loading= false;

      if(result.length > 0 ){
        this.currentUserSettings = result
        const find = this.currentUserSettings.find((setting) => setting.key === 'BrokerQuota');
        if(find){
          this.selectedSetting={ id:find.id, key:find.key, value:find.value }
          this.createSetting=false;
        } else {
          this.createSetting=true;
        }        
      } else {
        this.messagingService.showDefaultWarning('UserToken.Label.NoData', 'No Data');
        this.noData=true;

      }

    }).catch((error) =>{
      console.log('error', error);
      this.loading = false;
      this.noData=true;
    });
  }


  updateUserSetting() {
    let newValue = 0;
    switch(this.distributionMode) {
      case 1:
        newValue = this.userData + this.currentValue;
        break;
      case 2:
        newValue = this.userData - this.currentValue;
        break;
    }
    this.externaldatasourceService.executeExternalDataSource(1022, [
      this.userid,
      'BrokerQuota'.concat(this.applicationInfoService.projectID.toString()),
      Number(newValue)
    ]).then(() => {
      this.getUserQuotaData();
      this.getDetailedInfo();   
    })    
  }


  getDetailedInfo() {
    this.loadingDetailInfo=true;
    this.externaldatasourceService.executeExternalDataSource(1020, [this.userid]).then((detailInfoResult) =>{      
      this.detailInfoToken = detailInfoResult ? detailInfoResult : null
      this.calculateValues();
      this.loadingDetailInfo = false
    }).catch((error) =>{
      this.loadingDetailInfo= false;
    })
  }

  closeWindow(){
    this.eventService.closeJjPopup();
    this.eventService.customEvent.emit('UserTokenDistribution');
  }
  
  setValueToMaxValue() {
    if (this.currentValue > this.maxValue) {
      this.currentValue = this.maxValue;
    }
  }

  calculateValues() {
    this.currentValue = 0;
    switch(this.distributionMode) {
      case 1:
        this.maxValue = this.detailInfoToken.available;
        break;
      case 2:
        this.maxValue = this.detailInfoToken.quotaLeft;
        break;
    }    
  }

  getUserQuotaData() {
    this.externaldatasourceService.executeExternalDataSource(1028, [
      this.userid,
      'BrokerQuota'.concat(this.applicationInfoService.projectID.toString())
    ]).then(getUserQuotaDataResult =>{      
      if (getUserQuotaDataResult != null) {
        this.userData = Number(getUserQuotaDataResult.value);
      }      
    });
  }
}
