import { Injectable } from '@angular/core';
import { ExternaldatasourceService } from './externaldatasource.service';
import { CommonService } from './common.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Injectable({
  providedIn: 'root'
})
export class DuplicatecheckService {
  matrixRows = null;
  minimumScore = 65;
  maxVectorDiff= 0.75;
  quickMode = true;
  indexRadius = 2;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService
  ) {}

  checkAccount(accountData, matrixRows = null) {
    const accountArray = {
      id: 0,
      name: '',
      street: '',
      zipcode: '',
      city: ''
    }
    this.addToAccountArray(accountArray, accountData, 'name', 'name1');
    this.addToAccountArray(accountArray, accountData, 'street', 'street');
    this.addToAccountArray(accountArray, accountData, 'zipcode', 'zipcode');
    this.addToAccountArray(accountArray, accountData, 'city', 'city');

    if (this.commonService.isNullOrUndefined(matrixRows)) {
      this.matrixRows = [ 
        { name: 70, address: 90 }
      ]      
    } else {
      this.matrixRows = matrixRows;
    }

    let matrixJson = JSON.stringify(this.matrixRows);
    matrixJson = matrixJson.replace('"'.concat('name', '"'), 'name');
    matrixJson = matrixJson.replace('"'.concat('address', '"'), 'address');

    this.externaldatasourceService.executeExternalDataSource(1029, [
      this.commonService.getModifyArrayBody(accountArray, []),
      matrixJson,
      this.minimumScore,
      this.maxVectorDiff,
      this.quickMode,
      this.indexRadius
    ])
  }

  addToAccountArray(accountArray, accountData, accountValue, value) {
    if (!this.commonService.isNullOrUndefined(accountData[value])) {
      accountArray[accountValue] = accountData[value];
    }
  }
}
