import { Component, OnInit, Input, OnDestroy, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';
import { WizardService } from '../../wizard/wizard.service';

@Component({
  selector: 'app-jj-popup-item',
  templateUrl: './jj-popup-item.component.html',
  styleUrls: ['./jj-popup-item.component.scss']
})
export class JjPopupItemComponent implements OnInit, OnDestroy, AfterViewInit {
  customEventSubscription: Subscription = new Subscription();
  @Input() popupSize = '80';
  @Input() popupWidth = null;
  @Input() popupHeight = null;
  isExpanded = false;
  showContainer = false;
  @Input() headerText = '';
  @Input() showExpandButton = false;
  innerHTML = null;
  @Input() popupIsClosable = true;
  @Input() id = null;
  @Input() controlId;
  isInitialyMaximized = false;
  isFullSize = false;
  subHeader = null;

 modalState={
  width:null,
  height:null,
  transform:null,
  left:null,
  top:null,
 }

  @ViewChild('everleadPopup') everleadPopup: ElementRef;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private domSanitizer: DomSanitizer,
    private uiService: UiService,
    private commonService: CommonService,
    public axivasTranslateService: AxivasTranslateService,
    public wizardService : WizardService
  ) { }



  // * Commented sections on this component.ts and style.scss are the previous conditions before the unwanted blur 

  ngOnInit() {    

    this.modalState={
      width:null,
      height:null,
      transform:null,
      left:null,
      top:null,
     }


    if (isNaN(this.controlId)) {
      this.uiService.getUIfromID(this.controlId, this.controlId)
      .then(getUIfromIDResult => { this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(getUIfromIDResult); });        
    } else {
      this.showContainer = true; 
    }
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {

      this.modalState={
        width:null,
        height:null,
        transform:null,
        left:null,
        top:null,
       }

      if (event.id == 'resizeJJPopup') {
        this.popupSize = event.value;
        this.setPopupSize(true);
      }
      if (event.id == 'changePopupHeader') {
        this.headerText = event.value;
      }
      if (event.id == 'changePopupSubHeader') {
        this.subHeader = event.value;
      }
      
    });

    this.setPopupSize();



  }

  @HostListener('window:resize', ['$event'])
  onResize(event){
    setTimeout(() => this.centerModal(),0);
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.closeWindowOnKey(event);
  }

  setPopupSize(popupResized = false) {

    if (this.commonService.checkIfStringContainsString(this.popupSize, ';')) {
      const popupSizeSplit = this.popupSize.split(';');
      this.popupWidth = popupSizeSplit[0];
      this.popupHeight = popupSizeSplit[1];    
    } else {
      this.popupWidth = this.popupSize;
      this.popupHeight = this.popupSize;    
    }
    if (this.applicationInfoService.miscSettings['popupFullSize'] == true) {
      this.isFullSize = true;
      this.applicationInfoService.miscSettings['popupFullSize'] = false;
    }
    if (this.popupWidth == 100 && this.popupHeight == 100 && popupResized == false) {
      this.isInitialyMaximized = true;
    }
    
  }

  ngAfterViewInit(): void {
    this.dragElement(document.getElementById('everleadPopup'));
    setTimeout(() => this.centerModal(), 0);
  }

  ngOnDestroy() {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
    this.closePopup();
    this.modalState=null;
  }

  toggleExpand() {

//  ! before
 /*    if (!this.isExpanded) {
      //console.log('!expanded', this.isExpanded)
      document.getElementById('everleadPopup').style.left = '50%';
      document.getElementById('everleadPopup').style.top = '50%';
      this.centerModal();
      if (this.applicationInfoService.isMobile()) {
        document.getElementById('everleadPopup').style.top = 'calc(50% + 30px)';
        //this.centerModal();
      }
    }
    this.isExpanded = !this.isExpanded; */


    const modal = document.getElementById('everleadPopup') as HTMLElement;

    if (!this.isExpanded) {

      modal.style.left='0';
      modal.style.top= '0';
      modal.style.width='100vw';
      modal.style.height='100vh';
      modal.style.transform= `none`;

      if (this.applicationInfoService.isMobile()) {
        document.getElementById('everleadPopup').style.top = 'calc(50% + 30px)';
       
      }

    } else {

      setTimeout(() => this.centerModal(),0);

      /* modal.style.width = this.modalState.width;
      modal.style.height=this.modalState.height;
      modal.style.left= '50%';
      modal.style.top='50%';
      modal.style.transform = this.modalState.transform; */
    }

    this.isExpanded = !this.isExpanded; 

  }

  closePopup() {
    this.eventService.closeJjPopup(this.id);
  }

  getZIndex(bonus = 0) {
    return ((Number(this.id) * 100) + 20000 + bonus);
  }

  getPopupDimension(axis) {
    switch (axis) {
      case 'x':
        if (this.applicationInfoService.isMobile() || this.isExpanded) {
          return '100vw';
        } else {
          if (
            this.commonService.checkIfStringContainsString(this.popupWidth, 'px') || 
            this.commonService.checkIfStringContainsString(this.popupWidth, '%')
          ) {
            return this.popupWidth;
          } else {
            return this.popupWidth + 'vw'
          }          
        }
        break;

      case 'y':
        if (this.applicationInfoService.isMobile() || this.isExpanded) {
          return '100%';
        } else {
          if (
            this.commonService.checkIfStringContainsString(this.popupHeight, 'px') ||
            this.commonService.checkIfStringContainsString(this.popupHeight, '%') 
          ) {
            return this.popupHeight;
          } else {
            return this.popupHeight + 'vh';
          }          
        }
        break;
    }


  }

  dragElement(element) {
    if (this.isInitialyMaximized) { return; }
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(element?.id + "__header")) {
      document.getElementById(element?.id + "__header").onmousedown = dragMouseDown;
    } else {
      element.onmousedown = dragMouseDown;
    }
  
    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }
  
    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      element.style.top = (element.offsetTop - pos2) + "px";
      element.style.left = (element.offsetLeft - pos1) + "px";
    }
  
    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }

  }

  getPopupId(additionalText) {
    return 'everleadPopup'.concat(this.id, additionalText);
  }

  closeWindowOnKey(event:KeyboardEvent){
    if (this.applicationInfoService.showJJPopup && (event.key === 'Escape' || event.key === 'Esc')) {
      this.closePopup();
    }
  }



  centerModal(event?:Event){
    const modal = document.querySelector('.popupContainer__wrapper') as HTMLElement;
    if(modal){

      const modalWidth = modal?.offsetWidth;
      const modalHeight = modal?.offsetHeight;
      const translateX = -(modalWidth / 2);
      const translateY = -(modalHeight / 2);

      modal.style.left = '50%';
      modal.style.top = '50%';
      modal.style.transform=`translate(${Math.round(translateX)}px, ${Math.round(translateY)}px)`;

      this.modalState.transform = modal.style.transform;
      this.modalState.left= modal.style.left;
      this.modalState.top= modal.style.top;
      this.modalState.width= modal.style.width 
      this.modalState.height= modal.style.height
      
    }
  }

}
