<div class="cardaccordion" [ngClass]="{ 'noBottomBorder': applicationInfoService.isCardAccordeonOpen }" *ngIf="account || contact">
    
    <div *ngIf="!applicationInfoService.isCardAccordeonOpen || closeWhenExpanding == false" class="cardaccordion__header" (click)="togglePanel()">       
        <div class="appCardRowItem" *ngIf="applicationInfoService.currentAccount">
            <app-card type="account" [data]="applicationInfoService.currentAccount" [small]="true"></app-card>
        </div>
        <div class="appCardRowItem appCardRowItemContact" *ngIf="applicationInfoService.currentContact">
            <app-card type="contact" [data]="applicationInfoService.currentContact" [small]="true" [noRightBorder]="true"></app-card>
        </div>        
    </div>
    <div class="card__accordionButton" (click)="togglePanel()">
        <div class="card__accordionButton__inner">
            <app-jj-icons class="arrow-svg" [type]=" applicationInfoService.isCardAccordeonOpen ? 'caret-up' : 'caret-down'" [width]="24" [height]="12" [pointer]="true"></app-jj-icons>
        </div>
    </div> 
    <div *ngIf="applicationInfoService.isCardAccordeonOpen" class="cardaccordion__body">
        <ng-content></ng-content>
    </div>
</div>
