
<app-layout header="Dashboard.Item.Activity" [subheader]="selectedReport?.subHeader">
    <div class="projDashboardActivity">
        <div class="projDashboardActivity__header">          
            <div class="projDashboardActivity__selection">
                <div class="parameterContainer">                
                    <div class="parameterContainer__inner" *ngFor="let reportParameter of reportTemplate?.reportParameters">                                    
                        <app-select-input *ngIf="
                                reportParameter.parameterTypeLookupId == 16 && 
                                reportParameter.isUserParameter &&
                                reportParameter.defaultName != 'language_id'"
                            [label]="commonService.getTranslationValueFromArray(reportParameter)"
                            [(ngModel)]="reportParameterValues[reportParameter.id]"
                            [options]="paramterExternalDataSourceResults[reportParameter.externalDataSource?.id]"
                            [bind]="reportParameter.externalDataSource?.dataKey"
                            [display]="reportParameter.externalDataSource?.dataValue"
                            (valueChange)="getDashboardDataArray(17)"></app-select-input>
                    </div>

                        <app-select-input label="Task.Label.Campaign" [(ngModel)]="campaignId" bind="id" display="defaultName"
                        *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                        [options]="this.applicationInfoService.campaigns.toArray()"
                        (valueChange)="getDashboardDataArray(17)"></app-select-input>

            
                    <app-checkbox [(ngModel)]="onlyLeads" label="DefaultActivityDashboard.Label.ShowOnlyLeads"
                            (valueChange)="getDashboardDataArray(17)" class="checkBoxStyler"></app-checkbox>
              
                </div>
               
            </div>
        </div>
        <div class="projDashboardActivity__errors" *ngIf="loading || nodata">
            <div *ngIf="loading">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>
                {{ 'General.Label.LoadingData' | texttransform }}
            </div>

            <div class="marginContainer noDataContainer" *ngIf="nodata">
                {{ 'General.Label.NoDataAvailable' | texttransform }}
            </div>
        </div> 
        <span id="defaultDashboardActivity"></span>
        <div class="projDashboardActivity__content"
        [style.height.px]="getMaxHeight(5)" 
        [style.max-height.px]="getMaxHeight(5)"
        *ngIf="!loading && !nodata">
            <div class="projDashboardActivity__navigation">
                 <app-select-input 
                 label="Task.Label.Step" placeholder="ProjectOverview.Label.All"
                 [(ngModel)]="step" bind="id" display="step" 
                 [options]="stepsOptions"
                 (valueChange)="onSelectChange()"
                ></app-select-input>
                <div class="projDashboardActivity__navigation__options">
                    <app-main-button svg="chart-bar" [type]=" viewChart ? 'small' : 'borderSmall'" label="Dashboard.Label.ViewChart" (onClick)="viewChart=true"></app-main-button>
                    <app-main-button svg="list" [type]="viewChart ? 'borderSmall': 'small'" label="Dashboard.Label.ViewList" (onClick)="viewChart = false"></app-main-button>
                </div>
            </div>

            <div class="projDashboardActivity__content__chart" *ngIf="viewChart">
               <app-bar-chart [height]="350" [controlid]="step === null ? 'allstepsActivities' : undefined" 
                [chartData]="selectedData" component="dashboard_activity"></app-bar-chart>
            </div>

            <div class="projDashboardActivity__content__list" *ngIf="!viewChart">
               <app-dashboard-list-dropdown [controlid]="step === null ? 'nestedArray' : undefined" 
                [data]="selectedData" component="activity"></app-dashboard-list-dropdown>
            </div>
        </div>
    </div>
</app-layout>