<!-- <div class="optInoptOutEditor__wrapper">
    <div class="optInoptOutEditor__values">
        <div class="optinValueLine" *ngIf="optInoptOutObject.id == null">
            <div class="optinValueLine__label">{{ 'OptInOptOut.Label.MediumRef' | texttransform }}</div>
            <div class="optinValueLine__value">
                 <select [(ngModel)]="optInoptOutObject.lookupMediumRefId">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let lookupMediaType of lookupMediaTypes" [ngValue]="lookupMediaType.id">{{ lookupMediaType.defaultName }}</option>
                </select>            
            </div>
        </div>
        <div class="optinValueLine" *ngIf="optInoptOutObject.id == null">
            <div class="optinValueLine__label">{{ 'OptInOptOut.Label.TypeRef' | texttransform }}</div>
              <div class="optinValueLine__value">

               <select [(ngModel)]="optInoptOutObject.lookupTypeRefId">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let lookupTypeType of lookupTypeTypes" [ngValue]="lookupTypeType.id">{{ lookupTypeType.defaultName }}</option>
                </select> 
            </div>
        </div>

        <div class="optinValueLine" *ngIf="optInoptOutObject.id == null">
            <div class="optinValueLine__label">{{ 'OptInOptOut.Label.Contact' | texttransform }}</div>
            <div class="optinValueLine__value">
                <select [(ngModel)]="optInoptOutObject.contactId">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let contact of contacts" [ngValue]="contact.id">{{ contact.firstName }} {{ contact.lastName }}</option>
                </select>
            </div>
        </div>

        <div *ngIf="optInoptOutObject.id != null" class="everleadCoral everleadSmallFont">
            {{ 'OptInOptOut.Label.CanOnlySaveSpecificValues' | texttransform }}
        </div>

        <div class="optinValueLine" *ngIf="optInoptOutObject.id == null">
            <div class="optinValueLine__label">{{ 'OptInOptOut.Label.StartDate' | texttransform }}</div>
            <div class="optinValueLine__value">
                <input [(ngModel)]=optInoptOutObject.startDate [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly  appendTo="body" aria-modal="true"><owl-date-time #dt7></owl-date-time>
            </div>
        </div>
        <div class="optinValueLine">
            <div class="optinValueLine__label">{{ 'OptInOptOut.Label.EndDate' | texttransform }}</div>
            <div class="optinValueLine__value">
               
                <input [(ngModel)]=optInoptOutObject.endDate [owlDateTimeTrigger]="dt8" [owlDateTime]="dt8" readonly  appendTo="body" aria-modal="true"><owl-date-time #dt8></owl-date-time>
            </div>            
        </div>
        <div class="optinValueLine">
         
            <div class="optinValueLine__label">{{ 'OptInOptOut.Label.Comment' | texttransform }}</div>
            <div class="optinValueLine__value">
                <textarea [(ngModel)]=optInoptOutObject.comment></textarea>
            </div>            
        </div>      
    </div>
    <div class="optInoptOutEditor__save">
        <button *ngIf="optInoptOutObject.id != null" class="everleadCustomButton" (click)="updateOptInOptOutSetting()">{{ 'OptinOptOutEditor.Label.Save' | texttransform }}</button>
        <button *ngIf="optInoptOutObject.id == null" class="everleadCustomButton" (click)="createOptInOptOutSetting()"
            [disabled]="
                        optInoptOutObject.lookupTypeRefId == null || 
                        optInoptOutObject.lookupMediumRefId == null ||  
                        optInoptOutObject.contactId == null"
        >{{ 'OptinOptOutEditor.Label.Create' | texttransform }}</button>
    </div>
</div> -->

<div class="optinoptoutPopup">
    <div class="row">
        <div class="col-12" *ngIf="optInoptOutObject.id == null">
            <app-select-input 
                [(ngModel)]="optInoptOutObject.lookupMediumRefId" 
                [options]="lookupMediaTypes"
                bind="id"
                display="defaultName"
                [label]="'OptInOptOut.Label.MediumRef' | texttransform"
                (valueChange)="change($event)"
            ></app-select-input>
        </div>

        <div class="col-12" *ngIf="optInoptOutObject.id == null">
            <app-select-input
            [(ngModel)]="optInoptOutObject.lookupTypeRefId"
            [options]="lookupTypeTypes"
            bind="id"
            [label]="'OptInOptOut.Label.TypeRef' | texttransform "
            (valueChange)="change($event)"
            ></app-select-input>
        </div>

        <div class="col-12" *ngIf="optInoptOutObject.id == null">
            <app-select-input
            label="OptInOptOut.Label.Contact"
            [(ngModel)]="optInoptOutObject.contactId"
            bind="id"
            display="name"
            displayMultiValue="firstName;lastName"
            [options]="contacts"
            ></app-select-input>
        </div>

        <div class="col-12 everleadCoral everleadSmallFont" *ngIf="optInoptOutObject.id != null">
            {{ 'OptInOptOut.Label.CanOnlySaveSpecificValues' | texttransform }}
        </div>

        <div class="col-12" *ngIf="optInoptOutObject.id == null">
            <app-date-input [label]="'OptInOptOut.Label.StartDate' | texttransform" [(ngModel)]="optInoptOutObject.startDate"></app-date-input>
        </div>

        <div class="col-12">
            <app-date-input [label]="'OptInOptOut.Label.EndDate' | texttransform " [(ngModel)]="optInoptOutObject.endDate"></app-date-input>
        </div>

        <div class="col-12">
            <app-text-area-input [(ngModel)]="optInoptOutObject.comment" [label]="'OptInOptOut.Label.Comment' | texttransform "></app-text-area-input>
        </div>

        <div class="col-12">
            <div class="optinoptoutPopup__buttonContainer">
                <app-main-button
                svg="floppy-disk" 
                *ngIf="optInoptOutObject.id != null"
                [label]="'OptinOptOutEditor.Label.Save' | texttransform" 
                (onClick)="updateOptInOptOutSetting()"></app-main-button>
                
                <app-main-button
                svg="plus"
                *ngIf="optInoptOutObject.id == null" 
                [label]="'OptinOptOutEditor.Label.Create' | texttransform" 
                (onClick)="createOptInOptOutSetting()"
                [disabled]="optInoptOutObject.lookupTypeRefId == null || 
                            optInoptOutObject.lookupMediumRefId == null ||  
                            optInoptOutObject.contactId == null"
                ></app-main-button>
            </div>
        </div>
    </div>
</div>