<div class="quickSearchWrapper">
    <div class="quickSearchTopLayer">
    
    <div class="quickSearchMenuMobile">
        <div class="everleadMobileTab">
            <div class="everleadMobileTab__valueWrapper">
                <select [(ngModel)]="searchTab" class="selectTab__box">
                    <option [ngValue]="0">{{ getQuickSearchTabLabel('ResultAll', 'num_all')}}</option>
                    <option [ngValue]="1">{{getQuickSearchTabLabel('ResultAccounts', 'num_accounts')}}</option>
                    <option [ngValue]="2">{{getQuickSearchTabLabel('ResultContacts', 'num_contacts')}}</option>
                    <option [ngValue]="3">{{getQuickSearchTabLabel('ResultDocuments', 'num_documents')}}</option>
                </select>
            </div>
        </div>
    </div>    
    <div class="quickSearchMenu">
        <app-tab svg="magnifying-glass" [active]="searchTab == 0" [label]="getQuickSearchTabLabel('ResultAll', 'num_all')" (onClick)="searchTab = 0"></app-tab>
        <app-tab svg="building" [active]="searchTab == 1" [label]="getQuickSearchTabLabel('ResultAccounts', 'num_accounts')" (onClick)="searchTab = 1"></app-tab>
        <app-tab svg="user" [active]="searchTab == 2" [label]="getQuickSearchTabLabel('ResultContacts', 'num_contacts')" (onClick)="searchTab = 2"></app-tab>
        <app-tab svg="file" [active]="searchTab == 3" [label]="getQuickSearchTabLabel('ResultDocuments', 'num_documents')" (onClick)="searchTab = 3"></app-tab>
    </div>
    <div class="quickSearchHeader">        
        <app-jj-icons (click)="filterActive=!filterActive" class="fingerCursor" [type]="'sliders'" [width]="20" [height]="20" ></app-jj-icons>        
        <div class="quickSearchHeaderSearch">            
            <input class="projectSearchInput" [(ngModel)]="applicationInfoService.quickSearchValue" id="quickSearchInput"
            (keydown)="handleQuickSearchKeyDown($event)" [placeholder]="'General._.QuickSearch' | texttransform">  
            <app-jj-icons (click)="searchForValue()" class="fingerCursor" [type]="'magnifying-glass'" [width]="20" [height]="20" ></app-jj-icons>          
        </div>        
    </div>    
</div>

    <div class="quickSearchFilter" *ngIf="!searching && filterActive">
        <div class="quickSearchFilter__filter">
            <input class="projectSearchInput" [(ngModel)]="filterString" [placeholder]="'QuickSearch.Label.FilterResults' | texttransform">
            <app-jj-icons [type]="'funnel'" pointer="false" [width]="20" [height]="20" ></app-jj-icons>                        
        </div>
<!--         <app-filter (keyUp)="filterString = $event"></app-filter>
        <app-jj-icons [type]="'funnel'" [width]="20" [height]="20" ></app-jj-icons>   -->
        <div class="quickSearchFilter__pools">
            <input type="checkbox" [(ngModel)]="applicationInfoService.quickSearchAllPools">
            <div>{{ 'QuickSearch.Label.AllPools' | texttransform}}</div>
        </div>
    </div>
    <div class="toMuchResults" *ngIf="searchResults?.num_accounts > 99 || searchResults?.num_contacts > 99">
        <app-jj-icons [type]="'warning'" [width]="20" [height]="20" pointer="false"></app-jj-icons>                    
        {{ 'QuickSearch.Label.TooManyResults' | texttransform }}
    </div>

    <div class="quickSearchContent">
        <div *ngIf="searching == false  && searchResults != null">
            <div id="searchResultAccounts" *ngIf="(searchTab == 0 || searchTab == 1) && searchResults.num_accounts != 0" >
                <app-quicksearchitem *ngFor="let searchResult of searchResults.accounts; let rowIndex = index" [itemType]="0" [item]="searchResult" [ngClass]="{
                    hideInDom: !checkIfItemCorrespondsFilter(searchResult, 1)
                }" [displayText]="accountStrings[searchResult.id].accountText" class="quickSearchHover" [isOdd]="commonService.isOdd(rowIndex)">
                </app-quicksearchitem>
            </div>            
        </div>

        <div *ngIf="searching == false && searchResults != null">
            <div id="searchResultAccounts" *ngIf="(searchTab == 0 || searchTab == 2) && searchResults.num_contacts != 0">
                <app-quicksearchitem *ngFor="let searchResult of searchResults.contacts" [itemType]="1" [item]="searchResult" [ngClass]="{
                    hideInDom: !checkIfItemCorrespondsFilter(searchResult, 2)
                }" [displayText]="contactStrings[searchResult.id].contactText" [accountText]="contactStrings[searchResult.id].accountText"
                    [isOdd]="commonService.isOdd(rowIndex)"
                ></app-quicksearchitem>
            </div>            
        </div>

        <div *ngIf="searching == true" class="quickSearchContentSpinner">
            <i class="fas fa-spinner fa-spin"></i>{{'General.Label.LoadingData'|texttransform}}
        </div>
    </div>








    <!-- <div class="quickSearch__interaction">
        <div class="quickSearch__header">
            <input id="quickSearchInput" type="text" class="quickSearch__searchInput" 
                [(ngModel)]="applicationInfoService.quickSearchValue"
                placeholder="{{ 'General._.QuickSearch' | texttransform }}" (keydown)="handleQuickSearchKeyDown($event)">
            <div class="quickSearch__header__allPools" *ngIf="applicationInfoService.applicationSettings['hideAllPoolQuickSearch'] != 'true'">
                <input type="checkbox" [(ngModel)]="applicationInfoService.quickSearchAllPools">
                <div class="ellipsisText">{{ 'QuickSearch.Label.AllPools' | texttransform }}</div>
            </div>            
            <svg-icon src="assets/images/svg/search.svg" [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" (click)="searchForValue()" class="fingerCursor"></svg-icon>   
        </div>
        <div class="quickSearch__filterBar" *ngIf="searchResults != null">
            <div class="quickSearch__filterBar__filterInput">
                <input type="text" [(ngModel)]="filterString" placeholder="{{ 'General._.Filter' | texttransform }}">
            </div>
            <div class="quickSearch__filterBar__resultContainer" (click)="changeResultType(0)">
                <div class="ellipsisText" [ngClass]="{ 
                    'quickSearch__filterBar__resultContainer__active': resultType == 0
                }">{{ 'QuickSearch.Label.ResultAll' | texttransform }}</div>
                <div class="quickSearch__filterBar__resultContainer__icon quickSearch__filterBar__resultContainer__icon__all">{{ searchResults.num_all }}</div>
            </div>
            <div class="quickSearch__filterBar__resultContainer" (click)="changeResultType(1)">
                <div class="ellipsisText" [ngClass]="{ 
                    'quickSearch__filterBar__resultContainer__active': resultType == 1
                }">{{ 'QuickSearch.Label.ResultAccounts' | texttransform }}</div>
                <div class="quickSearch__filterBar__resultContainer__icon quickSearch__filterBar__resultContainer__icon__account">{{ searchResults.num_accounts }}</div>
            </div>
            <div class="quickSearch__filterBar__resultContainer quickSearch__filterBar__resultContainer__last" (click)="changeResultType(2)">
                <div class="ellipsisText" [ngClass]="{ 
                    'quickSearch__filterBar__resultContainer__active': resultType == 2
                }">{{ 'QuickSearch.Label.ResultContacts' | texttransform }}</div>
                <div class="quickSearch__filterBar__resultContainer__icon quickSearch__filterBar__resultContainer__icon__contact">{{ searchResults.num_contacts }}</div>
            </div>
        </div>        
    </div>

    <div *ngIf="searchResults != null">
        <div *ngIf="searchResults.num_all == 0 && searching==false" class="quickSearch__infoContainer">
            <i class="fas fa-frown"></i> {{ 'QuickSearch.Label.NoResults' | texttransform }}
        </div>
        <div *ngIf="searchResults.num_accounts == 100 || searchResults.num_contacts == 100" class="quickSearch__infoContainer">
            <i class="fas fa-exclamation-triangle"></i> {{ 'QuickSearch.Label.TooManyResults' | texttransform }}
        </div>
    </div>        

    <div class="quickSearch__results fingerCursor" *ngIf="searchResults != null && searching == false">
        <div *ngIf="(resultType == 1 || resultType == 0) && searchResults.num_accounts > 0">
            <div *ngFor="let searchResult of searchResults.accounts">
                <div class="quickSearch__results__panel" (click)="loadAccount(searchResult)" *ngIf="checkIfItemCorrespondsFilter(searchResult, 1)">
                    <div class="quickSearch__results__panel__icon quickSearch__results__panel__icon__account">
                        <svg-icon src="assets/images/svg/account.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" (click)="searchForValue()"></svg-icon>   
                    </div>
                    <div class="quickSearch__results__panel__text">
                        <div class="quickSearch__results__panel__text__inner">
                            <div class="quickSearchId">{{ searchResult.id }} </div> <label [innerHTML]="accountStrings[searchResult.id].accountText">
                            </label>
                        </div>       
                        <div class="everleadCoral ellipsisText  quickSearch__results__panel__text__pool">
                            {{ searchResult.poolname }}
                        </div>                  
                    </div>                
                </div>
            </div>
        </div>

        <div *ngIf="(resultType == 2 || resultType == 0) && searchResults.num_contacts > 0">
            <div *ngFor="let searchResult of searchResults.contacts">
                <div class="quickSearch__results__panel" (click)="loadContact(searchResult)" *ngIf="checkIfItemCorrespondsFilter(searchResult, 2)">
                    <div class="quickSearch__results__panel__icon quickSearch__results__panel__icon__contact">
                        <svg-icon src="assets/images/svg/contact-pool.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" (click)="searchForValue()"></svg-icon>                           
                    </div>
                    <div class="quickSearch__results__panel__text">
                        <div class="quickSearch__results__panel__text__inner" [ngClass]="{
                            'quickSearch__results__panel__text__inner__contact': searchResult.accountText != null
                        }">
                            <div class="quickSearchId">{{ searchResult.id }} </div> 
                            <label [innerHTML]="contactStrings[searchResult.id].contactText"></label>
                            <span *ngIf="searchResult['account.is_dummy_account'] == false" class="d-flex">
                                <div class="quickSearch__results__panelsmall__icon quickSearch__results__panel__icon__contactAccount">
                                    <svg-icon src="assets/images/svg/account.svg" [svgStyle]="{ 'width.px': 12, 'height.px': 12 }"></svg-icon>   
                                </div>                                
                                <label [innerHTML]="contactStrings[searchResult.id].accountText"></label>
                            </span>                            
                        </div>            
                        <div class="everleadCoral ellipsisText quickSearch__results__panel__text__pool">
                            {{ searchResult.poolname }}
                        </div>    
                    </div>                  
                </div>
            </div>
        </div>
    </div> -->

</div>