<div class="prospectsLayout">
    <div class="prospectActivityDashboard" *ngIf="activityData && !isActivitiesLoading">

            <div class="prospectActivityDashboard__nav">
                <app-select-input [(ngModel)]="groupingType" label="ProspectsDashboard.Label.Timeline" [options]="groupingTypeOptions" [complex]="true" display="label"></app-select-input>    
                <app-date-input *ngIf="groupingType" label="ProspectsDashboard.Label.From" [(ngModel)]="inputFrom"></app-date-input>
                <app-date-input  *ngIf="groupingType" label="ProspectsDashboard.Label.Until" [(ngModel)]="inputUntil"></app-date-input>
                <app-main-button [disabled]="!groupingType" type="small" label="ProspectsDashboard.Label.GetTimeline" svg="rocket" (onClick)="getData()"></app-main-button>
            </div>


        <div class="prospectActivityDashboard__subnav" *ngIf="data.length > 0">
                <div class="prospectActivityDashboard__subnav__filters">
                    <app-select-input label="ProspectsDashboard.Label.ActivityType" placeholder="All" [(ngModel)]="activity" [options]="activityOptions" [complex]="true" display="option" (valueChange)="filterActivity('activity')"></app-select-input>
                    <app-select-input [ellipsis]="50" *ngIf="isFilterable" [(ngModel)]="prospect" [options]="prospectsOptions" label="ProspectsDashboard.Label.CompanyName" display="option" [complex]="true" (valueChange)="filterActivity('prospects')"></app-select-input>
                    <app-select-input [ellipsis]="50" *ngIf="isUrl && !isFilterable" [(ngModel)]="url" [options]="urlOptions" label="ProspectsDashboard.Label.ActiveUrls" display="option" [complex]="true" (valueChange)="filterActivity('url')"></app-select-input>
                </div>        
                <div class="prospectActivityDashboard__subnav__buttons">
                    <app-main-button svg="line-segments" [type]="showNodeLabels ? 'small' : 'borderSmall'" (onClick)="toggleChartLabels()" [label]="showNodeLabels ? 'ProspectsDashboard.Label.HideLabels' : 'ProspectsDashboard.Label.ShowLabels'"></app-main-button>
                </div>
        </div>

        <div class="prospectActivityDashboard__content" *ngIf="pathSelected && !isFirstCallLoading">

   <!-- todo must be tested on MOBILE   -->
                <div class="prospectActivityDashboard__content__nav">
                    <div class="prospectActivityDashboard__tabsMobile">
                        <div class="everleadMobileTab">
                            <div class="everleadMobileTab__valueWrapper">
                                <select [(ngModel)]="pathSelected" class="selectTab__box" (change)="navigate(pathSelected, path.indexOf(pathSelected))">
                                    <option *ngFor="let link of path" [ngValue]="link">{{ checkLabelLength(link) }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="prospectActivityDashboard__tabs" *ngIf="path?.length > 0">
                        <div *ngFor="let link of path; let i= index" (click)="navigate(link, i)">
                            <app-tab [tippy]="link.length > 50 ? link : undefined" [tippyOptions]="{theme:'everleadNav', placement:'bottom' }" svg="chart-line" [label]="checkLabelLength(link)" (onClick)="navigate(link, i)" [active]="link === pathSelected"></app-tab>
                        </div>
                    </div>
                </div>

                <div class="prospectActivityDashboard__content__chartContainer" *ngIf="!isLoading">
                    <div class="prospectActivityDashboard__content__chartLegend" *ngIf="!filteredArray[0]?.url">
                        <div *ngFor="let color of colors" class="prospectActivityDashboard__content__chartLegend__placeholder">
                            <div class="line" [ngStyle]="{'backgroundColor': color.color}"></div>
                            <div class="label">{{color.type }}</div>
                        </div>
                    </div>
                    <div class="prospectActivityDashboard__content__chartLegend" *ngIf="filteredArray[0]?.url">
                        <div class="prospectActivityDashboard__content__chartLegend__placeholder">
                            <div class="dot" [ngStyle]="{'backgroundColor': '#5465FF'}"></div>
                            <div class="label">{{'ProspectsDashboard.Label.Activity' | texttransform }}</div>
                        </div>
                        <div class="prospectActivityDashboard__content__chartLegend__placeholder">
                            <div class="dot" [ngStyle]="{'backgroundColor': '#FFD600'}"></div>
                            <div class="label">{{ 'ProspectsDashboard.Label.OverlappingActivity' | texttransform  }}</div>
                        </div>
                    </div>
                    <div echarts [options]="options" (chartClick)="onNodeClick($event)" class="prospectActivityDashboard__content__chart"></div>
                </div>
                <span *ngIf="isLoading">
                    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
                </span>
        </div>
        <span *ngIf="isFirstCallLoading">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
        </span>
    </div>
    <div *ngIf="!activityData && !isActivitiesLoading" class="prospectActivityDashboard">
        <div class="prospectActivityDashboard__noData">
                {{ 'General.Label.NoDataAvailable' | texttransform }}<br>
                <br>
        </div>
    </div>
    <span *ngIf="isActivitiesLoading">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </span>
</div>

