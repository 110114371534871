import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { EventbookingbatchService } from 'app/jollyjupiter/controls/static/eventbookingbatch/eventbookingbatch.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MethodService } from 'app/jollyjupiter/service/method.service';

@Component({
  selector: 'app-defaultdashboard-mailshotdetail',
  templateUrl: './defaultdashboard-mailshotdetail.component.html',
  styleUrl: './defaultdashboard-mailshotdetail.component.scss'
})
export class DefaultdashboardMailshotdetailComponent implements OnInit, OnDestroy {
  @Input() selectedReport = null;
  customEventSubscription: Subscription = new Subscription();
  dashboardResultData = null;
  loadingData = false;
  eventBookingBatches = [];
  selectedBookingBatchId = null;
  dashboardResults = [];
  dashboardResultBoxes = [];
  deviceData = null;
  deviceDataPie = null;
  kpis = null;
  kpisChart = [];
  eventHours = [
    { event_hour: 1, event_type: 'Open', event_count: 12 },
    { event_hour: 2, event_type: 'Open', event_count: 4 },
    { event_hour: 3, event_type: 'Open', event_count: 6 },
    { event_hour: 4, event_type: 'Open', event_count: 8 },
    { event_hour: 1, event_type: 'Click', event_count: 9 },
  ];
  eventHoursChart = null;
  selectedMailshotDetailList = null;
  selectedMailshotDetailListArray = null;
  mailshotDetailListOptions = [];
  mode = 1;
  modes = [
    { id: 1, defaultName: 'Mailshots.Label.Mode1' },
    { id: 2, defaultName: 'Mailshots.Label.Mode2' }
  ];

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private dashboardcoreService: DashboardcoreService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventbookingbatchService: EventbookingbatchService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    private commonService: CommonService,
    private methodService: MethodService
  ) {}

  
  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.getEventBookingBatches();
      }
    });

    this.getEventBookingBatches();
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getDashboardDetails(dashboardId, type = null): Promise<any> {
    return new Promise((getDashboardDetailsResolve, getDashboardDetailsReject) => {
      let params = this.fillParams(type);
      this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(getDashboardDataArrayResult => {      
        this.loadingData = false;
        getDashboardDetailsResolve(getDashboardDataArrayResult);
      }).catch((error)=>{
        this.loadingData = false;
        getDashboardDetailsReject(error);
      });  
    });
  }

  fillParams(eventType = null) {
    const params = [];   
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@event_batch_id', value: this.selectedBookingBatchId });
    if (eventType) {
      params.push({ key: '@event_type', value: eventType });
    }
    return params;
  }

  getEventBookingBatches() {
    if (this.applicationInfoService.getCompleteProjectInformatonLoaded == false) { return; }
    this.externaldatasourceService.executeExternalDataSource(this.eventbookingbatchService.getBookingBatchExternalDataSourceId).then(getEventBookingBatchesResult => { 
      this.eventBookingBatches = getEventBookingBatchesResult.filter(batch => 
        batch.batchTypeLookupId == 7421 &&
        batch.isDeleted == false
      );       
      this.eventBookingBatches.sort((a, b) => a.id < b.id ? -1 : 1);
      this.eventBookingBatches.forEach(batchType => {
        batchType['wizardValueArray'] = JSON.parse(batchType.wizardValues);
      });
      if (this.applicationInfoService.campaingnModeId != null) {
        this.eventBookingBatches = this.eventBookingBatches.filter(batch => batch.wizardValueArray.campaignId == this.applicationInfoService.campaingnModeId);
      }      
    });    
  }

  openContactPopup(selectedMailshotDetailListItem) {
    this.methodService.loadContactInformation(selectedMailshotDetailListItem.contact_id);
  }

  getEventBookingBatchData() {
    this.deviceData = null;
    this.kpis = null;
    this.kpisChart = null;
    this.selectedMailshotDetailList = null;
    this.selectedMailshotDetailListArray = null;
    this.mailshotDetailListOptions = [];
    this.getDashboardDetails(23).then(getEventBookingBatchDataResult => {
      setTimeout(() => {
        console.warn(getEventBookingBatchDataResult);
        this.kpis = [];
        let counter = 1;
        this.selectedMailshotDetailListArray = [];
        getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.forEach(item => {
          item['translation'] = this.getTypeTranslation(item.event_type);
          if (item.event_type != 'Total') {
            this.mailshotDetailListOptions.push({ id: counter, type: item.event_type, defaultName: this.getTypeTranslation(item.event_type)});  
          }
          counter ++;
        });
    
        this.kpis.push(getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.find(item => item.event_type == 'Send'));    
        this.kpis.push(getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.find(item => item.event_type == 'Delivery'));
        this.kpis.push(getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.find(item => item.event_type == 'Open'));
        this.kpis.push(getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.find(item => item.event_type == 'Click'));
        this.kpis.push(getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.find(item => item.event_type == 'Bounce'));        
        this.kpisChart = JSON.parse(JSON.stringify(this.kpis)).reverse();
      }, 200);
    });
    
    this.dashboardResults = [];     
    this.getDevices();
    this.get24hrs();
  }

  get24hrs() {
    this.getDashboardDetails(26).then(get24hrsResult => {
      if (get24hrsResult) {
        this.create24HourData(get24hrsResult[0][0].mail_event_first_24_hours);
      }
    });
  }

  getDevices() {
    this.getDashboardDetails(25).then(getDevicesResult => {
      if (getDevicesResult.length > 0) {
        this.deviceData = getDevicesResult[0][0].mail_event_device_type;
        if (this.deviceData.length > 0) {
          this.deviceData = getDevicesResult[0][0].mail_event_device_type.filter(items => items.event_type == 'Open' );        
          this.deviceDataPie = { color: ['#5465FF', '#5162F6','#788BFF'], data: [], type: 'pie' }
          this.deviceDataPie.data.push({ name: 'Mobile' , value: this.deviceData.find(item => item.device_type == 1).event_count });
          this.deviceDataPie.data.push({ name: 'Desktop' , value: this.deviceData.find(item => item.device_type == 2).event_count });
          this.deviceDataPie.data.push({ name: 'Unknown' , value: this.deviceData.find(item => item.device_type == 3).event_count });           
        }          
      }
    });
  }

  addDashboardDetail(dashboardDetail) {
    this.getDashboardDetails(24, dashboardDetail).then(getDashboardDetailsResult => { 
      if (getDashboardDetailsResult) {
        this.selectedMailshotDetailListArray = [];
        getDashboardDetailsResult[0][0].mail_event_contact_list_by_event_type.forEach(item => {
          const foundItem = this.selectedMailshotDetailListArray.find(existingItem => existingItem.contact_id == item.contact_id);
          if (!foundItem) {
            this.selectedMailshotDetailListArray.push(item);  
          }
        });        
      }      
    });
  }

  getDashboardResults() {
    if (this.applicationInfoService.isDeveloper) {
      console.warn(this.dashboardResults);
    }    
  }

  getTypeTranslation(event_type) {
    let returnValue = 'Mailshots.Label.';
    switch(event_type) {
      case 'Delivery': returnValue = returnValue.concat('Delivered'); break;
      case 'Click': returnValue = returnValue.concat('LinkClicked'); break;
      case 'Open': returnValue = returnValue.concat('Open'); break;
      case 'Bounce': returnValue = returnValue.concat('CountBounced'); break;
      case 'Send': returnValue = returnValue.concat('Send'); break;
      case 'Error': returnValue = returnValue.concat('Error'); break;
      case 'Complaint': returnValue = returnValue.concat('Complaint'); break;
      case 'Failure': returnValue = returnValue.concat('Failure'); break;
    }    
    return returnValue;
  }

  create24HourData(eventData) {
    const dataOpen = [];
    const dataClick = [];
    const yAxis = [];
    for (let i = 1; i < 25; i++) {
      yAxis.push('Hour '.concat(i.toString()));
      const foundOpen = eventData.find(eventHour => eventHour.event_calculated_hour == i && eventHour.event_type == 'Open');
      const foundClick = eventData.find(eventHour => eventHour.event_calculated_hour == i && eventHour.event_type == 'Click');
      if (foundOpen) {
        dataOpen.push(foundOpen.event_count);
      } else {
        dataOpen.push(0);
      }
      if (foundClick) {
        dataClick.push(foundClick.event_count);
      } else {
        dataClick.push(0);
      }
    }

    const labelOption = {
      show: true, distance: 15, align: 'left', verticalAlign: 'middle', position: 'insideBottom',
      formatter: function(param) { return param.data == 0 ? '': param.data; }, fontSize: 14, rich: { name: {}}
    };

    this.eventHoursChart = {
      title: { text: this.axivasTranslateService.getTranslationTextForToken('Mailshots.Label.Overview24Hours')},
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }},
      legend: { data: [
        this.axivasTranslateService.getTranslationTextForToken('Mailshots.Label.Open'),
        this.axivasTranslateService.getTranslationTextForToken('Mailshots.Label.LinkClicked'),
      ]},
      toolbox: { show: true, orient: 'vertical', left: 'right', top: 'center', feature: { magicType: { show: true, type: ['line', 'bar'] }}},
      xAxis: [{ type: 'category', axisTick: { show: false }, data: yAxis }],
      yAxis: [{ type: 'value' }],
      series: [        
        { name: this.axivasTranslateService.getTranslationTextForToken('Mailshots.Label.Open'), 
          type: 'line', label: labelOption, emphasis: { focus: 'series' }, data: dataOpen },
        { name: this.axivasTranslateService.getTranslationTextForToken('Mailshots.Label.LinkClicked'), 
          type: 'line', label: labelOption, emphasis: { focus: 'series' }, data: dataClick }
      ]
    };
  }

  changeSelectedMailshotDetailList() {
    const foundMailshotDetailListOption = this.mailshotDetailListOptions.find(item => item.id == this.selectedMailshotDetailList);
    if (foundMailshotDetailListOption) {
      this.addDashboardDetail(foundMailshotDetailListOption.type); 
    }    
  }
}
