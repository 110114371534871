<span id="projDashboardHeightSpan"></span>
<app-layout [header]="getHeaderText()" [subheader]="selectedReport?.subHeader">
    <div 
    class="projDashboard">
        <!-- <app-main-button type="small" label="Export" svg="export" (onClick)="requestReport()"
        *ngIf="!loadingData && !nodata" [disabled]="loadingData && nodata" class="exportDashboard"></app-main-button> -->

        <div class="projDashboardHeader">

            <div class="projDashboard__tabsMobile">
                <div class="everleadMobileTab">
                    <div class="everleadMobileTab__valueWrapper">
                        <select [(ngModel)]="tab" class="selectTab__box" (change)="switchTab(tab)">
                            <option [ngValue]="1">{{'Account.Label.Header' | texttransform }}</option>
                            <option [ngValue]="2">{{'Contact.Label.Header' | texttransform }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="projDashboard__tabs">
                <app-tab label="Account.Label.Header" svg="building" (onClick)="switchTab(1)" [active]="tab===1"></app-tab>
                <app-tab label="Contact.Label.Header" svg="user" (onClick)="switchTab(2)" [active]="tab===2"></app-tab>
            </div>

            <div class="projDashboard__selection">                
                <div *ngFor="let reportParameter of reportTemplate?.reportParameters" class="onlyMobile">                                    
                    <app-select-input *ngIf="
                            reportParameter.parameterTypeLookupId == 16 && 
                            reportParameter.isUserParameter &&
                            reportParameter.defaultName != 'language_id'"
                        [label]="commonService.getTranslationValueFromArray(reportParameter)"
                        [(ngModel)]="reportParameterValues[reportParameter.defaultName]"
                        [options]="paramterExternalDataSourceResults[reportParameter.externalDataSource?.id]"
                        [bind]="reportParameter.externalDataSource?.dataKey"
                        [display]="reportParameter.externalDataSource?.dataValue"
                        (valueChange)="getDashboardData()"></app-select-input>
                </div>
            </div>
        </div>
        <div class="projDashboard__content"
        [style.height.px]="getMaxHeight(650)" 
    [style.max-height.px]="getMaxHeight(650)">
            <div *ngIf="loadingData && loadingDependentData">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>
                {{ 'General.Label.LoadingData' | texttransform }}
            </div>

            <div class="marginContainer noDataContainer" *ngIf="nodependentData && nodata">
                {{ 'General.Label.NoDataAvailable' | texttransform }}
            </div>

            <div class="chartContainer" *ngIf="(!nodata || !nodependentData ) && !loadingData && !loadingDependentData">
                <div class="chartContainer__nav">

                    <div class="chartContainer__tabsMobile">
                        <div class="everleadMobileTab">
                            <div class="everleadMobileTab__valueWrapper">
                                <select [(ngModel)]="chartTab" (change)="viewChart = true" class="selectTab__box">
                                    <option [ngValue]="1">{{'Report.ProjectOverview.Bearbeitungsstatus' | texttransform }}</option>
                                    <option [ngValue]="4" *ngIf="tab == 2 && !nodataArray && !loadingDataArray">{{'Report.ProjectOverview.EinwandDaten' | texttransform }}</option>
                                    <option [ngValue]="2">{{ 'Report.ProjectOverview.AbgeschlosseneDaten' | texttransform }}</option>
                                    <option [ngValue]="3">{{'Report.ProjectOverview.DatenInBearbeitung' | texttransform }}</option> 
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="chartContainer__tabs">
                        <app-tab svg="chart-bar-horizontal" label="Report.ProjectOverview.Bearbeitungsstatus" 
                            [active]="chartTab == 1" (onClick)="chartTab=1; viewChart=true"></app-tab>

                        <app-tab svg="chart-bar" *ngIf="tab == 2 && !nodataArray && !loadingDataArray" 
                            label="Report.ProjectOverview.EinwandDaten" [active]="chartTab == 4" (onClick)="chartTab=4; viewChart=true"></app-tab>

                        <app-tab svg="status" svgC="#5465FF" [svgH]="20" [svgW]="20"
                            label="Report.ProjectOverview.AbgeschlosseneDaten" [active]="chartTab== 2"
                            (onClick)="chartTab=2; viewChart=true"></app-tab>

                        <app-tab svg="status" svgC="#FF6B00" [svgH]="20" [svgW]="20"
                            label="Report.ProjectOverview.DatenInBearbeitung" [active]="chartTab == 3"
                            (onClick)="chartTab=3; viewChart=true"></app-tab>

                       <!--  <app-tab svg="status" svgC="#FF5050" [svgH]="20" [svgW]="20" *ngIf="tab == 2 && !nodataArray && !loadingDataArray" 
                            label="Report.ProjectOverview.Objections" [active]="chartTab == 4" (onClick)="chartTab=4; viewChart=true"></app-tab> -->
    
                    </div>
                    <div class="chartContainer__options">
                        <app-main-button [type]="viewChart === true ? 'small' : 'borderSmall'" svg="chart-bar-horizontal" label="View Chart"
                            (onClick)="viewChart=true" [disabled]="chartTab == 1"></app-main-button>
                        <app-main-button [type]="viewChart === false ? 'small' :'borderSmall'" svg="list" label="View List" (onClick)="viewChart=false"
                            [disabled]="chartTab == 1"></app-main-button>
                    </div>
                </div>

                <div class="chartContainer__chart">
                        <div class="chartContainer__chart__wrapper" *ngIf="chartTab === 1">
                                <div class="projDashboard__wrapper__chartTwo">
                                    <app-stacked-bar [chartData]="overViewData" controlid="defaultdashboard"
                                        height="400"></app-stacked-bar>
                                </div>  
                                <div class="projDashboard__wrapper__list__small">
                                    <app-dashboard-list [data]="overViewData" [type]="'overviewData'"
                                        [result]="dataResult"></app-dashboard-list>
                                </div>
                        </div>

                    <div class="chartContainer__chart__chart" *ngIf="chartTab === 2">
                        <span *ngIf="overViewDataClosed == null" class="marginContainer noDataContainer">
                            {{ 'General.Label.NoDataAvailable' | texttransform }}
                        </span>
                        <div *ngIf="overViewDataClosed != null">
                            <app-bar-chart *ngIf="viewChart" [yAxis]="true" [chartData]="overViewDataClosed"
                                controlid="dataclosed" [height]="getBestTableHeight(overviewDataClosed)"></app-bar-chart>
                            <div *ngIf="!viewChart" class="projBorderLists">
                                <app-dashboard-list  [data]="overViewDataClosed" [type]="'dataclosed'"
                                [result]="closedResult"></app-dashboard-list>
                            </div>
                        </div>
                    </div>

                    <div class="chartConatiner__chart__chart" *ngIf="chartTab === 3">
                        <span *ngIf="overViewDataInProgress == null" class="marginContainer noDataContainer">
                            {{ 'General.Label.NoDataAvailable' | texttransform }}
                        </span>
                        <div *ngIf="overViewDataInProgress != null" [style.padding.px]="16">
                            <app-bar-chart *ngIf="viewChart" [yAxis]="true" [chartData]="overViewDataInProgress"
                                controlid="dataprogress" [height]="getBestTableHeight(overViewDataInProgress)"></app-bar-chart>
                            
                            <div class="projBorderLists" *ngIf="!viewChart" >
                                    <app-dashboard-list [data]="overViewDataInProgress" [type]="'dataprogress'"
                                [result]="progressResult"></app-dashboard-list>
                            </div>                            
                        </div>
                    </div>

                    <div class="chartContainer__chart" *ngIf="chartTab === 4 && tab === 2" [style.padding.px]="16">
            
                        <div *ngIf="!nodataArray">
                            <div class="chartContainer__chart__selection">
                                <app-select-input
                                [(ngModel)]="leadStatus"
                                label="Report.ProjectOverview.LeadStatus"
                                [options]="leadStatusOptions"
                                [complex]="true"
                                display="option"
                                (valueChange)="onChangeLeadStateOptions()"
                                [error]="leadStatus === null"></app-select-input>

                                <app-select-input
                                [(ngModel)]="step"
                                placeholder="Report.ProjectOverview.SelectAllSteps"
                                label="Report.ProjectOverview.ChooseStep"
                                [options]="stepOptions"
                                [complex]="true"
                                display="option"
                                (valueChange)="onChangeStepOptions()"></app-select-input>                 
                            </div>
    
                            <div class="apiContainer" *ngIf="leadStatus == null"> 
                                <div class="apiContainer__error">{{'Defaultdashboard.Label.ChooseLeadstate' | texttransform}}</div>
                            </div>
        
                      <!--       chart height was 430 -->
                            <app-bar-chart *ngIf="viewChart && leadStatus" [height]="340" [chartData]="selectedObjectionsOptions" component="contact_Objections" [controlid]="step === null ? 'allsteps' : undefined" ></app-bar-chart>
    
                           <div class="projBorderLists" *ngIf="!viewChart && leadStatus">
                                <app-dashboard-list-dropdown [controlid]="step === null ? 'nestedArray' : undefined" [data]="selectedObjectionsOptions" [result]="stepObjectionResult" [leadstatus]="leadStatus"></app-dashboard-list-dropdown>
                           </div>                        
                        </div>


                        <div *ngIf="loadingDataArray">
                            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>
                            {{ 'General.Label.LoadingData' | texttransform }}
                        </div>
            
                        <div class="marginContainer noDataContainer" *ngIf="nodataArray">
                            {{ 'General.Label.NoDataAvailable' | texttransform }}
                        </div>
                    </div>


                </div>

            </div>

        </div>
    </div>
</app-layout>