<div class="projectUser__wrapper autoHeight">
  <app-layout header="Supervisor.Label.UserRoles">
    <div class="projectUser__header">
      <div class="projectUser__header__item" *ngIf="applicationInfoService.isDeveloper">        
        <app-select-input [label]="'General._.Project' | texttransform" [(ngModel)]="dropDownProjectSelection" class="maxWidth"
          [options]="projectData" display="projectName" bind="id" (valueChange)="changeProjectDropdownSelectionValue()"></app-select-input>
      </div>
      <div class="projectUser__header__item">
        <app-select-input [label]="'General.User.UserRole' | texttransform" [(ngModel)]="dropDownRoleSelection" class="maxWidth"
          [options]="roleData" display="defaultName" bind="id" (valueChange)="changeUserTypeDropdownSelectionValue()"></app-select-input>            
      </div>
      <div class="projectUser__header__item" *ngIf="dropDownRoleSelection">
        <app-select-input [label]="'General.User.UserRoleSection' | texttransform" [(ngModel)]="userRoleSection" class="maxWidth"
          [options]="userRoleSections" display="defaultName" bind="id" (valueChange)="getSubitemInfoBySection()"></app-select-input>
      </div>
    </div>

    <!-- User assignment -->  
    <div class="userManagement__wrapper" *ngIf="userRoleSection == '1' && dropDownRoleSelection">
      <div class="userManagement__left">
        <div class="userManagement__sectionHeader">
          <div class="userManagement__sectionHeader__label">{{ 'General.User.AvailableUser' | texttransform }}</div>
          
          <app-filter (keyUp)="filterString = $event"></app-filter>
    <!--       <app-jj-icons [type]="'magnifying-glass'" [width]="20" [height]="20"></app-jj-icons>
          <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
            [(ngModel)]="filterString"> -->
        </div>
        <div id="userList"></div>
        <div class="projectUser__header__userList"
          [style.height.px]="uiService.getContainerHeightEx('userList', 40, 300)">
          <div *ngFor="let user of availableUsers; let rowIndex = index" class="everleadTableRow"
            [ngClass]="{ hideInDom: isFilterActive(user.userName, filterString) || checkIfAlreadyAssigned(user) }">
            <div class="userManagement__item__container">
              <div class="userManagement__item__id">{{ user.id }}</div>
              <div class="userManagement__item__name">
                <div class="ellipsisText">{{ user.userName }}</div>
                <div class="ellipsisText userManagement__item__name__email">{{ user.email }}</div>
              </div>
              <div class="userManagement__item__move" (click)="addUser(user)">
                <app-jj-icons [type]="'plus'" [width]="20" [height]="20" *ngIf="dropDownRoleSelection != null" [pointer]="true"></app-jj-icons>               
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="userManagement__left">
        <div class="userManagement__sectionHeader">
          <div class="userManagement__sectionHeader__label">{{ 'General.User.AssignedUsers' | texttransform }}</div>

          <app-filter (keyUp)="filterStringAssigned = $event"></app-filter>
<!--           <app-jj-icons [type]="'magnifying-glass'" [width]="20" [height]="20"></app-jj-icons>
          <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
            [(ngModel)]="filterStringAssigned"> -->
        </div>
        <div id="assignedList"></div>
        <div class="projectUser__header__userList"
          [style.height.px]="uiService.getContainerHeightEx('assignedList', 40, 300)">
          <div *ngFor="let assignedUser of assignedUsers; let rowIndex = index" class="everleadTableRow"
            [ngClass]="{ 
              hideInDom: isFilterActive(assignedUser.user.userName, filterStringAssigned)
             }">
            <div class="userManagement__item__container">
              <div class="userManagement__item__id">{{ assignedUser.user.id }}</div>
              <div class="userManagement__item__name">
                <div class="ellipsisText">{{ assignedUser.user.userName }}</div>
                <div class="ellipsisText userManagement__item__name__email">{{ assignedUser.user.email }}</div>
              </div>
              <div class="userManagement__item__move" (click)="removeUser(assignedUser)">
                <app-jj-icons [type]="'trash'" [width]="20" [height]="20" [pointer]="true"></app-jj-icons>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Automatic record assignment -->
    <div class="projectUserSection fingerCursor" *ngIf="userRoleSection == '2' && dropDownRoleSelection">
      <label class="roleSectionHeaderLabel">{{ 'General.User.UserRoleSectionRecordAssignment' | texttransform }}</label>
      <label class="roleSectionHeaderLabelDesc">{{ 'General.User.UserRoleSectionRecordAssignmentDescription' |
        texttransform }}</label>
      <div class="d-flex">
        <div class="userManagement__left">
          <div class="userManagement__sectionHeader">
            <div class="userManagement__sectionHeader__label">{{ 'General.Label.Available' | texttransform }}</div>
            <app-jj-icons [type]="'magnifying-glass'" [width]="20" [height]="20"></app-jj-icons>
            <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
              [(ngModel)]="filterString">
          </div>
          <div id="availableList"></div>
          <div class="projectUser__header__userList"
            [style.height.px]="uiService.getContainerHeightEx('availableList', 40, 300)">
            <div *ngFor="let automaticRecordAssignment of automaticRecordAssignments; let rowIndex = index" class="everleadTableRow"
              [ngClass]="{ hideInDom: isFilterActive(automaticRecordAssignment.defaultName, filterString) || isActiveAssignment(automaticRecordAssignment) }">
              <div class="userManagement__item__container">
                <div class="userManagement__item__name ellipsisText">{{ automaticRecordAssignment.defaultName }}</div>
                <div class="userManagement__item__move" (click)="toggleAssignment(automaticRecordAssignment)">
                  <app-jj-icons [type]="'plus'" [width]="20" [height]="20" *ngIf="dropDownRoleSelection != null" [pointer]="true"></app-jj-icons> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="userManagement__left">
          <div class="userManagement__sectionHeader">
            <div class="userManagement__sectionHeader__label">{{ 'General.Label.Assigned' | texttransform }}</div>
            <app-jj-icons [type]="'magnifying-glass'" [width]="20" [height]="20"></app-jj-icons>
            <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
              [(ngModel)]="filterStringAssigned">
          </div>
          <div id="assignedList"></div>
          <div class="projectUser__header__userList"
            [style.height.px]="uiService.getContainerHeightEx('assignedList', 40, 300)">
            <div *ngFor="let automaticRecordAssignment of automaticRecordAssignments; let rowIndex = index" class="everleadTableRow"
              [ngClass]="{ hideInDom: isFilterActive(automaticRecordAssignment.defaultName, filterStringAssigned) || isActiveAssignment(automaticRecordAssignment) == false }">
              <div class="userManagement__item__container">
                <div class="userManagement__item__name ellipsisText">{{ automaticRecordAssignment.defaultName }}</div>
                <div class="userManagement__item__move" (click)="toggleAssignment(automaticRecordAssignment)">
                  <app-jj-icons [type]="'trash'" [width]="20" [height]="20" [pointer]="true"></app-jj-icons> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <span id="projectUserSectionData"></span>
      <div class="projectUserSectionData" [style.height.px]="uiService.getContainerHeightEx('projectUserSectionData', 40, 300)">
        <div *ngFor="let automaticRecordAssignment of automaticRecordAssignments" class="projectUserSectionAssignment"
          (click)="toggleAssignment(automaticRecordAssignment)" [ngClass]="{ 'projectUserSectionAssignment__isActive': isActiveAssignment(automaticRecordAssignment) }">
          {{ automaticRecordAssignment.defaultName }}
        </div>
      </div> -->
      </div>

      <!-- Rights -->
      <div class="projectUserSection fingerCursor" *ngIf="userRoleSection == '3' && dropDownRoleSelection">
        <label class="roleSectionHeaderLabel">{{ 'General.User.UserRoleSectionRights' | texttransform }}</label>
        <label class="roleSectionHeaderLabelDesc">{{ 'General.User.UserRoleSectionRightsDescription' | texttransform
          }}</label>
        <span id="projectUserSectionData"></span>
        <div class="projectUserSectionData"
          [style.height.px]="uiService.getContainerHeightEx('projectUserSectionData', 40, 300)">

        </div>
      </div>

      <!-- Dashboards -->
      <div class="projectUserSection fingerCursor" *ngIf="userRoleSection == '4' && dropDownRoleSelection">
        <label class="roleSectionHeaderLabel">{{ 'General.User.UserRoleSectionDashboards' | texttransform }}</label>
        <label class="roleSectionHeaderLabelDesc">{{ 'General.User.UserRoleSectionDashboardsDescription' | texttransform
          }}</label>
        <div class="d-flex">
          <div class="userManagement__left">
            <div class="userManagement__sectionHeader">
              <div class="userManagement__sectionHeader__label">{{ 'General.Label.Available' | texttransform }}</div>
              <app-jj-icons [type]="'magnifying-glass'" [width]="20" [height]="20"></app-jj-icons>
              <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
                [(ngModel)]="filterString">
            </div>
            <div id="availableList"></div>
            <div class="projectUser__header__userList"
              [style.height.px]="uiService.getContainerHeightEx('availableList', 40, 300)">
              <div *ngFor="let dashboard of dashboards"
                [ngClass]="{ hideInDom: isFilterActive(dashboard.name, filterString) || isActiveDashboard(dashboard) }">
                <div class="userManagement__item__container">
                  <div class="userManagement__item__name ellipsisText">{{ dashboard.name }}</div>
                  <div class="userManagement__item__move" (click)="toggleDashboard(dashboard)">
                    <app-jj-icons [type]="'plus'" [width]="20" [height]="20" *ngIf="dropDownRoleSelection != null" [pointer]="true"></app-jj-icons> 
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="userManagement__left">
            <div class="userManagement__sectionHeader">
              <div class="userManagement__sectionHeader__label">{{ 'General.Label.Assigned' | texttransform }}</div>
              <app-jj-icons [type]="'magnifying-glass'" [width]="20" [height]="20"></app-jj-icons>
              <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
                [(ngModel)]="filterStringAssigned">
            </div>
            <div id="assignedList"></div>
            <div class="projectUser__header__userList"
              [style.height.px]="uiService.getContainerHeightEx('assignedList', 40, 300)">
              <div *ngFor="let dashboard of dashboards"
                [ngClass]="{ hideInDom: isFilterActive(dashboard.name, filterStringAssigned) || isActiveDashboard(dashboard) == false }">
                <div class="userManagement__item__container">
                  <div class="userManagement__item__name ellipsisText">{{ dashboard.name }}</div>
                  <div class="userManagement__item__move" (click)="toggleDashboard(dashboard)">
                    <app-jj-icons [type]="'trash'" [width]="20" [height]="20" [pointer]="true"></app-jj-icons> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <span id="projectUserSectionData"></span>
      <div class="projectUserSectionData" [style.height.px]="uiService.getContainerHeightEx('projectUserSectionData', 40, 300)">
        <div *ngFor="let dashboard of dashboards" class="projectUserSectionAssignment"
          (click)="toggleDashboard(dashboard)" [ngClass]="{ 'projectUserSectionAssignment__isActive': isActiveDashboard(dashboard) }">
          {{ dashboard.name }}
        </div>
      </div> -->
      </div>

      <!-- Datarights -->
      <div class="projectUserSection fingerCursor" *ngIf="userRoleSection == '5' && dropDownRoleSelection">
        <label class="roleSectionHeaderLabel">{{ 'General.User.UserRoleSectionDataRights' | texttransform }}</label>
        <label class="roleSectionHeaderLabelDesc">{{ 'General.User.UserRoleSectionDataRightsDescription' | texttransform
          }}</label>
        <div class="d-flex">
          <div class="userManagement__left">
            <div class="userManagement__sectionHeader">
              <div class="userManagement__sectionHeader__label">{{ 'General.Label.Available' | texttransform }}</div>
              <app-jj-icons [type]="'magnifying-glass'" [width]="20" [height]="20"></app-jj-icons>
              <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
                [(ngModel)]="filterString">
            </div>
            <div id="availableList"></div>
            <div class="projectUser__header__userList"
              [style.height.px]="uiService.getContainerHeightEx('availableList', 40, 300)">
              <div *ngFor="let userRight of userRights"
                [ngClass]="{ hideInDom: isFilterActive(userRight.defaultName, filterString) || isActiveUserRight(userRight) }">
                <div class="userManagement__item__container">
                  <div class="userManagement__item__name ellipsisText">{{ userRight.defaultName }}</div>
                  <div class="userManagement__item__move" (click)="toggleUserRight(userRight)">
                    <svg-icon src="assets/images/svg/icon-plus+.svg" class="userManagement__moveIcon"
                      *ngIf="dropDownRoleSelection != null" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="userManagement__left">
            <div class="userManagement__sectionHeader">
              <div class="userManagement__sectionHeader__label">{{ 'General.Label.Assigned' | texttransform }}</div>
              <app-jj-icons [type]="'magnifying-glass'" [width]="20" [height]="20"></app-jj-icons>
              <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
                [(ngModel)]="filterStringAssigned">
            </div>
            <div id="assignedList"></div>
            <div class="projectUser__header__userList"
              [style.height.px]="uiService.getContainerHeightEx('assignedList', 40, 300)">
              <div *ngFor="let userRight of userRights"
                [ngClass]="{ hideInDom: isFilterActive(userRight.defaultName, filterStringAssigned) || isActiveUserRight(userRight) == false }">
                <div class="userManagement__item__container userManagement__item__containerreverse">
                  <div class="userManagement__item__name ellipsisText">{{ userRight.defaultName }}</div>
                  <div class="userManagement__item__move" (click)="toggleUserRight(userRight)">
                    <svg-icon src="assets/images/svg/icon-minus+.svg" class="userManagement__moveIcon"
                      [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <span id="projectUserSectionData"></span>
        <div class="projectUserSectionData" [style.height.px]="uiService.getContainerHeightEx('projectUserSectionData', 40, 300)">
          <div *ngFor="let userRight of userRights" class="projectUserSectionAssignment"
          (click)="toggleUserRight(userRight)" [ngClass]="{ 'projectUserSectionAssignment__isActive': isActiveUserRight(userRight) }">
          {{ userRight.defaultName }}
        </div>
        </div> -->
        </div>

      </div>
    </div>
  </app-layout>
</div>