<div class="maxHeight">
<app-layout [header]="selectedReport.defaultName" [subheader]="selectedReport?.subHeader">
    <div class="defaultDashboardConsentContentWrapper">
        <div class="defaultDashboardConsentToolbarSelection">
            <div class="toolbar">
                <app-date-input [calendarOnly]="true" label="UTMDashboard.Label.FromDate" [(ngModel)]="fromDate" (dateChange)="updateDashboardValues()"></app-date-input>	
                <app-date-input [calendarOnly]="true" label="UTMDashboard.Label.ToDate" [(ngModel)]="toDate" (dateChange)="updateDashboardValues()"></app-date-input>	
                <app-select-input label="UTMDashboard.Label.Content" [options]="contents" [(ngModel)]="content" [complex]="true" display="option" (valueChange)="updateDashboardValues()"></app-select-input>
                <app-select-input label="UTMDashboard.Label.Campaigns" [options]="campaigns" [(ngModel)]="campaign" [complex]="true" display="option" (valueChange)="updateDashboardValues()"></app-select-input>                
                <app-select-input label="UTMDashboard.Label.Sources" [options]="sources" [(ngModel)]="source" [complex]="true" display="option" (valueChange)="updateDashboardValues()"></app-select-input>                
            </div>             
        </div>
    </div>

    <div *ngIf="loadingData == true">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </div>
    
    <div class="dataDiv" *ngIf="dashboardResultData[this.dashboardId]==null && loadingData == false">
        {{ 'General.Label.NoDataAvailable' | texttransform }}
    </div>
    <span id="dashboardContainerDesignSpan"></span>
    <div *ngIf="loadingData == false && dashboardResultData[this.dashboardId]!=null" class="chartWrapperBox"
        [style.height.px]="getControlHeight('dashboardContainerDesignSpan', 32)">       
        <div class="utmPies">     
            <div class="chartBox">
                <app-echarts id="#sourceChart" [chart]="'pie'" [title]="'UTMDashboard.Label.Sources' | texttransform"
                    [chartData]="getDataArray('sourceSave')" [darkmode]="false" [legend]="false" [height]="275" tooltipPosition="right"
                ></app-echarts>
            </div>
            <div class="chartBox">
                <app-echarts
                    id="#campaignChart" [chart]="'pie'" [title]="'UTMDashboard.Label.Campaigns' | texttransform"
                    [chartData]="getDataArray('campaignSave')" [darkmode]="false" [legend]="false" tooltipPosition="left" [height]="275"
                ></app-echarts>
            </div>
            <div class="chartBox">
                <app-echarts
                    id="#contentChart" [chart]="'pie'" [title]="'UTMDashboard.Label.Content' | texttransform" 
                    [chartData]="getDataArray('contentSave')" [darkmode]="false" [legend]="false" [height]="275" tooltipPosition="right"
                ></app-echarts>
            </div>
        </div>
        <div class="sourcesPerCampaign">
            <app-bar-chart 
                *ngIf="getFilteredDataArray().length > 0"
                [height]="getBarChartHeight()"
                barHeader="UTMDashboard.Label.SourcesPerCampaign"
                [chartData]="getFilteredDataArray()" 
                component="utmSourcesPerCampaign" 
                consentRef="sortArrayId"
                [controlid]="utmSourcesPerCampaign"
            >
        </app-bar-chart>
        </div>
    </div>
</app-layout>
</div>