import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { isNullOrUndefined } from 'util';
import { UserService } from 'app/core/authentication/services/user.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-extended-dropdown',
  templateUrl: './extended-dropdown.component.html',
  styleUrls: ['./extended-dropdown.component.scss']
})
export class ExtendedDropdownComponent implements OnInit {
  @Input() data = null;
  @Input() controlDefinition = null;
  @Input() dataKey: any;
  @Input() dataValue: any;
  @Input() disabled = false;
  @Output() onchanged = new EventEmitter<any>();
  @Input() controlValue: any = null;
  @Input() entityMemberlookupTable = null;
  
  dropdownGuid = null;
  valueArray = [];
  controlSettings = null;
  darkMode = false;

  constructor(
    public commonService: CommonService,
    private uiService: UiService,
    private eventService: EventService,
    public userService: UserService,
    public applicationInfoService: ApplicationInfoService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit() {
    this.dropdownGuid = 'jjDropdown'.concat(this.commonService.createGuid());
    this.splitValueToArray(); 
    if (!this.commonService.isNullOrUndefined(this.controlDefinition.settings)) {
      this.controlSettings = JSON.parse(this.controlDefinition.settings);
    }
    this.darkMode = this.uiService.getControlSettingOrDefault(this.controlDefinition, 'controlSettingGeneralDarkMode', false);    
  }

  splitValueToArray() {
    if (this.dataValue) {
      this.valueArray = this.dataValue.split(';');
      this.valueArray.forEach(valueArrayItem => {
        // console.log('splitValueToArray', valueArrayItem, this.commonService.isDate(valueArrayItem));        
      });
    }
  }

  emitValueChange() {
    this.onchanged.emit(this.controlValue);
  }

  clearValue() {
    this.controlValue = null;
    this.emitValueChange();
  }

  getDropdownWidth() {
    // let substractValue = 30;
    // if (this.controlValue) {
    //   this.controlValue = Number(this.controlValue);
    // }
    // if (!isNullOrUndefined(this.entityMemberlookupTable) && this.userService.hasPermission('CanUpdateUIControl')) {
    //   substractValue = 50;
    // }
    // const mainControlWidth = this.uiService.getDomControlWidth(this.dropdownGuid, substractValue);
    // return { 'width': mainControlWidth + 'px' };    
  }

  getTranslationById(nameTranslationId, defaultValue) {
    let text = this.axivasTranslateService.getTranslationTextForId(nameTranslationId);
    // const text = this.axivasTranslateService.translationTextArray.find(text => text.id == nameTranslationId);
    if (text) {
      return text;
    } else {
      return defaultValue;
    }    
  }

  addValueToLookupTable() {
    this.applicationInfoService.miscSettings['lookupTable'] = this.entityMemberlookupTable;
    this.eventService.showJjPopup('', 'lookuptables', 80, true);
  }

  getDatalist(data) {
    if (this.controlSettings == null) {
      return data;
    } else {
      if (this.controlSettings['dropdownUseFullProjectUserAsData'] == true) {
        return this.applicationInfoService.projectUsersAll.filter(user => 
          (user.isActive == true && user.userProjects[0].roledId != 4) || 
           user.id == this.controlValue )
          .sort((a, b) => a.userName < b.userName ? -1 : 1);
      } else {
        return data;
      }
    }     
  }
}
