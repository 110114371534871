import { Component } from '@angular/core';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { QuestionaireControlService } from '../questionaire-control/questionaire-control.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';

@Component({
  selector: 'app-questionnaire-report',
  templateUrl: './questionnaire-report.component.html',
  styleUrl: './questionnaire-report.component.scss'
})
export class QuestionnaireReportComponent {
  questionnaires = [];
  exportName = 'FragebogenExport';

  constructor(
    public questionaireControlService: QuestionaireControlService,
    private sharedAPI: SharedAPI,
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    this.questionaireControlService.getQuestionaires().then(questionnaires => {
      this.questionnaires = questionnaires;
    });
  }

  exportQuestionnaire() {
    const questionaireIds = [];
    this.questionnaires.forEach(questionnaire => {
      if (questionnaire.isExported) {
        questionaireIds.push(questionnaire.id);
      }
    });
    this.messagingService.showDefaultInfo('Supervisor.Label.QuestionnaireReport', 'QuestionnaireExport.Label.ExportStarted');
    this.sharedAPI.exportQuestionnaire({
      "projectId": this.applicationInfoService.projectID,
      "questionnaireIds": questionaireIds,
      "languageId": this.applicationInfoService.languageId,
      "columnHeaders": null,
      "fileName": this.exportName
    }).subscribe(exportQuestionnaireResult => {
      this.commonService.downloadFile(exportQuestionnaireResult, 'application/xlsx', 'Export '.concat(this.exportName, ' ',
        this.commonService.getDateTimeString(new Date(Date.now())), '.xlsx'));
      this.messagingService.showDefaultSuccess('Supervisor.Label.QuestionnaireReport', 'QuestionnaireExport.Label.ExportSuccess');
    }, (error) => {
      console.error(error);
      this.messagingService.showDefaultError('Supervisor.Label.QuestionnaireReport', 'QuestionnaireExport.Label.ExportError');
    });
  }
}