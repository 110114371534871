<app-layout header="Dashboard.Item.AccountOwner" [subheader]="selectedReport?.subHeader">
    <div class="defaultDashboardConsentContentWrapper">
        <div class="defaultDashboardConsentToolbarSelection">
            <div class="defaultDashboardConsentToolbarSelection__tabs">
                <app-select-input label="Account.Label.PoolId" [(ngModel)]="poolId" [options]="pools" display="defaultName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
            </div>             
        </div>
    </div>

    <div *ngIf="loadingData == true">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </div>
    
    <div class="dataDiv" *ngIf="dashboardResultData[this.dashboardId]==null && loadingData == false">
        {{ 'General.Label.NoDataAvailable' | texttransform }}
    </div>

    <div *ngIf="loadingData == false" class="defaultDashboardConsentTableContainer">
        <div *ngFor="let pool of pools">
            <app-bar-chart 
                *ngIf="getPoolData(pool).length > 0"
                [barHeader]="pool.defaultName"
                [chartData]="getPoolData(pool)" 
                component="accountOwner" 
                [consentRef]="sortArrayId"
                [controlid]="accountOwnerChart">
            </app-bar-chart>
        </div>
    </div>
</app-layout>