<div class="moveAccountOwnerWrapper" *ngIf="userService.hasPermission('CanUpdateTask')">    
    <div class="moveAccountOwnerContent">
        <app-layout header="AccountOwnerOrganisation.Label.MoveAccountOwners" *ngIf="mode == 0">            
            <div class="moveAccountOwnerMain">

                <div class="moveAccountOwnerNavigationMobile">
                    <div class="everleadMobileTab">
                        <div class="everleadMobileTab__valueWrapper">
                            <select [(ngModel)]="distributionMode" class="selectTab__box">
                                <option [ngValue]="0">{{'AccountOwnerOrganisation.Label.SingleTarget' | texttransform }}</option>
                                <option [ngValue]="1">{{'AccountOwnerOrganisation.Label.MultiTarget' | texttransform }}</option>
                                <option [ngValue]="2">{{'AccountOwnerOrganisation.Label.AssignEmpty' | texttransform }}</option>
                                <option [ngValue]="3">{{'AccountOwnerOrganisation.Label.ProMode' | texttransform }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="moveAccountOwnerNavigation">
                    <app-tab svg="user" [active]="distributionMode == 0" label="AccountOwnerOrganisation.Label.SingleTarget" (onClick)="distributionMode = 0"></app-tab>
                    <app-tab svg="users" [active]="distributionMode == 1" label="AccountOwnerOrganisation.Label.MultiTarget" (onClick)="distributionMode = 1"></app-tab>            
                    <app-tab svg="users" [active]="distributionMode == 2" label="AccountOwnerOrganisation.Label.AssignEmpty" (onClick)="distributionMode = 2"></app-tab>            
                    <app-tab svg="users" [active]="distributionMode == 3" label="AccountOwnerOrganisation.Label.ProMode" (onClick)="distributionMode = 3"></app-tab>            
                </div>

                <div *ngIf="distributionMode == 0">
                    <div class="moveAccountOwnerDescription">{{ 'AccountOwnerOrganisation.Label.MoveAccountOwnersDesc' | texttransform }}</div>
                </div>                

                <div *ngIf="distributionMode == 1">
                    <div class="moveAccountOwnerDescription">{{ 'AccountOwnerOrganisation.Label.MoveAccountOwnersMultipleDesc' | texttransform }}</div>
                </div>

                <div *ngIf="distributionMode == 2">
                    <div class="moveAccountOwnerDescription">{{ 'AccountOwnerOrganisation.Label.AssignEmptyDesc' | texttransform }}</div>
                </div>

                <div *ngIf="distributionMode == 3">
                    <div class="moveAccountOwnerDescription">{{ 'AccountOwnerOrganisation.Label.AssignEmptyProDesc' | texttransform }}</div>
                </div>

                <app-select-input label="AccountOwnerOrganisation.Label.MoveAccountOwnersSourceUser" [(ngModel)]="fromUserId" 
                    [options]="projectUsers" display="userName" bind="id" *ngIf="distributionMode == 0 || distributionMode == 1"></app-select-input>
                
                <ng-container *ngIf="distributionMode == 0">
                    <app-select-input label="AccountOwnerOrganisation.Label.MoveAccountOwnersTargetUser" [(ngModel)]="toUserId" 
                        [options]="projectUsers" display="userName" bind="id"></app-select-input>
                    <div *ngIf="fromUserId == toUserId && toUserId != null" class="moveAccountOwnersError">
                        {{ 'AccountOwnerOrganisation.Label.SourceAndTargetShouldNotMatch' | texttransform }}
                    </div>    
                    <app-main-button svg="arrows-left-right" (onClick)="reassignAccounts()" label="AccountOwnerOrganisation.Label.AssignNewOwner"
                        [disabled]="fromUserId == null || toUserId == null || fromUserId == toUserId"></app-main-button>
                </ng-container>

                <div *ngIf="distributionMode == 1" class="multiUserContainer">
                    <div class="moveAccountOwnerDescription">{{ 'AccountOwnerOrganisation.Label.SelectUserToDistribute' | texttransform }}</div>
                    <div class="targetUserList">
                        <div *ngFor="let projectUser of projectUsers" class="everleadTableRow moveAccountOwnerUserList" (click)="toggleUser(projectUser.id)" 
                            [ngClass]="{ 
                                projectUserActive: commonService.checkIfItemIsInArray(multiUserIds, projectUser.id),
                                hideInDom: projectUser.id == fromUserId
                            }">
                            {{ projectUser.userName }}
                        </div>
                    </div>                    
                    <app-main-button svg="arrows-left-right" (onClick)="reassignAccounts(true)" label="AccountOwnerOrganisation.Label.MoveAccountOwners"
                        [disabled]="fromUserId == null || multiUserIds.length == 0"></app-main-button>
                </div>

                <div *ngIf="distributionMode == 2" class="multiUserContainer">
                    <div class="moveAccountOwnerDescription">{{ 'AccountOwnerOrganisation.Label.SelectUserToDistribute' | texttransform }}</div>
                    <div class="targetUserList">
                        <div *ngFor="let projectUser of projectUsers" class="everleadTableRow moveAccountOwnerUserList" (click)="toggleUser(projectUser.id)" 
                            [ngClass]="{ 
                                projectUserActive: commonService.checkIfItemIsInArray(multiUserIds, projectUser.id),
                                hideInDom: projectUser.id == fromUserId
                            }">
                            {{ projectUser.userName }}
                        </div>
                    </div>                    
                    <app-main-button type="small" svg="arrows-left-right" (onClick)="assignAllUnassigned()" label="AccountOwnerOrganisation.Label.AssignAccountOwners"
                        [disabled]="multiUserIds.length == 0"></app-main-button>
                </div>        
                <div *ngIf="distributionMode == 3" class="multiUserContainer">
                    <div [innerHTML]="innerHTML" class="everleadMaxHeightOverflow"></div>
                </div>
            </div>
        </app-layout>
    </div>
</div>