import { Injectable } from '@angular/core';
import { Dictionary } from 'app/core/dictionary';

@Injectable({
  providedIn: 'root'
})
export class GraphqlqueryService {
  queryDict: Dictionary<any> = new Dictionary<any>();

  // Project selection form
  projectSelectionMyLastUsedUserProjects = `query { myLastUsedUserProjects(numberOfLastProjects:10)
    { id projectId project { projectName dataInstanceId customerId }}}`;
  projectSelectionProjects = `query { projects { id projectName customerId dataInstanceId
    agentMaskingSpecialNumber hasCampaignsWithOwnTimeRecordingProject
    agentMaskingType isUsingDataRights } }`;
  projectSelectionLogicalEntities = `query { logicalEntities (projectId: <projectId>) {
    id
    defaultName
    entityId
    editContainerLogicalControlId
    listContainerLogicalControlId
    nameTranslationToken {tokenFullName}
    descriptionTranslationToken {tokenFullName}
    templatesFilters {id defaultName}
  }}`;
  // projectSelectionCustomers = `query { customers { customerName id }}`;
  // projectSelectionCustomer = `query { customer (id: <customerID>)
  //   { customerName id projects { projectName projectEnd id customerId ownerUserId }}}`;

  // PowerButton
  powerButtonPossibleBookingResults = `query { possibleBookingResults (projectId:<projectId>,stepId:<stepId>)
    {
      id
      resultOrder
      nameTranslationToken { tokenFullName }
      descriptionTranslationToken { tokenFullName }
      leadStatus { id lookupTableId lookupTablePositivity { positivityValue } }
      defaultName
      isActive
    }}`;

  // EntityService
  entityServiceEntities = `query {
    entities(projectId: <projectId>)
    {
      entityExternalDataSources {
        externalDataSource { dataKey dataQuery queryType dataValue languageDependant type schema
          identifier source validity isSortedAscending sortColumn isCacheable
          externalDataSourceQueryMappingFields { type source target defaultValue id }
        }
      }
      nameTranslationTokenId
      nameTranslationToken { id tokenFullName }
      descriptionTranslationTokenId
      descriptionTranslationToken { id tokenFullName }
      lookupEntityDynamicalType { id defaultName }
      uiEntityInstances { id logicalUiEntityInstanceId }
      id
      entityName
      entityMembers {
        lookupTableId
        lookupTableSource
        isGraphqlQueryable
        externalDataSource { id dataKey dataQuery dataValue type source identifier }
        entityMemberValidities { defaultName descriptionTranslationToken {tokenFullName}
          nameTranslationToken {tokenFullName} regExJs validityType
        }
        defaultName
        descriptionTranslationToken { tokenFullName }
        id
        nameTranslationToken {tokenFullName }
        memberType
      }
    }
  }`;

  // Account form
  accountTryLockAccountMutation = `mutation { tryLockAccountMutation(projectId: <0>,accountId:<1>,lockingUserId:<userId>)
    { id accountId lockerUserId lockTill account { id name1 }}}`;

  // Projectusers form
  projectUsersDomainUsers = 'query { users { id userName email isExternalUser isActive }}';
  projectUsersUserProject = `query { userProject  ( projectId: <projectID>  , roleId: <roleID>)
    { id projectId userId roleId user { id userName email }}}`;
  projectUsersProjects = `query { projects { id projectName } }`;
  // projectUsersCreateUserProjectMutation = `mutation { createUserProjectMutation ( newUserProject:
  //   {userId: <userID> , projectId:<projectID>, roleId: <roleID> })
  //   { id } } `;
  // projectUsersDeleteUserProjectsMutation = 'mutation { deleteUserProjectsMutation(id: [<ids>]) }';
  projectUsersRoles = `query { roles { id nameTranslationToken{tokenFullName} nameTranslationTokenId permissionLevel projectId }}`;


  // UI Service
  uiServiceControlQueryTemplate2 = 'query { projectControlArray(projectId: <projectId>) { id childs { id childs { id }}}}';
  uiServiceControlQueryTemplate = `query { projectControlArray ( projectId: <projectId> ) {
      <uiServiceControlBaseString>
  }}`;

  uiServiceControlBaseString = `id labelPos displayText displayTextWidth displayTextHeight reserverSpaceLeft parentControlId lookupVisibilityId
  parentContainerId width height value uiEntityInstanceId inline additionalCssClasses isActive additionalCssClassesMainControl isVisible mobileRow
  additionalCssClassesMainLabel isFieldFilterable tooltip entity entityMemberId additionalSetting1 additionalSetting2 additionalSetting3 settings
  maxXResolution maxYResolution minXResolution minYResolution minWidth maxWidth projectId isDeveloperOnly
  icon order controlType externalDataSourceId order logicalControlId isRequired isResetVisible isFieldActivatable isEnabled
  attributeModifiers { id compareValueType compareType compareValue order refValue attribute newValue }
  controlAttributeModifierGroups { id controlId connection attribute newValue order
    controlAttributeModifiers { id compareValueType compareType compareValue order refValue attribute newValue } }

  nameTranslationTokenId
  nameTranslationToken { id tokenFullName }
  descriptionTranslationTokenId
  descriptionTranslationToken { id tokenFullName }
  uiControlSecurityPermissions {
    id securityPermission permissionType
  }
  uiControlExternalDataSources {
    id
    externalDataSource {
      id
      dataQuery
      dataKey
      type
      dataValue
      queryType
      schema
      isSortedAscending
      sortColumn
      isCacheable
      source
      validity
      identifier
      externalDataSourceQueryMappingFields
      { id source target type defaultValue }
    }
  }
  childs { id childs { id childs { id }}}
  methods { id method type parentControlMethodId order parameters { id type value order }
  conditions { id compareValue compareValueType compareType refValue refValueType returnValue }
    childs  { id method type parentControlMethodId order parameters { id type value order }
    conditions { id compareValue compareValueType compareType refValue refValueType returnValue }
      childs  { id method type parentControlMethodId order parameters { id type value order }}
      conditions { id compareValue compareValueType compareType refValue refValueType returnValue }
    }
  }`;

  uiServiceControlBaseQuery =  `id order logicalControlId`;
  uiServiceControlQuery: string = `query { projectControlOfContainer ( projectId:<projectId> ,
    onlyActive: false, containerLogicalId:<controlId>)
    {` + this.uiServiceControlBaseQuery + `<subChilds>` + `}}`;

  uiServiceCustomerControlQuery = `query { customerProjectControl ( projectId:<projectId> )
     { id value uiEntityInstanceId }}`;

  // UserService
  userServicecurrentUser = `{
    currentUser {
      id
      firstName
      lastName
      email
      isExternalUser
      trialEndsAt
      trialExtensionDays
      trialExtensionGuid
      userSettings
      customerId
      userProjects {
        id
        poolId
        projectId
        project {
          id
          projectName
          projectEnd
          projectStart
          customer {
            customerName
          }
        }
      }
      userLanguage {
        id
        languageName
      }
      userName
      securityUserAuthenticationUsers {
        authenticationUserId
        id
      }
      settings {
        id
        key
        value
        userId
      }
      fakeUserId
      fakeUser {
        id
        userName
        userLanguage {
          id
          languageName
        }
      }
    }
  }`;
  userServiceUserRoles = `query { rolesOfCurrentUser { id nameTranslationToken { tokenName }}}`;

  // MethodService
  methodServiceSingleMethodQuery = `query { method(id: {methodID}) {
      controlId id order parentControlMethodId type method
      parameters { id type order value }
      childs { id }
    }}`;

  // ProjectService
  projectServiceCampaigns = `query { campaigns ( projectId: <projectId> )
    { 
      defaultName 
      isActive 
      sesConfigSetId
      nameTranslationToken { tokenFullName } 
      id
      timeRecordingProjectId 
    }
  }`;
  projectServiceWorkflows = `query { workflows (projectId: <projectId> ) {
  	defaultName nameTranslationToken { tokenFullName } id starterStep { id}
    workflowSteps {
      id nameTranslationToken {tokenFullName} defaultName stepOrder isFollowUp workflowId typeLookupId
      results { isConsentRequired nameTranslationToken {tokenFullName} defaultName id workflowStepResultTypeId
        isContactRequired isPersonalFollowup
        isEventResult
        isActive typeLookupId
        isSendEmailToFollowUpUser
        isOpportunityCreation
        followUpUserId
        stepId
        emailTemplateId
        isEditableEmail
        isNotesRequired
        leadStatus {id lookupTable { id defaultName}}
      questions {
        isMandatory
        parentAnswerId
        defaultName
        isMultiselect
        id
        nameTranslationToken{ id tokenFullName }
        descriptionTranslationToken { id tokenFullName }
        answers {
          defaultName
          nameTranslationToken{ id tokenFullName }
          descriptionTranslationToken { id tokenFullName }
          id
          order
          typeLookupId
          typeLookup {
            defaultName
          }
        }
      }
    }}}}`;
  projectServiceFollowUpUserGroups = `query { followUpUserGroups(projectId: <projectId> userId: <userId>) {
    id
    defaultName
    nameTranslationToken {tokenFullName}
    campaignId
  }}`;

  // projectServiceProject = `query { project (id: <projectId>) {
  //     pools {
  //       defaultName
  //       projectId
  //       isActive
  //       descriptionTranslationToken {tokenFullName}
  //       nameTranslationToken {tokenFullName}
  //       id
  //     }
  //     leadreportLevel
  //     leadreportLanguageId
  //     leadreportTemplateDefinitionId
  //     leadreportFiletype
  //     leadreportOnlyNetResults
  //     isAutomaticLeadreportUploadEnabled
  //     leadreportFtpPath
  //     leadreportZipFilePassword
  //     leadreportExportSettingLookupId
  //     automaticLeadreportUploadSince
  //     ftpConnectionId
  //     agentMaskingType
  //     isUsingDataRights
  //     consentUi
  //     mediaIds
  //     defaultCampaignId
  //     defaultWorkflowId
  //     phoneSystemLookupId
  //     agentMaskingSpecialNumber
  //     timeRecordingProjectId
  //     leadreportBoxPath
  //     projectLogo
  //     projectBanner
  //     projectGuid
  // 		uiStyle { id style }
  //     projectName
  //     projectEnd
  //     isUsingWorkflow
  //     projectStart
  //     dataInstanceId
  //     mailConfigTypeLookupId
  //     mailConfigRefId
  //     dateCreated
  //     customerId
  //     id
  //     iframeContent
  //     isUsingNewDesign
  //     dmcAccountId
	// 	  activeSync
	// 	  trackingSchema
  //     isClassicView kram
  //     telemarketingStart telemarketingEnd netCallTarget reportTypeLookupId
  //     isLoadingTaskOnLoginForAgent
  //     contactFrequencyWeekly
  //     contactFrequencyDaily
  //     contactFrequencyMonthly
  //     countOfCallsPerHour
  //     countOfNetcallsPerHour
  //     leadQuota
  //     languageId
  //     selectOnlyContactOnTaskCreation
  //     hasCampaignsWithOwnTimeRecordingProject
  //     projectSourceId
  //     dataInstanceDatabaseDetail {
  //       isSchemaTracked
  //     }
	//   }
  // }`;

  projectServiceProjectById = `query { project (id: <0>) {
    id
    customerId
    pools {
      defaultName
      projectId
      isActive
      descriptionTranslationToken {tokenFullName}
      nameTranslationToken {tokenFullName}
      id
    }
      leadreportLevel
      leadreportLanguageId
      leadreportTemplateDefinitionId
      leadreportFiletype
      leadreportOnlyNetResults
      isAutomaticLeadreportUploadEnabled
      leadreportFtpPath
      leadreportZipFilePassword
      leadreportExportSettingLookupId
      automaticLeadreportUploadSince
      ftpConnectionId
      agentMaskingType
      defaultCampaignId
      isUsingDataRights
      consentUi
      mediaIds
      defaultWorkflowId
      phoneSystemLookupId
      agentMaskingSpecialNumber
      timeRecordingProjectId
      projectLogo
      projectBanner
  		uiStyle { id style }
      projectName
      projectEnd
      projectGuid
      isUsingWorkflow
      projectStart
      dataInstanceId
      mailConfigTypeLookupId
      mailConfigRefId
      dateCreated
      customerId
      id
      iframeContent
      isUsingNewDesign
      dmcAccountId
		  activeSync
		  trackingSchema
      isClassicView
      telemarketingStart telemarketingEnd netCallTarget reportTypeLookupId
      isLoadingTaskOnLoginForAgent
      contactFrequencyWeekly
      contactFrequencyDaily
      contactFrequencyMonthly
      countOfCallsPerHour
      countOfNetcallsPerHour
      leadQuota
      languageId
      selectOnlyContactOnTaskCreation
      hasCampaignsWithOwnTimeRecordingProject
      projectSourceId
  }
  }`;

  projectServiceGetQuestionaires = `query { entities (projectId: <projectId>) {
    id
    entityName
    uiEntityInstances{id entityId logicalUiEntityInstanceId}
    entityMembers {
      lookupTableId
      lookupTableSource
      isGraphqlQueryable
      externalDataSource { id dataKey dataQuery dataValue type source identifier }
      entityMemberValidities { defaultName descriptionTranslationToken {tokenFullName}
        nameTranslationToken {tokenFullName} regExJs validityType
      }
      defaultName
      descriptionTranslationToken { tokenFullName }
      id
      nameTranslationToken {tokenFullName }
      memberType
    }
    nameTranslationToken {id tokenName}
    lookupEntityDynamicalType { id defaultName }
  }}`;
  projectServiceBookNotRelevantCp = `mutation {
    bookNotRelevantCp (projectId: <projectId> taskId: <0> newCpId: <1>) {
      id
    }
  }`;
  projectServiceBookTask = `mutation {
    bookTask (projectId: <projectId>,
      oldAdjustedTask: {
        id:<0>, resultId: <2>, isFollowUpAppointment: <6>, followUpUserId: <3>, followUpDate: "<4>" opportunityId: <7>
        isAgentExclusive: <8> questionAnswers: [<5>] isNotifyFollowUpOwner: <9>
      }
      <templateContentOverride>)
    {
      id stepId accountId contactId followUpDate followUpTaskId followUpUserId resultId isDeleted subject
    }}`;
  projectServiceDataInstance = `query { dataInstance(id: <instanceId>) {
      postUrl
      urlRessource { urlDev urlDevBeta urlLive urlLiveBeta id }
      defaultDataInstanceDatabaseDetail {id dataInstanceId schema}
    }}`;
  projectServiceCustomer = `query { customer (id: <customerId>) { customerName id, defaultDataInstanceId }}`;

  projectServiceTryUnlockAccountMutation = `mutation { tryUnlockAccountMutation(
    projectId:<projectId>, accountId:<accountId>, lockingUserId:<userId> )
    { id accountId lockerUserId lockTill account { id name1 }}}`;
  projectServiceNextFollowUp = `query { nextFollowUp (projectId: <projectId>, userId: <userId>, followUpGroupId: <0>
      poolId: <poolId>) {
    id
  	notes
  	stepId
  	subject
  	resultId
  	accountId
  	contactId
    account { id }
    contact { id }
  }}`;

  // SettingService
  settingServiceUpdateUserSettingMutation = `mutation { updateUserSettingMutation ( userSetting:
    { id: <SettingID> value:"<Value>" }) { id }}`;
    settingServiceUCreateUserSettingMutation = `mutation { createUserSettingMutation ( newSetting:
    { userId: <userID> key:"<Key>" value:"<Value>" }) { id }}`;

  // ResultPreview-Control
  resultPreviewControlPreviewBookingTask = `{ previewBookingTask( projectId: <projectId> resultId: <0>) {
  	previewText {defaultText messageTokenName messageParameters }
    defaultNextFollowUpDate
    hasFollowUpTask
	  }
  }`;

  // automaticRecordAssignment
  automaticRecordAssignmentDeleteFollowUpUserGroupPrioritiesOfGroupMutation = `mutation { deleteFollowUpUserGroupPrioritiesOfGroupMutation
     (followUpUserGroupId: <0>) {id}}`;
  automaticRecordAssignmentCreateFollowUpUserGroupPriorityMutation = `mutation { createFollowUpUserGroupPriorityMutation
    (newFollowUpUserGroupPriority: {
    entityId: <0>
    memberId: <1>
    ascending: <2>
    order: <3>
    followupUserGroupId: <4>
  }) {id}}`;
  automaticRecordAssignmentFollowUpUserGroupPriorities = `query { followUpUserGroupPriorities (followUpUserGroupId: <0>) {
    id
    ascending
    memberId
    entityId
    entity{id entityName}
    entityMember{id defaultName}
    order
  }}`;
  automaticRecordAssignmentFollowUpUserGroup = `query { followUpUserGroup (id: <0>)
    {
      id
      filterId
      defaultName
      minutesUntilRelease
      hoursNoDialing
      hoursAutoFollowup
      campaignId
      followupTypePriorities {order}
      followupUserGroupUsers {userId}
      nameTranslationToken {tokenFullName id}
      descriptionTranslationToken {tokenFullName id}
    }
  }`;
  automaticRecordAssignmentFollowUpUserGroups = `query { followUpUserGroups (projectId: <projectId>)
    {
      id
      defaultName
      campaignId
      nameTranslationToken {tokenFullName}
      descriptionTranslationToken {tokenFullName}
    }
  }`;
  automaticRecordAssignmentFollowUpUserGroupTypes = `query { followUpUserGroupTypes (followUpUserGroupId: <0>) {
  	  followupType { defaultName nameTranslationToken {tokenFullName} id }
      order
      followupTypeId
      id
	  }
  }`;
  automaticRecordAssignmentDeleteUserGroupPriority = `mutation { deleteFollowUpUserGroupPriorityMutation (id: <0>) {id}}`;
  automaticRecordAssignmentUpdateFollowUpUserGroupMutation = `mutation { updateFollowUpUserGroupMutation ( followUpUserGroup: {
    id: <0>
    defaultName: "<1>"
    minutesUntilRelease: <2>
    hoursAutoFollowup: <3>
    hoursNoDialing: <4>
    filterId: <5>
    campaignId: <6>
  })
    { id }
  }`;
  automaticRecordAssignmentUpdateFollowUpUserGroupTypeMutation =
    `mutation { updateFollowUpUserGroupTypeMutation(followUpUserGroupType: { id: <0> order: <1> }) { id }}`;
  automaticRecordAssignmentCreateFollowUpUserGroupUserMutation = `mutation { createFollowUpUserGroupUserMutation
     ( newFollowUpUserGroupUser: { followupUserGroupId: <0> userId: <1> }) { id }}`;
  automaticRecordAssignmentFollowUpUserGroupUsers =
     `query { followUpUserGroupUsers(followUpUserGroupId: <0>) { id user { userName, id }}}`;
  automaticRecordAssignmentDeleteFollowUpUserGroupUsersMutation = `mutation { deleteFollowUpUserGroupUsersMutation(id: [<0>])}`;
  automaticRecordAssignmentDeleteUserGroupMutation = `mutation { deleteFollowUpUserGroupMutation (id: <0> ) {id}}`;
  automaticRecordAssignmentCreateFollowUpUserGroupMutation = `mutation { createFollowUpUserGroupMutation (newFollowUpUserGroup: {
    projectId: <projectId>
    defaultName: "<0>"
  }) { id }}`;

  // dataFilter mainpanel component

  dataFilterMainPanelFilterDefinitions =  `query { filterDefinitions (projectId: <projectId> filterTypeId: <0>) {
    id
    mainEntityId
    descriptionTranslationToken {tokenFullName}
     projectId
    defaultName
    lookupFilterTypeId
  }}`;

  dataFilterFollowUpUserGroupsByFilterId = `query { followUpUserGroupsByFilter(filterId: <0>) {defaultName id}}`;
  dataFilterCreateFilterDuplicateMutation = `mutation { createFilterDuplicateMutation (sourceFilterId: <0>
      newFilterDefaultName: "<1>") { id }}`;
  dataFilterSegmentMemberEntities = `query { entities (projectId: <projectId>) {
    id
    entityName
    nameTranslationToken { id tokenFullName }
    entityMembers {
      defaultName id nameTranslationToken { id tokenFullName }
  }}}`;
  dataFilterSegmentMemberEntityMember = `query { entity (projectId: <projectId>) { id entityName }}`;
  dataFilterCreateFilterMutation = `mutation { createFilterMutation (projectId: <projectId> newFilter: {
    defaultName: "<0>"
    lookupSegmentOperatorId: <1>
    lookupFilterTypeId: <3>
    projectId: <projectId>
    mainEntityId: <2>
  }) {id}}`;
  dataFilterUpdateFilterMutation = ``;
  dataFilterDeleteFilterMutation = ``;
  dataFilterFilterSegment = `query {
    filterSegment(id: <0>) {
      id
      lookupSegmentOperatorId
      filterSegmentMembers {
        id
      }
  }}`;

  dataFilterCreateFilterSegmentMutation = `mutation { createFilterSegmentMutation (newFilterSegment: {
    filterId: <0>
    lookupSegmentOperatorId: 13
    segmentNumber: <1>
  }) {id}}`;
  dataFilterUpdateFilterSegmentMutation = `mutation { updateFilterSegmentMutation (filterSegment:{
    id: <0>
    lookupSegmentOperatorId: <1>
    filterId: <2>
    segmentNumber: <3>
  }) {id}}`;
  dataFilterDeleteFilterSegmentMutation = `mutation { deleteFilterSegmentMutation (
    filterId: <0>
    filterSegmentId: <1>
  ) {id}}`;

  dataFilterFilterSegmentMember = `query { filterSegmentMember (id: <0>) {
    id
    entity { entityName id nameTranslationToken { id tokenFullName }}
  	entityId
  	entityMember { defaultName id nameTranslationToken { id tokenFullName }}
    memberId
    isNegation
    isRelevantForAllEntries
  	lookupFilterOperator{id isArrayOperator lookupTable{defaultName}}
  	filterParameter
  	filterParameter2
  }}`;


  dataFilterFilterSegmentOperators = `query { filterSegmentOperators (onlyActive: true) {defaultName id}}`;
  dataFilterPreviewAccount = `query {
    accounts( projectId: <projectId> onlyActive: true poolId: <poolId> campaignId: 0
      filters: [{ filterId: <0> parameters: [] }]
    ) { id <1>}}`;

  // developer project administration
  developerProjectAdministratonGetProjects =
  `query {
    projects {
      id
      projectName
      dataInstanceDatabaseDetailId
      customerId
    }
  }`;

  developerProjectAdministratonGetSchemas =
  `query {
    dataInstanceDatabaseDetails (dataInstanceId: <0>) {
      id
      schema
    }
  }`;

  updateProjectAdministratonSchemaMutation =
  `mutation {
    updateProjectMutation(project: {id:<0> dataInstanceDatabaseDetailId: <1>  }  )
    {
      id
      dataInstanceDatabaseDetail {id}
    }
  }`;

  // export templates
  exportTemplates =
  `query {
    exportTemplates (projectId: <0>) {
      id
      defaultName
      nameTranslationTokenId
    }
  }`;

  // Leadreports
  leadReportsTemplateDefinitions = `query {
    templateDefinitions(projectId: <projectId>) {
      id
      defaultName
      templates {
        id
        htmlTemplate
      }
    }
  }`;
  leadReportsLevels = `query {
    centralLookupTableDefinitions(projectId: <projectId>, defintionsIds: 24) {
    id defaultName order nameTranslationToken {tokenFullName}
    descriptionTranslationToken {tokenFullName}
  }}`;
  leadReportsContacts = `query { contacts(projectId: <projectId>, accountId: <accountId>, onlyActive: true) { firstName lastName id }}`;

  // MyLeads
  myLeadsFilterQuerySegment = `{ filterId: <filterId> parameters: <filterParameters> }`;
  // myLeadsAccountQuery = `query {
  //   accounts( projectId: <projectId> onlyActive: true poolId: <poolId>
  //     filters: [<0>]
  //   ) { id <1>}}`;

  // Languages
  languages =
  `query {
    translationLanguages {
      id
      languageName
      nameTranslationTokenId
      nameTranslationToken { id tokenFullName }
      descriptionTranslationTokenId
      descriptionTranslationToken { id tokenFullName }
    }
  }`;

  // Misc
  miscEntitySubRelationships = `query {
    entitySubRelationships(entityId: <0>) {
      entityId
      otherEntityId
      entity {id entityName}
      otherEntity {id nameTranslationToken {tokenFullName} entityName}
      isSubEntity
  }}`;

  // External content service
  externalContentServiceUiStyle = `query { uiStyles (ids:[<0>]) { style comment id }}`;

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // create new Task
  createTaskCreateTask = `mutation { createTask (projectId: <projectId>
    newTask:{ <0> questionAnswers: [<subResults>] }) {id} }`;

  // OptInOptOut
  optInOptOutOptInOptOuts = `query { optInOptOuts (projectId: <projectId>, accountId: <accountId>)
    { accountId id contactId startDate endDate comment lookupTypeRefId lookupMediumRefId}
  }`;
 optInOptOutAccount = `{ account(projectId: <projectId>, id: <0>) {
      currentOptStatus
      currentOptStatusTranslationTokenFullName
    }
  }` ;

  // QM
  qmQmTask = `mutation { qmTask (projectId: <projectId>, qm: {
    taskId: <0>
    qmStateLookupId: <1>
    rejectedText: "<2>"
  }) {id}}`;

  // ActivityForm
  appointmentViewUndoActivity = 'mutation { undoActivity(projectId: <projectId> taskId: <0>) {id}}';

  // Reporting
  reportingBaseReports = `query { baseReports (projectId: <projectId>)
    { id projectId defaultName nameTranslationToken{ id tokenFullName } nameTranslationTokenId
      descriptionTranslationTokenId
      reportParameters {
        defaultName order value isMandatory id
        nameTranslationToken {id tokenFullName} defaultName descriptionTranslationToken {tokenFullName}
        defaultValue isUserParameter externalDataSource { id dataKey dataValue }
        parameterTypeLookup {defaultName id}
      }
  }}`;

  // MakeCall
  makeCallIsContactAllowedDueOptOut = `{ isContactAllowedDueOptOut( projectId: <projectId> accountId: <0> contactId: <1> mediumId: 45 )}`;

  // Externaldatasources
  externaldatasources = `query { externalDataSources { id dataKey dataQuery queryType dataValue languageDependant type schema
    identifier source validity isSortedAscending sortColumn isCacheable
    externalDataSourceQueryMappingFields { type source target defaultValue id } }}`;

  loadProjectDetails = `
  query {
    applicationTipps {
      defaultName
      id
      projectId
      typeLookupId
      teaserTranslationTokenId
      nameTranslationTokenId
      descriptionTranslatioTokenId
      tippGuid
    }

    applicationSettings(projectId: <projectId>) {
      id
      value
      comment
      setting
      projectId
      lookupSettingType
    }

    userSettings(userId: <userId>) {
      id
      key
      userId
      value
    }

    uiContainerArrayUiControls(projectId: <projectId>, uiContainerIds: [19,
      1300,
      1400,
      23111,
      24929,
      27429,
      27465,
      32733,
      34038,
      36051,
      38353,
      49820]) {
      id
      order
      projectId
      uiControlLogicalId
      uiContainerLogicalId
    }


    translationsByProjectId(projectId: <projectId>, languageId: <0>) {
      id
      translationToken {
        id
        tokenFullName
      }
      translation
    }


    projectControlArray(projectId: <projectId>) {
      id
      labelPos
      displayText
      displayTextWidth
      displayTextHeight
      reserverSpaceLeft
      parentControlId
      lookupVisibilityId
      parentContainerId
      width
      height
      value
      uiEntityInstanceId
      inline
      additionalCssClasses
      isActive
      additionalCssClassesMainControl
      isVisible
      mobileRow
      additionalCssClassesMainLabel
      isFieldFilterable
      tooltip
      entity
      entityMemberId
      additionalSetting1
      additionalSetting2
      additionalSetting3
      settings
      maxXResolution
      maxYResolution
      minXResolution
      minYResolution
      minWidth
      maxWidth
      projectId
      isDeveloperOnly
      icon
      order
      controlType
      externalDataSourceId
      order
      logicalControlId
      isRequired
      isResetVisible
      isFieldActivatable
      isEnabled
      attributeModifiers {
        id
        compareValueType
        compareType
        compareValue
        order
        refValue
        attribute
        newValue
      }
      controlAttributeModifierGroups {
        id
        controlId
        connection
        attribute
        newValue
        order
        controlAttributeModifiers {
          id
          compareValueType
          compareType
          compareValue
          order
          refValue
          attribute
          newValue
        }
      }
      nameTranslationTokenId
      nameTranslationToken {
        id
        tokenFullName
      }
      descriptionTranslationTokenId
      descriptionTranslationToken {
        id
        tokenFullName
      }
      uiControlSecurityPermissions {
        id
        securityPermission
        permissionType
      }
      uiControlExternalDataSources {
        id
        externalDataSource {
          id
          dataQuery
          dataKey
          type
          dataValue
          queryType
          schema
          isSortedAscending
          sortColumn
          isCacheable
          source
          validity
          identifier
          externalDataSourceQueryMappingFields {
            id
            source
            target
            type
            defaultValue
          }
        }
      }
      childs {
        id
        childs {
          id
          childs {
            id
          }
        }
      }
      methods {
        id
        method
        type
        parentControlMethodId
        order
        parameters {
          id
          type
          value
          order
        }
        conditions {
          id
          compareValue
          compareValueType
          compareType
          refValue
          refValueType
          returnValue
        }
        childs {
          id
          method
          type
          parentControlMethodId
          order
          parameters {
            id
            type
            value
            order
          }
          conditions {
            id
            compareValue
            compareValueType
            compareType
            refValue
            refValueType
            returnValue
          }
          childs {
            id
            method
            type
            parentControlMethodId
            order
            parameters {
              id
              type
              value
              order
            }
          }
          conditions {
            id
            compareValue
            compareValueType
            compareType
            refValue
            refValueType
            returnValue
          }
        }
      }
    }
  }`;

  loadFullProjectInformation = `query {
    projectUsers(projectId: <projectId> campaignId: null) {
      id userName email firstName lastName trialEndsAt isSentWelcomeEmail isExternalUser
    }

    tableLayoutDefinitions(projectId: <projectId>) {
      id defaultName mainEntityName filterId jsonLayout jsonSettings mainEntityId logicalControlId projectId
    }

    followUpUserGroups(projectId: <projectId>, userId: <userId>) {
      id
      defaultName
      nameTranslationToken {
        tokenFullName
      }
      campaignId
    }

    dashboards(projectId: <projectId>) { id projectId jsonLayout jsonSettings logicalUiControlId name isActive }

    roles { id nameTranslationTokenId nameTranslationToken { id tokenFullName} isAdmin permissionLevel isSupervisor }

    workflows(projectId: <projectId>) {
      defaultName
      nameTranslationToken {
        tokenFullName
      }
      id
      starterStep {
        id
      }
      workflowSteps {
        id
        nameTranslationToken {
          tokenFullName
        }
        defaultName
        stepOrder
        isFollowUp
        workflowId
        bookTaskAfterXDays
        bookTaskAfterXDaysResultId
        typeLookupId
        results {
          isConsentRequired
          nameTranslationToken {
            tokenFullName
          }
          defaultName
          id
          workflowStepResultTypeId
          isContactRequired
          isPersonalFollowup
          isEventResult
          isActive
          isSendEmailToFollowUpUser
          isOpportunityCreation
          followUpUserId
          stepId
          nextStepId
          leadStatusId
          emailTemplateId
          isEditableEmail
          isNotesRequired
          leadStatus {
            id
            lookupTable {
              id
              defaultName
            }
          }
          questions {
            isMandatory
            parentAnswerId
            defaultName
            isMultiselect
            id
            answers {
              defaultName
              nameTranslationToken {
                tokenFullName
              }
              id
              order
              typeLookupId
              typeLookup {
                defaultName
              }
            }
          }
        }
      }
    }

    entities(projectId: <projectId>) {
      entityEntityDefinitions { id entityDefinition { projectId id order entityDefinitionName }}
      entityExternalDataSources {
        externalDataSource {
          dataKey
          dataQuery
          queryType
          dataValue
          languageDependant
          type
          schema
          identifier
          source
          validity
          isSortedAscending
          sortColumn
          isCacheable
          externalDataSourceQueryMappingFields {
            type
            source
            target
            defaultValue
            id
          }
        }
      }
      nameTranslationTokenId
      nameTranslationToken {
        id
        tokenFullName
      }
      descriptionTranslationTokenId
      descriptionTranslationToken {
        id
        tokenFullName
      }
      lookupEntityDynamicalType {
        id
        defaultName
      }
      uiEntityInstances {
        id
        logicalUiEntityInstanceId
      }
      id
      entityName
      entityMembers {
        lookupTableId
        lookupTableSource
        isGraphqlQueryable
        alternativeLookupTable
        externalDataSource {
          id
          dataKey
          dataQuery
          dataValue
          type
          source
          identifier
        }
        entityMemberValidities {
          defaultName
          descriptionTranslationToken {
            tokenFullName
          }
          nameTranslationToken {
            tokenFullName
          }
          regExJs
          validityType
        }
        defaultName
        id
        memberType
        nameTranslationTokenId
        nameTranslationToken {
          id
          tokenFullName
        }
        descriptionTranslationTokenId
        descriptionTranslationToken {
          id
          tokenFullName
        }
      }
    }

    logicalEntities(projectId: <projectId>) {
      id
      defaultName
      entityId
      editContainerLogicalControlId
      listContainerLogicalControlId
      nameTranslationToken {
        tokenFullName
      }
      descriptionTranslationToken {
        tokenFullName
      }
      templatesFilters {
        id
        defaultName
      }
    }

    campaigns(projectId: <projectId>) {
      defaultName
      sesConfigSetId
      nameTranslationToken {
        tokenFullName
      }
      id
      isActive      
    }

    applicationSettings(projectId: <projectId>) {
      id
      value      
      setting
      projectId
      lookupSettingType
    }
  }
  `;

  getSliderImages = `query {
    controlSlider(controlId: <0>, onlyActive: true) {
      id
      base64Img
      order
      isActive
      settings
    }
  }`;

  constructor() { this.initQueryDict(); }

  initQueryDict() {
    this.queryDict = new Dictionary<any>();
    this.queryDict.Add('createTaskCreateTask', this.createTaskCreateTask);
    this.queryDict.Add('qmQmTask', this.qmQmTask);
    this.queryDict.Add('optInOptOutOptInOptOuts', this.optInOptOutOptInOptOuts);
    this.queryDict.Add('makeCallIsContactAllowedDueOptOut', this.makeCallIsContactAllowedDueOptOut);
    this.queryDict.Add('appointmentViewUndoActivity', this.appointmentViewUndoActivity);
    this.queryDict.Add('leadReportsContacts', this.leadReportsContacts);
    this.queryDict.Add('leadReportsLevels', this.leadReportsLevels);
    this.queryDict.Add('leadReportsTemplateDefinitions', this.leadReportsTemplateDefinitions);
    this.queryDict.Add('externaldatasources', this.externaldatasources);
    this.queryDict.Add('reportingBaseReports', this.reportingBaseReports);
  }

  getQuery(queryIdentifier: string): string {
    let result = '';
    if (this.queryDict.ContainsKey(queryIdentifier)) {
      result = this.queryDict.Item(queryIdentifier);
    } else {
      console.error('graphQLQueryService getQuery: No query found for', queryIdentifier);
    }
    return result;
  }
}
