import { Injectable, EventEmitter } from '@angular/core';
import { SDK } from '@ringcentral/sdk';
import { environment } from 'environments/environment';
import WebPhone from '../ringcentral';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from './common.service';
import { ExternaldatasourceService } from './externaldatasource.service';
import { MessagePosition, MessageSeverity, MessagingService } from './messaging.service';
import { EventService } from './event.service';
import { Subscription, timer } from 'rxjs';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Injectable({
  providedIn: 'root'
})

export class RingcentralwebphoneService {
  public showRecordOptionsEvent: EventEmitter<any> = new EventEmitter<any>();
  public collapseExtensionEvent: EventEmitter<any> = new EventEmitter<any>();
  public expandExtensionEvent: EventEmitter<any> = new EventEmitter<any>();
  ringoutCheckSubscription: Subscription = new Subscription();

  // ! ivo
  public ringCentralWebPhoneEvent: EventEmitter<any> = new EventEmitter<any>();

  sdk = null;
  platform = null;
  username = null;
  extension = null;
  extensionNumber = '';
  logLevel: 0 | 1 | 2 | 3 = 0;
  webPhone = null;
  outboundCall = true;
  confSessionId = '';
  loginProcessRunning = false;
  session = null;
  transferSession = null;
  transferReady = false;
  callStartTime = null;

  // Ringout
  ringoutMaxSeconds = 30;
  ringoutCheckRingoutTime = false;
  ringoutTimer = 0;

  sessionState = {
    record: false,
    hold:false,
    muted:false,
    startTransfer:false,
    transferCallDisabled: true,
  };
  phoneSettings = null;
  maskedNumber = false;
  makeCallObject = null;
  incomingCall = false;
  recentCalls = false;
  phoneNumbers = [];
  primaryNumber = null;
  ringCentralBlackList = [];
  availabilityMode: 'inbound' | 'backoffice' | 'notavailable' | null = null;
  callQueues = [];
  userState = null;
  ownCallerNumber = '';
  activeCallInfo = {
    id: '',
    from: '',
    to: '',
    direction: '',
    sipData: {
      toTag: '',
      fromTag: '',
    },
  };
  established = false;
  projectBlacklist = [];

  // Recording
  recordingtypes = [];
  selectedRecordingTypeId = null;
  lastLoggedRecordingId = null;
  lastLoggedRecordingRowId = null;
  deviceInfo = null;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private messagingService: MessagingService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService
  ) {
  }

  initSoftphone() {
    const redirectUri = decodeURIComponent(this.applicationInfoService.applicationSettings['ringCentralWebRTCRedirect'].replace('<0>', window.location.origin));
    const clientId = this.commonService.decryptUsingAES256(this.applicationInfoService.applicationSettings['ringCentralWebRTCClientId']);
    const clientSecret = this.commonService.decryptUsingAES256(this.applicationInfoService.applicationSettings['ringCentralWebRTCClientSecret']);
    const server = this.applicationInfoService.applicationSettings['ringCentralWebRTCServer'];
    
    this.sdk = new SDK({
      clientId: clientId,
      clientSecret: clientSecret,
      server: server,
      redirectUri: redirectUri
    });
    this.availabilityMode = null;
    this.platform = this.sdk.platform();
    const lookuptableId = Number(this.applicationInfoService.applicationSettings['recordingLookupTableId']);
    this.recordingtypes = this.commonService.getProjectLookupTableValues(lookuptableId, 'data');
    console.log('webRTC initialized');
    if (this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['ringCentralWebRTCMaxRingoutSeconds'])) {
      this.ringoutMaxSeconds = 30;
    } else {
      this.ringoutMaxSeconds = Number(this.applicationInfoService.applicationSettings['ringCentralWebRTCMaxRingoutSeconds']);
    }
    this.ringoutCheck();
  }

  showExternalLoginForm() {
    const loginUrl = this.platform.loginUrl();
    this.platform
      .loginWindow({ url: loginUrl }) // this method also allows to supply more options to control window position
      .then(this.platform.login.bind(this.platform))
      .then(() => {
        return this.postLogin();
      })
      .catch((e) => {});
  }

  login(showLoginFormIfFailing = true) {
    this.loginProcessRunning = true;
    if (!this.platform) {
      return;
    }
    this.platform.login({ 'code':  localStorage.getItem("ringCentralWebRTCAuthCode") })
    .then(loginResult => {
      if (loginResult.status == 429) {
        this.messagingService.showDefaultError('RingCentral Login', 'RingCentral.Message.ToManyRequests')
      }
      this.postLogin();
    })
    .catch(() => {
      localStorage.removeItem('ringCentralWebRTCAuthCode');
      if (showLoginFormIfFailing) {
        this.loginProcessRunning = false;
        this.showExternalLoginForm();
      }
    });
  }

  logout() {
    this.webPhone.userAgent.unregister().then(() => {
      this.session = null;
      this.extension = null;
      this.ringCentralWebPhoneEvent.emit({id: 'collapseExtension'})
    });
  }

  postLogin() {
    this.extension = null;
    this.getUserStatus();
    this.getCallQueuePresence();
    this.getDeviceInfo();
    return this.platform
      .get('/restapi/v1.0/account/~/extension/~')
      .then((res) => res.json())
      .then((res) => {
        this.extension = res;

        //this.expandExtensionEvent.emit()
        if (this.applicationInfoService.isDeveloper) {
          console.log('Extension info', this.extension);
        }
        this.getBlackList();
        return this.platform.post('/restapi/v1.0/client-info/sip-provision', {
          sipInfo: [{ transport: 'WSS' }]
        });
      })
      .then((res) => res.json())
      .then(this.register.bind(this)) 
      .then(() => {

        this.getPhoneNumbers();
        this.getPresenceActiveCalls();
        this.loginProcessRunning = false;
        this.ringCentralWebPhoneEvent.emit({id: 'expandExtension'})
        if (!this.commonService.isNullOrUndefined(localStorage.getItem('activeCallInfo'))) {
          this.switchCall();
        }

        // Background bug -.-
        const footerElement = document.getElementById('everleadFooter');
        if (footerElement) {
          this.applicationInfoService.showApplicationFooter = false;
          setTimeout(() => {
            this.applicationInfoService.showApplicationFooter = true;
          }, 10);
        }
      })
      .catch((e) => {
        console.error('Error in main promise chain');
        console.error(e.stack || e);
      });
  }

  register(data) {
    const remoteVideoElement = document.getElementById('remoteVideo') as HTMLMediaElement;
    const localVideoElement = document.getElementById('localVideo') as HTMLMediaElement;
    const webPhone = new WebPhone(data, {
      enableDscp: true,
      clientId: localStorage.getItem('webPhoneclientId'),
      logLevel: 0,
      appName: 'EVERLEAD Webphone',
      appVersion: '1.0.0',
      audioHelper: {
        enabled: true,
        incoming: environment.serverUrl + 'assets/audio/incoming.ogg',
        outgoing: environment.serverUrl + 'assets/audio/outgoing_ag1.ogg',
      },
      media: {
        remote: remoteVideoElement,
        local: localVideoElement,
      },
      enableQos: true,
      enableMediaReportLogging: true,
    });

    console.log('webphone const', webPhone);
    webPhone.userAgent.audioHelper.setVolume(0.3);
    webPhone.userAgent.on('invite', this.onInvite.bind(this));
    webPhone.userAgent.on('connecting', () => { console.log('UA connecting'); });
    webPhone.userAgent.on('connected', () => { console.log('UA Connected'); });
    webPhone.userAgent.on('disconnected', () => { console.log('UA Disconnected'); });
    webPhone.userAgent.on('registered', () => {  });
    webPhone.userAgent.on('unregistered', () => {  });
    webPhone.userAgent.on('registrationFailed', function () { console.log('UA RegistrationFailed', arguments); });
    webPhone.userAgent.on('message', function () { console.log('UA Message', arguments); });
    webPhone.userAgent.transport.on('switchBackProxy', () => {
      console.log('switching back to primary outbound proxy');
      webPhone.userAgent.transport.reconnect();
    });
    webPhone.userAgent.transport.on('closed', () => { console.log('WebSocket closed.'); });
    webPhone.userAgent.transport.on('transportError', () => { console.log('WebSocket transportError occured'); });
    webPhone.userAgent.transport.on('wsConnectionError', () => { console.log('WebSocket wsConnectionError occured'); });
    this.webPhone = webPhone;
  }

  makeCall(number, homeCountryId = null) {
    this.ringCentralWebPhoneEvent.emit({ id: 'dashboardOutgoingCall' , number: number});

    if (this.commonService.isNullOrUndefined(number) || number == '') {
      this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Warning, '', 'RingCentralWebRTC.Message.NoNumberToCall', false);
      return;
    }
    if (this.extension == null) {
      this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Warning, '', 'RingCentralWebRTC.Message.NotLoggedIn', false);
      return;
    }
    if (this.session) {
      this
      this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Error, '', 'RingCentralWebRTC.Message.CallAlreadyActive', false);
      return;
    }
    this.outboundCall = true;
    this.transferReady = false;
    number = this.formatNumber(number);
    if (this.checkIfNumberIsBlocked(number)) {
      return;
    }

    // Number can be called
    this.checkProjectBlacklist(number).then(()=> {

      this.getOptinOpOuts().then(getOptinOpOutsResult => {
        if (getOptinOpOutsResult) {
          this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Error, '', 'RingCentralWebRTC.Message.NumberIsBlacklisted', false);
          return;
        }
        this.logCall(number);
        homeCountryId = this.extension?.regionalSettings?.homeCountry?.id || null;

        // RingOut
        if (this.applicationInfoService.userSettings['ringCentralUseHardphone'] == true) {
          this.platform.post('/restapi/v1.0/account/~/extension/~/ring-out', {
            from: { phoneNumber : this.ownCallerNumber.concat(' ') },
            to: { phoneNumber: number.concat(' ') },
            playPrompt: true,
            country: { id : homeCountryId }
          });
          this.ringCentralWebPhoneEvent.emit({id: 'onSession'})
        } else {
          this.session = this.webPhone.userAgent.invite(number, { fromNumber: this.ownCallerNumber, homeCountryId, });
          this.setSessionEvents(this.session);
          this.onAccepted(this.session);
          this.ringCentralWebPhoneEvent.emit({id: 'onSession'})
        }

        console.log('here')
        this.eventService.customEvent.emit({
          id: 'makeCall',
          number: number,
          ownNumber: this.ownCallerNumber
        });

        this.callStartTime = new Date(Date.now());
        this.ringoutCheckRingoutTime = true;
        this.ringoutTimer = 0;

      });
    }).catch(error => {
      console.warn(error);
    });
  }

  updateRecordingLog() {
    this.externaldatasourceService.executeExternalDataSource(787, [
      this.lastLoggedRecordingRowId,
      new Date(Date.now())
    ])
    .then(() => {
      this.lastLoggedRecordingRowId = null;
      console.log('updateRecordingLog', 'updated')
    });
  }

  ringoutCheck() {
    const ringoutCheckTimer = timer(1000, 1000);
    if (this.ringoutCheckSubscription) {
      this.ringoutCheckSubscription.unsubscribe();
    }
      this.ringoutCheckSubscription = ringoutCheckTimer.subscribe(() => {
        if (this.ringoutCheckRingoutTime && this.ringoutTimer > this.ringoutMaxSeconds) {
          if (this.session) {
            let hangUpText = this.axivasTranslateService.getTranslationTextForToken('RingCentralWebRTC.Message.HangUpAfterRingoutMax');
            hangUpText = hangUpText.replace('<0>', this.ringoutMaxSeconds.toString());
            this.messagingService.showDefaultInfo('', hangUpText);
            this.hangUp();
          }
        }
        this.ringoutTimer ++;
      });
  }

  logRecording(recordingId, startTime) {
    if (this.lastLoggedRecordingId == recordingId) {
      return;
    }
    let accountId = null;
    if (this.applicationInfoService.currentAccount) {
      accountId = this.applicationInfoService.currentAccount.id;
    }
    this.externaldatasourceService.executeExternalDataSource(786, [
      this.makeCallObject.id,
      recordingId,
      startTime,
      this.selectedRecordingTypeId,
      accountId
    ])
    .then(logRecordingResult => {
      this.lastLoggedRecordingId = recordingId;
      this.lastLoggedRecordingRowId = logRecordingResult.id;
    });
  }

  logCall(number, inbound = false): Promise<any> {
    return new Promise((logCallResolve, logCallReject) => {
      if (inbound) {
        console.warn('logCall inbound', this.session);
      }
      let accountId = null;
      if (this.applicationInfoService.currentAccount != null) {
        accountId = this.applicationInfoService.currentAccount.id;
      }

      let contactId = null;
      if (this.applicationInfoService.currentContact != null) {
        contactId = this.applicationInfoService.currentContact.id;
      }

      let campaignId = this.applicationInfoService.projectInfo.defaultCampaignId;
      if (this.applicationInfoService.currentTask != null) {
        campaignId = this.applicationInfoService.currentTask.campaignId;
      }
      if (this.applicationInfoService.campaingnModeId != null) {
        campaignId = this.applicationInfoService.campaingnModeId;
      }
      if (this.commonService.isNullOrUndefined(campaignId)) {
        campaignId = null;
      }


      let taskId = null;
      if (this.applicationInfoService.currentTask != null) {
        taskId = this.applicationInfoService.currentTask.id;
      }

      if (this.applicationInfoService.isDeveloper) {
        console.log('MakeCall', '1', number, this.maskedNumber,
          this.applicationInfoService.projectID, 1, [],
          accountId, contactId, taskId, campaignId
        );
      }

      if (this.commonService.isNullOrUndefined(campaignId)) {
        campaignId = null;
      }

      if (inbound) {
        accountId = null;
        contactId = null;
      }

      const call = new Object();
      call['extensionId'] = this.extension.id;
      call['projectId'] = this.applicationInfoService.projectID;
      call['accountId'] = accountId;
      call['entityId'] = 1;
      call['userId'] = this.applicationInfoService.userID;
      call['campaignId'] = campaignId;
      call['entityMemberId'] = 1;
      call['calledNumber'] = number;
      call['outgoingCallerNumber'] = this.ownCallerNumber;
      call['callStartedAt'] = new Date(Date.now());
      this.externaldatasourceService.executeExternalDataSource(462, [
        this.commonService.getModifyArrayBody(call, [])
      ])
      .then(logCallResult => {
        call['id'] = logCallResult.id;
        this.makeCallObject = call;
        logCallResolve(logCallResult);
      })
      .catch(error => { logCallReject(error); });
    });
  }

  updateCallLog(logObject) {
    this.externaldatasourceService.executeExternalDataSource(470, [
      this.commonService.getModifyArrayBody(logObject, [])
    ])
    .then(logCallResult => {
      if (this.applicationInfoService.isDeveloper) {
        console.log('updateCallLog done', logObject);
      }
      this.makeCallObject['id'] = logCallResult.id;
    });
  }

  onAccepted(session) {
    console.log('EVENT: Accepted', session);
    this.ringCentralWebPhoneEvent.emit({id: 'callAccepted'})
  }

  setSessionEvents(session) {
    this.established = false;

    session.on('terminated', () => {
      this.session = null;

      this.sessionState.record = false;
      this.sessionState.startTransfer = false;
      this.sessionState.transferCallDisabled = true;


      this.incomingCall = false;
      this.established = false;

      this.transferReady = false

      localStorage.removeItem('activeCallInfo');
      if (this.lastLoggedRecordingRowId != null) {
        this.updateRecordingLog();
      }
      this.callStartTime = null;
      this.ringoutCheckRingoutTime = false;

      console.log('Event: Call Terminated');
      this.showToastInfo(null, 'Event: Call terminated');
      this.ringCentralWebPhoneEvent.emit({id : 'callTerminated'})

    });
    session.on('established', () => {
      console.log('Event: Established session', session);

      this.established = true;
      this.incomingCall = false;
      this.callStartTime = new Date(Date.now());
      this.ringoutCheckRingoutTime = false;
      this.captureActiveCallInfo(session);
      this.getPresenceActiveCalls(true);

      //! ivo commented this
      //this.session = null;

      this.sessionState.record = false;
      // ivo
      this.sessionState.startTransfer = false;
      this.sessionState.transferCallDisabled = false;
      this.showToastInfo(null, 'Event: Connection established');
      this.transferReady = false
    });
  }

  onInvite(session) {
    if (this.session != null) {
      console.log('Inbound call send to voicemail');
      this.ringCentralWebPhoneEvent.emit({id : 'sentToInvoice'})
      this.sessionState.transferCallDisabled = true;
      this.messagingService.showDefaultInfo('Inbound', 'RingCentral.Message.InboundToVoicemail');
      session.toVoicemail();
      return;
    }

    this.outboundCall = false;
    console.log('EVENT: Invite');
    this.incomingCall = true;
    this.session = session;
    this.getPresenceActiveCalls().then(getPresenceActiveCallsResult => {
      this.platform.get('/restapi/v1.0/account/~/telephony/sessions/'.concat(getPresenceActiveCallsResult.activeCalls[0].telephonySessionId))
      .then((res) => res.json())
      .then(telephonySessionInfo => {
        this.setSessionEvents(this.session);
        try {
          const number = telephonySessionInfo.parties[0].from.phoneNumber;
          this.eventService.openIncomingCallEvent.emit(number);
          this.ringCentralWebPhoneEvent.emit({
            id: 'incomingCall',
            incomingNumber: number
          });
        }
        catch {
          console.log('openIncomingCall failed')
        }
      })
    });
  }

  volumeUp() {
    console.warn('volume:', this.session.userAgent.audioHelper.volume);
    this.session.userAgent.audioHelper.setVolume(
      (this.session.userAgent.audioHelper.volume !== null ? this.session.userAgent.audioHelper.volume : 0.5) + 0.1,
    );

  }

  volumeDown() {
    this.session.userAgent.audioHelper.setVolume(
      (this.session.userAgent.audioHelper.volume !== null ? this.session.userAgent.audioHelper.volume : 0.5) - 0.1,
    );
    console.warn('volume:', this.session.userAgent.audioHelper.volume);
  }

  hangUp() {
    if (this.session) {
      this.session.dispose().then(() => {
        this.session = null;
      });
    }
    this.ringCentralWebPhoneEvent.emit({id: 'hangUp'});
    this.showToastInfo(null, 'Event: Hangup by user');
    console.log('hangup')
  }

  toggleHold() {

    console.log('this held', this.session.held)

    if (this.session.held) {
      this.unhold();
    } else {
      this.hold();
    }
  }

  hold() {
    if (this.session) {
      this.session.hold();
    }
  }

  unhold() {
    if (this.session) {
      this.session.unhold();
    }
  }

  reject() {
    if (this.session) {
      this.session.toVoicemail();
      //this.session.reject()
      this.ringCentralWebPhoneEvent.emit({id:'callRejected'})
    }
  }

  toggleRecord() {
    if (this.sessionState.record) {
      this.stopRecord();
    } else {
      this.startRecord();
    }
  }

  getDeviceInfo() {
    this.platform.get('/restapi/v1.0/account/~/extension/~/device').then((res) => res.json())
    .then(deviceInfo => {
      this.deviceInfo = deviceInfo;
    });
  }

  startRecord() {
    if (this.session) {
      let startTime = new Date(Date.now());
      this.session.startRecord().then(() => {
        this.getPresenceActiveCalls().then(getPresenceActiveCallsResult => {
          this.platform.get('/restapi/v1.0/account/~/telephony/sessions/'.concat(getPresenceActiveCallsResult.activeCalls[0].telephonySessionId))
          .then((res) => res.json())
          .then(telephonySessionInfo => {
            // console.warn('startRecord', telephonySessionInfo, telephonySessionInfo.parties);
            if (telephonySessionInfo.parties[0].recordings) {
              this.logRecording(telephonySessionInfo.parties[0].recordings[0].id, startTime);
            } else {
              if (telephonySessionInfo.parties[telephonySessionInfo.parties.length - 1].recordings) {
                this.logRecording(telephonySessionInfo.parties[telephonySessionInfo.parties.length - 1].recordings[0].id, startTime);
              } else {
                console.warn(telephonySessionInfo, telephonySessionInfo.parties)
                this.messagingService.showDefaultInfo('', 'RingCentralWebRTC.Message.NoPartyFound')
              }
            }
          });
        });
        this.sessionState.record = true;
        console.log('Recording started');
      });
    }
  }

  stopRecord() {
    if (this.session) {
      console.log('Stop recording');
      this.session.stopRecord().then(() => {
        console.log('Recording stopped');
        this.sessionState.record = false;
        this.updateRecordingLog();
        this.lastLoggedRecordingId = null;
      });
    }
  }

  toggleMute() {
    if (this.session.muted) {
      this.unmute();
    } else {
      this.mute();
    }
  }

  mute() {
    if (this.session) {
      this.session.mute();
      this.sessionState.muted = true;
    }
  }

  unmute() {
    if (this.session) {
      this.session.unmute();
      this.sessionState.muted = false;
    }
  }

  accept() {
    this.logCall(this.getFriendlyName('from'), true)
    .then(() => {
      this.session.accept();
      this.showToastInfo(null, 'Accepted');
      this.getPresenceActiveCalls(true);
    });
    this.incomingCall = false;
  }

  sendDTMF(key) {
    this.session.dtmf(key);
  }

  getPresenceActiveCalls(updateLog = false): Promise<any> {
    return new Promise((getPresenceActiveCallsResolve, getPresenceActiveCallsReject) => {
      this.platform.get('/restapi/v1.0/account/~/extension/~/presence?detailedTelephonyState=true').then((res) => res.json())
      .then((response) => {
        if (updateLog) {
          this.makeCallObject['telephonySessionId'] = response.activeCalls[0].telephonySessionId;
          if (response.activeCalls[0]) {
            this.updateCallLog(this.makeCallObject);
            // if(response.activeCalls[0].direction == 'Outbound') {
            //   this.updateCallLog(this.makeCallObject);
            // }
          }
        }
        getPresenceActiveCallsResolve(response);
      });
    });
  }

  getCallQueuePresence() {
    this.platform.get('/restapi/v1.0/account/~/extension/~/call-queue-presence').then((res) => res.json())
    .then((response) => {
      console.log(response);
      this.callQueues = response.records;
    });

  }

  updateUserQueueState(queue) {
    this.platform.put('/restapi/v1.0/account/~/extension/~/call-queue-presence', {
      records: this.callQueues
    }).then((res) => res.json())
    .then(() => {
      this.getCallQueuePresence();
    });
  }

  optOutExpired(optOut) {
    let returnValue = false;
    if (!this.commonService.isNullOrUndefined(optOut)) {
      if (!this.commonService.isNullOrUndefined(optOut.endDate)) {
        const endDate = new Date(optOut.endDate);
        const now = new Date();
        if (endDate < now) {
          returnValue = true;
        }
      }
    }
    return returnValue;
  }

  getOptinOpOuts(): Promise<any> {
    return new Promise((getOptinOpOutsResolve, getOptinOpOutsReject) => {
      this.externaldatasourceService.executeExternalDataSource(886).then(result => {
        getOptinOpOutsResolve(!result);
      });
    })
  }

  getFriendlyName(source) {
    let returnValue = '';
    if (this.session.request[source]) {
      let friendlyName = '';
      if (source == 'from') {
        friendlyName = this.session.request[source].uri.raw.user;
      } else {
        friendlyName = this.session.request[source].friendlyName;
      }

      if (!this.commonService.isNullOrUndefined(friendlyName)) {
        const friendlyNameDetails = friendlyName.split('@');
        if (friendlyNameDetails.length > 0) {
          returnValue = friendlyNameDetails[0];
        } else {
          returnValue = friendlyName;
        }
      }
    }
    return returnValue;
  }

  checkIfNumberIsBlocked(number) {
    // Blocked numbers from ringCentral
    let isBlocked = this.ringCentralBlackList.find(blacklistEntry => blacklistEntry.phoneNumber == number);
    if (isBlocked) {
      return true;
    }
    return false;
  }

  checkProjectBlacklist(number): Promise<any> {
    return new Promise((getProjectBlacklistResolve: (value?: any) => void, getProjectBlacklistReject) => {
      this.externaldatasourceService.executeExternalDataSource(894, [])
      .then(getProjectBlacklistResult => {
        const array = [];
        getProjectBlacklistResult.forEach(result => {
          if (result.blacklistTypeLookupId == 5716) {
            array.push(result.value);
          }
        });
        if (this.commonService.isPhoneNumberInNumberArray(array, number)) {
          getProjectBlacklistReject('blacklisted');
          this.messagingService.showDefaultError(number, 'RingCentralWebRTC.Message.NumberIsBlacklisted')
        } else {
          getProjectBlacklistResolve();
        }
      }).catch(() => {
        getProjectBlacklistReject(null);
      });
    });
  }

  getRecentCalls(): Promise<any> {
    return new Promise((getRecentCallsResolve, getRecentCallsReject) => {
      this.platform.get('/restapi/v1.0/account/~/extension/~/call-log', {
        dateFrom: '2022-12-01T00:00:00.534Z'
      }).then((res) => res.json())
      .then((getRecentCallsResponse) => {
        this.recentCalls = getRecentCallsResponse;
        getRecentCallsResolve(getRecentCallsResponse);
      })
      .catch(() => { getRecentCallsReject(null); });
    });
  }

  getAdressBook(): Promise<any> {
    return new Promise((getAdressBookResolve, getAdressBookReject) => {
      this.platform.get('/restapi/v1.0/account/~/directory/entries', {
        type: 'User',
        page: 1,
        perPage: 1000
      }).then((res) => res.json())
      .then((response) => {
        getAdressBookResolve(response);
      })
      .catch(() => { getAdressBookReject(null); });
    });
  }

  getPhoneNumbers() {
    this.platform.get('/restapi/v1.0/account/~/extension/~/phone-number').then((res) => res.json())
    .then((response) => {
      this.phoneNumbers = response.records;
      this.primaryNumber = this.phoneNumbers.find(number => number.primary == true);
      this.getOwnOutgoingCallNumber();
      if (this.applicationInfoService.isDeveloper) {
        console.warn('primaryNumber', this.primaryNumber);
      }
    });
  }

  getBlackList() {
    this.platform.get('/restapi/v1.0/account/~/extension/~/caller-blocking/phone-numbers').then((res) => res.json())
    .then((response) => {
      this.ringCentralBlackList = response.records.filter(record => record.status == 'Blocked');
    });
  }

  getUserStatus() {
    this.platform.get('/restapi/v1.0/account/~/extension/~/presence').then((res) => res.json())
    .then((response) => {
      this.userState = response;
      this.getCurrentUserActivity();
    });
  }

  getCurrentUserActivity() {
    this.availabilityMode = null;
    if (this.userState.dndStatus == 'TakeAllCalls' && this.userState.userStatus == 'Available') {
      this.availabilityMode = 'inbound';
    }
    if (this.userState.dndStatus == 'DoNotAcceptDepartmentCalls' && this.userState.userStatus == 'Available') {
      this.availabilityMode = 'backoffice';
    }
    if (this.userState.dndStatus == 'DoNotAcceptAnyCalls' && this.userState.userStatus == 'Offline') {
      this.availabilityMode = 'notavailable';
    }
    console.warn(this.userState.dndStatus, this.userState.userStatus, this.availabilityMode);
  }

  changeAvailabilityMode() {
    if (this.availabilityMode == 'inbound') { this.updateUserState('Available', 'TakeAllCalls'); }
    if (this.availabilityMode == 'backoffice') { this.updateUserState('Available', 'DoNotAcceptDepartmentCalls'); }
    if (this.availabilityMode == 'notavailable') { this.updateUserState('Offline', 'DoNotAcceptAnyCalls'); }
  }

  updateUserState(userStatus, dndStatus) {
    this.userState.userStatus = userStatus;
    this.userState.dndStatus = dndStatus;
    this.platform.put('/restapi/v1.0/account/~/extension/~/presence', {
      userStatus: this.userState.userStatus,
      dndStatus: this.userState.dndStatus,
    }).then((res) => res.json())
    .then((updateUserStateResult) => {
      console.log(updateUserStateResult);
      this.getCurrentUserActivity();
    });
  }

  // Number formating & co
  getOwnOutgoingCallNumber() {
    // console.log('getOwnOutgoingCallNumber',
    //   this.primaryNumber,
    //   this.primaryNumber.phoneNumber,
    //   this.applicationInfoService.projectInfo.agentMaskingType,
    //   this.applicationInfoService.projectInfo.agentMaskingSpecialNumber,
    //   this.applicationInfoService.userSettings['eonNumber']
    // );
    let ringCentralOwnNumberSetting = 'ringCentralOwnNumber'.concat(this.applicationInfoService.projectID.toString());
    let number = this.primaryNumber.phoneNumber;
    if (this.applicationInfoService.projectInfo.agentMaskingType == 2) {
      number = this.applicationInfoService.projectInfo.agentMaskingSpecialNumber;
      console.warn('getOwnOutgoingCallNumber', 'Masking found');
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.userSettings['eonNumber']) && this.applicationInfoService.projectID == 315) {
      number = this.applicationInfoService.userSettings['eonNumber'];
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.userSettings[ringCentralOwnNumberSetting])) {
      this.ownCallerNumber = this.applicationInfoService.userSettings[ringCentralOwnNumberSetting];
      console.log('projectSpecificNumber found', this.ownCallerNumber);      
      return this.ownCallerNumber;
    }    
    this.ownCallerNumber = this.formatNumber(number);
    console.warn('getOwnOutgoingCallNumber', 'number', number, this.ownCallerNumber);
    return number;
  }

  formatNumber(number) {
    if (!number) {
      return null;
    }
    if (number.length < 5) {
      // Extension
      return this.normalizeNumber(number);
    } else {
      number = this.normalizeNumber(number);
      number = this.checkNumberformat(number);
      return number;
    }
  }

  checkNumberformat(number: string) {
    if (number.startsWith('004')) {
      number = '+4'.concat(number.substring(3));
    }
    if (!number.startsWith('+')) {
      if (!number.startsWith("0")) {
        number = '0'.concat(number);
      }
      number = '+49'.concat(number.substring(1));
    }
    return number;
  }

  normalizeNumber(number) {
    if (this.applicationInfoService.isDeveloper) {
      console.warn('normalizeNumber', 'number', number)
    }
    const characterWhitelist = "+01234567890";
    let finalNumber = "";
    for (let i in number) {
      const numberPart = number[i];
      if (this.commonService.checkIfStringContainsString(characterWhitelist, numberPart, true)) {
        finalNumber = finalNumber.concat(numberPart)
      }
    }
    return finalNumber;
  }

  // Active call handling
  captureActiveCallInfo(session) {
    this.activeCallInfo = {
      id: '',
      from: '',
      to: '',
      direction: '',
      sipData: {
        toTag: '',
        fromTag: '',
      },
    };
    const direction = this.outboundCall ? 'Outbound' : 'Inbound';
    this.activeCallInfo.from = session.request.from.uri.user;
    this.activeCallInfo.to = session.request.to.uri.user;
    this.activeCallInfo.direction = direction;
    this.activeCallInfo.id = session.dialog.id.callId;
    this.activeCallInfo.sipData.fromTag = session.dialog.remoteTag;
    this.activeCallInfo.sipData.toTag = session.dialog.localTag;
    localStorage.setItem('activeCallInfo', JSON.stringify(this.activeCallInfo));
  }

  switchCall() {
    const activeCallItem = JSON.parse(localStorage.getItem('activeCallInfo'));
    console.log('Switching active call to current tab: ', activeCallItem);
    this.session = this.webPhone.userAgent.switchFrom(activeCallItem);
    console.log('switch -> session', this.session)
    this.setSessionEvents(this.session);
    this.onAccepted(this.session);
  }

  transferCall(transferNumber) {
    this.session.hold().then(() => {
      console.log('Placing the call on hold, initiating attended transfer');
      this.transferSession = this.session.userAgent.invite(transferNumber);
      console.log('this transfer session', this.transferSession);

      this.transferSession.once('established', () => {
        this.transferReady = true;
        this.ringCentralWebPhoneEvent.emit({id:"transferCallEstablished"})
      });

      this.transferSession.once('terminated', ()=>{
        console.log(' transferCall its - terminated')
        // this.session.unhold();
        this.sessionState.startTransfer = false;
        this.sessionState.transferCallDisabled= false;
        this.transferReady = false;
        this.ringCentralWebPhoneEvent.emit({id:"transferCallTerminated"})
        this.transferSession= null;
      });

    });

  }

  startTransferProcess(){
    if(this.session){
      this.sessionState.startTransfer = true;
      this.ringCentralWebPhoneEvent.emit({ id: 'startTransferCall'})
    }
  }

  stopTransferProcess(){
    if(this.transferSession){
      this.transferSession.dispose().then(()=>{
        console.log('stop the transfer Process')
        //this.session.unhold();
        this.sessionState.startTransfer = false;
        this.sessionState.transferCallDisabled= false;
        this.transferReady = false;
        this.ringCentralWebPhoneEvent.emit({id:"transferCallTerminated"})
        this.transferSession= null;
       })
    }
  }

  warmTransfer() {
    this.session.warmTransfer(this.transferSession)
    .then(() => {
      console.log('Warm transfer completed');
    })
    .catch(error => {
      console.error('Transfer failed', error.stack || error);
    });
  }

  getTimeDifferenceString() {
    if (this.callStartTime != null) {
      return this.commonService.getTimeDifferenceShortString(new Date(Date.now()), this.callStartTime);
    } else {
      return '';
    }
  }

  showToastInfo(event, text) {
    let message = text;
    if (this.applicationInfoService.applicationSettings['showingCentralToast']) {
      this.messagingService.showDefaultInfo('RingCentral', message);
    }    
  }
}
