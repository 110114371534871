
<div class="dashboardList">
    <div class="dashboardList__header">
        <div class="dashboardList__header__description noborder"*ngIf="component !== 'activity'">{{ 'Report.ProjectOverview.Leadstatus' | texttransform }} <span *ngIf="leadstatus">{{ leadstatus }}</span></div>
        <div class="dashboardList__header__descriptionActivity noborder" *ngIf=" component === 'activity'">{{ 'Report.ProjectOverview.Step' | texttransform }} <span *ngIf="leadstatus">{{ leadstatus }}</span></div>
        <div class="dashboardList__header__value noborder">{{'Report.ProjectOverview.Anzahl' | texttransform}} </div>
        <div class="dashboardList__header__value noborder" *ngIf="component === 'activity' && controlid === 'nestedArray'">% </div>
        <div class="dashboardList__row__arrowspace"></div>
    </div>

<div *ngIf="!controlid"> 
        <app-accordion [title]="data[0].step" [isOpen]="true">
            <div *ngFor="let row of data let index = i" class="dashboardList__row">
                <div class="dashboardList__row__inner">
                    <div class="dashboardList__row__description"  *ngIf="row?.result" [tippy]="checkStringLength(row.result)">{{ row?.result }}</div>
                    <div class="dashboardList__row__description"  *ngIf="row?.answer" [tippy]="checkStringLength(row.answer)">{{ row?.answer }}</div>
                    <div class="dashboardList__row__value" *ngIf="row.anzahl">{{row?.anzahl}}</div>
                    <div class="dashboardList__row__arrowspace"></div> 
                </div>
            </div>
            <div class="dashboardList__total">
                <div class="dashboardList__total__inner">
                    <div class="dashboardList__row__total bold noborder">{{'Report.ProjectOverview.Summe' | texttransform }}</div>
                    <div class=" dashboardList__row__singleArrayleadValue bold">{{ createSum(data, 'anzahl')}}</div>
                </div>
            </div>
        </app-accordion>
    </div> 
    <div *ngIf="controlid === 'nestedArray'">
        <app-accordion *ngFor="let array of data" [title]="array[0]?.step" [value]="createSumNested(array, 'anzahl')" [valueTwo]="component === 'activity' ? findPercentageArray(array) : undefined">
            <div *ngFor="let row of array let index = i" class="dashboardList__row">
                <div class="dashboardList__row__inner">
                    <div class="dashboardList__row__description" *ngIf="row?.answer" [tippy]="checkStringLength(row.answer)">{{ row?.answer }}</div>
                    <div class="dashboardList__row__description" *ngIf="row?.result" [tippy]="checkStringLength(row.result)" >{{ row?.result }}</div>
                    <div class="dashboardList__row__value" *ngIf="row?.anzahl">{{ row?.anzahl }}</div>
                    <div class="dashboardList__row__value" *ngIf="component === 'activity'">---</div>
                    <div class="dashboardList__row__arrowspace"></div>
                </div>
            </div>
        </app-accordion>

        <div class="dashboardList__total">
            <div class="dashboardList__total__inner contactRow">
                <div class="dashboardList__row__total bold noborder"> <span *ngIf="leadstatus">{{ leadstatus }}</span> {{'Report.ProjectOverview.Summe' | texttransform }}</div>
                <div class=" dashboardList__row__multiArrayleadValue bold">{{ createSumNested(data, 'anzahl')}}</div>
                <div class="dashboardList__row__multiArrayleadValue bold" *ngIf="component === 'activity'">{{ resultPercentage }} % </div>
                <div class="dashboardList__row__arrowspace"></div>
            </div>
        </div>
    </div>
</div>
