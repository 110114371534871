
<div *ngIf="applicationInfoService.awsSilentRenewError" class="awsErrorBanner everleadMagentaBackgroundColor">
    <div class="bannerInner">
        <div class="row maxWidth">
            <div class="col-md-10">
                <label class="bannerLabel bannerHeaderLabel">{{ 'AWSRefreshError.Label.Header' | texttransform }}</label>
            </div>
            <div class="col-md-2">
                <i class="fas fa-times bannerClose fingerCursor" (click)="closeBanner('awsSilentRenewError')"></i>
            </div>
            <div class="col-md-10">
                <!-- <label class="bannerLabel bannerHeaderLabel">Erleben Sie EVERLEAD in Aktion:</label> -->
                <label class="bannerLabel">{{ 'AWSRefreshError.Label.LoginMessage' | texttransform }}</label>
            </div>
            <div class="col-md-2 bannerInner__navigation">
                <button class="bannerInner__button" (click)="login()">{{ 'AWSRefreshError.Label.Login' | texttransform }}</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showVipNotActivated" class="showVipNotActivatedBanner everleadMagentaBackgroundColor">
    <div class="showVipNotActivatedBanner__inner">
        <div class="showVipNotActivated__inner__header">
            <div>
                {{ 'Supervisor.Label.ManageVip' | texttransform }}
            </div>
            <div class="everleadDemoBanner__item__close">
                <i class="fas fa-times bannerClose fa-2x fingerCursor" (click)="showVipNotActivated = false"></i>
            </div>
        </div>
        <div class="showVipNotActivatedBanner__inner__description">
            {{ 'VipNotActivatedBanner.Label.Description' | texttransform }}
        </div>
        <div class="showVipNotActivatedBanner__inner__select">
            <div class="showVipNotActivatedBanner__inner__select__label">
                {{ 'UserSettings.Label.VipAlertFrequency' | texttransform }}
            </div>
            <div class="showVipNotActivatedBanner__inner__select__interaction" *ngIf="settingsService.userSettings['VipAlertFrequency']">
                <select [(ngModel)]="settingsService.userSettings['VipAlertFrequency'].value"
                    (change)="settingsService.setUserSettingEx(settingsService.userSettings['VipAlertFrequency'])">
                    <option value="none">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.None' | texttransform }}</option>
                    <option value="direct">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Direct' | texttransform }}</option>
                    <option value="daily">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Daily' | texttransform }}</option>
                    <option value="weekly">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Weekly' | texttransform }}</option>
                </select>
            </div>
        </div>
    </div>
</div>
