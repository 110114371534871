<ng-container *ngIf="loadingData == true">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
</ng-container>


<div *ngIf="loadingData == false">
    <div class="compactTaskListHeader">
        <div class="maxWidth compactTaskListRow"
            *ngIf="controlDefinition.displayText != null && controlDefinition.displayText != ''">
            <div class="">
                <label class="compactTaskListHeader__label">{{ controlDefinition.displayText | texttransform}}</label>
            </div>
        </div>
        <div class="maxWidth compactTaskListRow__header">
            <div class="compactTaskListRow__tabs">
                <app-tab label="CompactTaskList.Label.Account" svg="list-bullets" [active]="listMode === 1" (onClick)="changeListMode(1)"></app-tab>
                <app-tab *ngIf="contactAvailable()" label="CompactTaskList.Label.Contact" svg="list-bullets" [active]="listMode === 2" (onClick)="changeListMode(2)"></app-tab>
            </div>
        </div>
        <div class="compactTaskListRow__buttons">
            <div class="compactTaskListRow__tabs" *ngIf="applicationInfoService?.applicationSettings['showCustomTaskListNewButtons']=='true'">
                <app-main-button *ngIf="checkQuickTaskId() == true" type="borderSmall" svg="plus" 
                    label="Tasklist.Label.CreateQuickTask" (onClick)="addNew()"></app-main-button>
                <app-main-button type="borderSmall" svg="plus" 
                    label="Tasklist.Label.CreateCustomTask" (onClick)="createCustomTask()"></app-main-button>
            </div>            
        </div>
    </div>

    <span *ngIf="controlView == 1" id="compactTaskLiskSmallItemDesign"></span>
    <div *ngIf="controlView == 1" class="compactListSmallComponent" [style.max-height]="getControlHeight('compactTaskLiskSmallItemDesign', 20)">        
        <div *ngFor="let dataItem of data" class="everleadTableRow" [ngClass]="{ 
                hideInDom: resultCount(dataItem.stepId) == 0 || isValidStep(dataItem.stepId) == false,
                'compactTaskListItem__highlight': dataItem.id == applicationInfoService.lastLogicalItemFromMyFollowups 
            }">
            <ng-container *ngIf="resultCount(dataItem.stepId) > 0 && isValidStep(dataItem.stepId)">
                <section class="row compactListSmallComponent__item">
                    <div class="col-12">
                        <div class="compactListSmallComponent__header">
                            <app-jj-icons type="clock" [width]="30" [height]="30"></app-jj-icons>
                            <h2 class="ellipsisText" [tippy]="getStepname(dataItem.stepId) | texttransform">{{ getStepname(dataItem.stepId) | texttransform }}</h2>
                        </div>
                    </div>
                    <div [ngClass]=" !taskIsInfoOnly(dataItem) ? 'col-6' : 'col-12'">
                        <div class="maxWidth">
                            <app-date-input [label]="getStepname(dataItem.stepId) | texttransform"
                                [(ngModel)]="dataItem.followUpDate" (dateChange)="changeRow(dataItem)"></app-date-input>
                        </div>
                    </div>

                    <div *ngIf="!taskIsInfoOnly(dataItem)" class="col-6">
                        <div class="compactListSmallComponent__itemCheckBox">
                            <app-jj-icons type="pencil-simple-line" *ngIf="dataItem.stepLookupTypeId != 4190" [width]="20" [height]="20"
                                (click)="editTask(dataItem)"></app-jj-icons>
                            <div *ngIf="dataItem.stepLookupTypeId == 4190">
                                <app-checkbox label="CompactTaskList.Label.Done" labelLeft="true"
                                    (change)="bookSingleResult(dataItem)"></app-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="maxWidth">
                            <app-select-input label="CompactTaskList.Label.User"
                                [(ngModel)]="dataItem.followUpUserId" (valueChange)="changeRow(dataItem)"
                                [options]="projectUsers" bind="id" display="userName"></app-select-input>                           
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="maxWidth">
                            <app-select-input label="CompactTaskList.Label.TaskContact"
                                [(ngModel)]="dataItem.contactId" (valueChange)="changeRow(dataItem)" bind="id"
                                [options]="contacts" displayMultiValue="firstName;lastName"></app-select-input>
    
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="maxWidth">
                            <app-text-input label="CompactTaskList.Label.Subject"
                                [(ngModel)]="dataItem.subject" (change)="changeRow(dataItem)"></app-text-input>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="maxWidth">
                            <app-text-area-input [(ngModel)]="dataItem.notes"
                                label="CompactTaskList.Label.Notes"
                                (valueChange)="changeRow(dataItem)"></app-text-area-input>
                        </div>
                    </div>
                </section>
            </ng-container>
        </div>
    </div>

    






<!--     todo still todo  -->
    <span *ngIf="controlView == 2" id="compactTaskLiskBigItemDesign"></span>
    <div *ngIf="controlView == 2" class="compactTaskListItem__wrapperlarge" 
        [style.height]="getControlHeight('compactTaskLiskBigItemDesign')">
 
        <div *ngFor="let dataItem of data" class="compactTaskLiskBigItem">
            <div class="compactTaskLiskBigItemTopRow">
                <app-component-header class="maxWidth" [header]="getStepname(dataItem.stepId) | texttransform"></app-component-header>
                <div class="compactTaskListItemRow compactTaskListItemDate">
                    <app-jj-icons type="clock" [width]="20" [height]="20"></app-jj-icons>
                    <label>{{ 'CompactTaskList.Label.FollowUpDate' | axivastranslate }}</label>
                    <app-date-input [(ngModel)]="dataItem.followUpDate" (dateChange)="changeRow(dataItem)"></app-date-input>
                </div>
                <div *ngIf="!taskIsInfoOnly(dataItem) || applicationInfoService.isDeveloper" class="compactTaskLiskBigItemTopRow__buttons">
                    <app-jj-icons class="fingerCursor" [type]="'play'" [tippy]="'CompactTaskList.Label.LoadTask' | texttransform"
                        *ngIf="applicationInfoService.projectInfo.isUsingWorkflow || applicationInfoService.isDeveloper" 
                        [width]="20" [height]="20" (click)="loadTask(dataItem)" [pointer]="true"></app-jj-icons>
                    <app-jj-icons class="fingerCursor" [type]="'trash'" [tippy]="'CompactTaskList.Label.Delete' | texttransform" 
                        *ngIf="userService.hasPermission(deleteTaskUserRightName)"
                        [width]="20" [height]="20" (click)="deleteTask(dataItem)" [pointer]="true"></app-jj-icons>
                </div>
            </div>            

            <div class="compactTaskLiskBigItemInfo" *ngIf="taskIsInfoOnly(dataItem)">
                {{ 'CompactTaskList.Label.InfoOnly' | texttransform }}              
            </div> 

            <div class="compactTaskLiskBigItemContent">
            <div class="maxWidth compactTaskListRow">                
                <div class="compactTaskListRowContent">
                    <div class="compactTaskListItemRow" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')">
                        <app-jj-icons type="info" [width]="20" [height]="20"></app-jj-icons>
                        <label>{{ 'CompactTaskList.Label.Campaign' | axivastranslate }}</label>
                        <app-select-input
                        [(ngModel)]="dataItem.campaignId"
                        [disabled]="true"
                        (valueChange)="changeRow(dataItem)"
                        [options]="campaigns"
                        bind="id"
                        display="defaultName"></app-select-input>       
                    </div>

                    <div class="compactTaskListItemRow" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')">
                        <app-jj-icons type="info" [width]="20" [height]="20"></app-jj-icons>
                        <label>{{ 'CompactTaskList.Label.Workflow' | axivastranslate }}</label>
                        <app-select-input
                            [(ngModel)]="dataItem.stepId"
                            (valueChange)="changeRow(dateItem)"
                            [disabled]="true"
                            [options]="workflows"
                            bind="id"
                            display="workflowName"
                            ></app-select-input>
                     </div>

                    <div class="compactTaskListItemRow">
                        <app-jj-icons type="user" [width]="20" [height]="20"></app-jj-icons>
                        <label>{{ 'CompactTaskList.Label.User' | axivastranslate }}</label>
                        <app-select-input
                            [(ngModel)]="dataItem.followUpUserId"
                            (valueChange)="changeRow(dataItem)"
                            [options]="projectUsers"
                            bind="id"
                            display="userName"
                            ></app-select-input>
                    </div>

                    <div class="compactTaskListItemRow" *ngIf="!taskIsInfoOnly(dataItem)">
                        <app-jj-icons type="user" [width]="20" [height]="20"></app-jj-icons>
                        <label>{{ 'CompactTaskList.Label.TaskContact' | axivastranslate }}</label>
                        <app-select-input [(ngModel)]="dataItem.contactId" (valueChange)="changeRow(dataItem)" bind="id"
                            [options]="contacts" displayMultiValue="firstName;lastName"></app-select-input>
                    </div>
                    <div class="compactTaskListItemRow" *ngIf="!taskIsInfoOnly(dataItem)">
                        <app-jj-icons type="calendar-check" [width]="20" [height]="20"></app-jj-icons>
                        <label>{{ 'CompactTaskList.Label.PersonalResubmission' | axivastranslate }}</label>
                        <app-checkbox [(ngModel)]="dataItem.isFollowUpAppointment" (valueChange)="changeRow(dataItem)"></app-checkbox>
                    </div>
                    <div class="compactTaskListItemRow" *ngIf="!taskIsInfoOnly(dataItem)">
                        <app-jj-icons type="calendar-check" [width]="20" [height]="20"></app-jj-icons>
                        <label>{{ 'CompactTaskList.Label.Subject' | axivastranslate }}</label>
                        <app-text-input [(ngModel)]="dataItem.subject" (valueChange)="changeRow(dataItem)" ></app-text-input>
                    </div>
                    <div class="compactTaskListItemRow" *ngIf="!taskIsInfoOnly(dataItem)">
                        <app-jj-icons type="note" [width]="20" [height]="20"></app-jj-icons>
                        <label>{{ 'CompactTaskList.Label.Notes' | axivastranslate }}</label>
                        <app-text-input [(ngModel)]="dataItem.notes" (valueChange)="changeRow(dataItem)" ></app-text-input>
                    </div>
                           
                </div>
                                             
            </div>    
            <div class="compactTaskListRow compactTaskListRow__dynamicItem__wrapper">
                <label class="compactTaskListRow__dynamicItem__headerLabel" *ngIf="additionalItemsFound">{{ 'CompactTaskList.Label.AdditionalItemList' |
                    axivastranslate }}</label>
                <div *ngFor="let child of controlDefinition.childs" class="maxWidth">                   
                    <div *ngIf="child.controlType=='20' && child.isActive" class="compactTaskListRow__dynamicItem">

                        <label class="compactTaskListRow__dynamicItem__label">{{ child.displayText | texttransform }}</label>
                        <app-text-input *ngIf="getMemberInfo(child) == 'TEXT'" [(ngModel)]="dataItem[child.value]" (valueChange)="changeRow(dataItem)"
                        [disabled]="!child.isEnabled"></app-text-input>
                        <app-select-input
                            *ngIf="getMemberInfo(child) == 'NUMERIC'"
                            [(ngModel)]="dataItem[child.value]"
                            (valueChange)="changeRow(dataItem)"
                            [disabled]="!child.isEnabled"
                            bind="id"
                            display="defaultName"
                            [options]="subDataLists[child.id]"
                        ></app-select-input>    
                    </div>
                </div>
            </div>        
            </div>
        </div>
    </div>
</div>



