import { Component, Input } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-defaultdashboard-questionnaire',
  templateUrl: './defaultdashboard-questionnaire.component.html',
  styleUrl: './defaultdashboard-questionnaire.component.scss'
})
export class DefaultdashboardQuestionnaireComponent {
  @Input() reports = null;
  @Input() selectedReport = null;
  loadingData = false;
  dashboardResultData = [];
  poolId = null;
  pools = [];
  dashboardId = null;
  questionnairesList = [];

  constructor(
      public applicationInfoService: ApplicationInfoService,
      private dashboardcoreService: DashboardcoreService,
      private commonService: CommonService,
      public userService: UserService,
      private externaldatasourceService: ExternaldatasourceService
  ) {}

  ngOnInit(): void {
    this.dashboardId = this.selectedReport.dashboardId
    this.pools = this.applicationInfoService.pools.toArray();
    this.poolId = this.applicationInfoService.poolId;
    this.getDashboardDataArray(this.dashboardId);
  }

  getDashboardDataArray(dashboardId) {    
    this.loadingData= true
    let params = this.fillParams();
    this.dashboardResultData[dashboardId] = null;
    this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(getDashboardDataArrayResult => {
      if (getDashboardDataArrayResult[0][0]) {        
        this.dashboardResultData[dashboardId] = getDashboardDataArrayResult[0][0].fn_standard_report_question_answer;
      }
      this.questionnairesList = this.getQuestionnaires()
      this.loadingData = false;
    }).catch((error)=>{
      console.log(error);
      this.loadingData = false;
    });
  }

  fillParams() {   
    const params = [];
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@pool_id', value: this.poolId });
    params.push({ key: '@language_id', value: this.applicationInfoService.languageId });    
    return params;
  }

  getQuestionnaireData(question, questionnaire) {
    if (this.dashboardResultData[this.dashboardId] == null) {
      return [];
    }
    return this.dashboardResultData[this.dashboardId].filter(item => 
      item.pool_id == this.poolId &&
      item.question == question && 
      item.questionnaire == questionnaire
    );
  }  

  getQuestions(questionnaire) {    
    if (this.dashboardResultData[this.dashboardId] == null) {
      return [];
    }
    const dataToAnalyze = this.dashboardResultData[this.dashboardId].filter(item => item.questionnaire == questionnaire)
    return [...new Set(dataToAnalyze.map(item => item.question))];
  }

  getQuestionnaires() {      
    if (this.dashboardResultData[this.dashboardId] == null) {
      return [];
    }
    return [...new Set(this.dashboardResultData[this.dashboardId].map(item => item.questionnaire))];
  }
}