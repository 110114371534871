<div class="maxHeight generalSettings" *ngIf="project">
    <app-layout class="agComponentHeader__header" [header]="'GeneralSettings.Label.ProjectSettings' | texttransform">       
        <div class="generalSettings__inner">
            <div class="generalSettings__navMobile">
                <div class="everleadMobileTab">
                    <div class="everleadMobileTab__valueWrapper">
                        <select [(ngModel)]="section" class="selectTab__box">
                            <option [ngValue]="1">{{'GeneralSettings.Label.ManageGeneralSettings' | texttransform }}</option>
                            <option [ngValue]="2" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')">{{'GeneralSettings.Setting.EMailSettings' | texttransform }}</option>
                            <option [ngValue]="3" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')">{{'GeneralSettings.Setting.TeleMarketing' | texttransform }}</option>
                            <option [ngValue]="4" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')">{{'GeneralSettings.Setting.ContactFrequency' | texttransform}}</option>
                            <option [ngValue]="5" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')">{{'GeneralSettings.Setting.CallMasking' | texttransform}}</option>
                            <option [ngValue]="6" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')">{{'GeneralSettings.Setting.ProjectTargets' | texttransform}}</option>
                            <option [ngValue]="7" *ngIf="applicationInfoService.isDeveloper">{{'DEV' | texttransform}}</option>
                            <option [ngValue]="8" *ngIf="applicationInfoService.isDeveloper">{{'Projectsettings' | texttransform}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="generalSettings__nav">
                <app-tab svg="calendar-dots" [active]="section == 1"
                    [label]="'GeneralSettings.Label.ManageGeneralSettings' | texttransform"
                    (onClick)="section = 1"></app-tab>
                <app-tab svg="calendar-dots" [active]="section == 2" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                    [label]="'GeneralSettings.Setting.EMailSettings' | texttransform" (onClick)="section = 2"></app-tab>
                <app-tab svg="calendar-dots" [active]="section == 3" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                    [label]="'GeneralSettings.Setting.TeleMarketing' | texttransform" (onClick)="section = 3"></app-tab>
                <app-tab svg="calendar-dots" [active]="section == 4" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                    [label]="'GeneralSettings.Setting.ContactFrequency' | texttransform" (onClick)="section = 4"></app-tab>
                <app-tab svg="calendar-dots" [active]="section == 5" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                    [label]="'GeneralSettings.Setting.CallMasking' | texttransform" (onClick)="section = 5"></app-tab>
                <app-tab svg="calendar-dots" [active]="section == 6" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                    [label]="'GeneralSettings.Setting.ProjectTargets' | texttransform" (onClick)="section = 6"></app-tab>
                <app-tab svg="calendar-dots" [active]="section == 7" *ngIf="applicationInfoService.isDeveloper"
                    label="Dev" (onClick)="section = 7"></app-tab>
                <app-tab svg="calendar-dots" [active]="section == 8" *ngIf="applicationInfoService.isDeveloper"
                    label="Projectsettings" (onClick)="section = 8"></app-tab>
                <app-tab svg="calendar-dots" [active]="section == 9" label="GeneralSettings.Label.ClearProjectCache" 
                    *ngIf="userService.hasPermission('CanRefreshCache')" (onClick)="section = 9"></app-tab>
            </div>


            <div class="generalSettings__content">

                <app-layout class="generalSettings__containerMargin" *ngIf="section === 1"
                    [header]="'GeneralSettings.Label.ManageGeneralSettings' | texttransform">

                    <div class="generalSettings__componentInner">
                        <!-- <input [(ngModel)]="project.projectName" (change)="saveSettings()">
                        <app-text-input [fire]="true" [label]="'GeneralSettings.Setting.ProjectName' | texttransform"
                        [(ngModel)]="project.projectName" (valueChange)="saveSettings()"></app-text-input>

                        // *test* // -->
                        
                        <app-text-input [label]="'GeneralSettings.Setting.ProjectName' | texttransform"
                            [(ngModel)]="project.projectName" (valueChange)="saveSettings()"></app-text-input>
                        <app-select-input [label]="'GeneralSettings.Setting.Customer' | texttransform" 
                            *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                            [(ngModel)]="project.customerId" [options]="customers" display="customerName" bind="id"
                            (valueChange)="saveSettings()"></app-select-input>
                        <app-text-input [label]="'GeneralSettings.Setting.ProjectBanner' | texttransform" *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                            [(ngModel)]="project.projectBanner" (valueChange)="saveSettings()"></app-text-input>
                        <app-date-input parent="generalSettings"
                            [label]="'GeneralSettings.Setting.ProjectStart' | texttransform"
                            [(ngModel)]="project.projectStart" (dateChange)="saveSettings()"></app-date-input>
                        <app-date-input parent="generalSettings"
                            [label]="'GeneralSettings.Setting.ProjectEnd' | texttransform"
                            [(ngModel)]="project.projectEnd" (dateChange)="saveSettings()"></app-date-input>
                        <app-text-area-input [label]="'GeneralSettings.Setting.Comments' | texttransform"
                            [(ngModel)]="project.comments" (valueChange)="saveSettings()"></app-text-area-input>
                        

                        <app-select-input [label]="'GeneralSettings.Setting.Customer' | texttransform" 
                            *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                            [(ngModel)]="project.customerId" [options]="customers" display="customerName" bind="id"
                            (valueChange)="saveSettings()"></app-select-input>

                        <span *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')">
                            <app-checkbox [label]="'GeneralSettings.Setting.ClassicAccountView' | texttransform"
                                [(ngModel)]="project.isClassicView" (valueChange)="saveSettings()"></app-checkbox>
                            <app-checkbox [label]="'GeneralSettings.Setting.IsUsingDataRights' | texttransform"
                                [(ngModel)]="project.isUsingDataRights" (valueChange)="saveSettings()"></app-checkbox>
                            <app-checkbox [label]="'GeneralSettings.Setting.IsLoadingTaskOnLoginForAgent' | texttransform"
                                [(ngModel)]="project.isLoadingTaskOnLoginForAgent"
                                (valueChange)="saveSettings()"></app-checkbox>
                            <app-checkbox [label]="'GeneralSettings.Setting.IsCheckEvents' | texttransform"
                                [(ngModel)]="project.isCheckEvents" (valueChange)="saveSettings()"></app-checkbox>
                            <app-checkbox
                                [label]="'GeneralSettings.Setting.SelectOnlyContactOnTaskCreation' | texttransform"
                                [(ngModel)]="project.selectOnlyContactOnTaskCreation"
                                (valueChange)="saveSettings()"></app-checkbox>
                            <app-checkbox [label]="'GeneralSettings.Setting.IsUsingReportRights' | texttransform"
                                [(ngModel)]="project.isUsingReportRights" (valueChange)="saveSettings()"></app-checkbox>
                        </span>
                    </div>
                </app-layout>

                <div *ngIf="section === 2">
                    <app-layout [header]="'GeneralSettings.Setting.EMailSettings' | texttransform">
                        <div class="generalSettings__componentInner">
                            <app-select-input [label]="'GeneralSettings.Setting.MailConfigTypeLookupId' | texttransform"
                                [(ngModel)]="project.mailConfigTypeLookupId" [options]="mailConfigTypes"
                                display="defaultName" bind="id" (valueChange)="saveSettings()"></app-select-input>

                            <app-select-input [label]="'GeneralSettings.Setting.MailConfigSMTPRefId' | texttransform"
                                [(ngModel)]="project.mailConfigRefId" [options]="mailConfigSmtpRefIds"
                                display="displayName" bind="id" (valueChange)="saveSettings()"
                                *ngIf="project.mailConfigTypeLookupId == 5936"></app-select-input>

                            <app-select-input *ngIf="project.mailConfigTypeLookupId == 5935"
                                label="GeneralSettings.Setting.MailConfigSesRefId" [(ngModel)]="project.mailConfigRefId"
                                (valueChange)="saveSettings()" [options]="mailConfigSesRefIds" bind="sesConfigSetId"
                                display="defaultName"></app-select-input>
                        </div>
                    </app-layout>

                </div>

                <div *ngIf="section === 3">
                    <app-layout [header]="'GeneralSettings.Setting.TeleMarketing' | texttransform">
                        <div class="generalSettings__componentInner">
                            <app-date-input parent="generalSettings"
                                [label]="'GeneralSettings.Setting.TeleMarketingStart' | texttransform"
                                [(ngModel)]="project.telemarketingStart" (dateChange)="saveSettings()"></app-date-input>
                            <app-date-input parent="generalSettings"
                                [label]="'GeneralSettings.Setting.TeleMarketingEnd' | texttransform"
                                [(ngModel)]="project.telemarketingEnd" (dateChange)="saveSettings()"></app-date-input>
                        </div>
                    </app-layout>

                </div>


                <div *ngIf="section==4">
                    <app-layout class="generalSettings__containerMargin"
                        [header]="'GeneralSettings.Setting.ContactFrequency' | texttransform" *ngIf="section==4">
                        <app-contact-protection [viewMode]="1"></app-contact-protection>
                    </app-layout>

                    <app-layout header="GeneralSettings.Setting.CallSettings" class="generalSettings__containerMargin">
                        <div class="generalSettings__componentInner">
                            <app-number-input [label]="'GeneralSettings.Setting.CountOfCallsPerHour' | texttransform"
                                [(ngModel)]="project.countOfCallsPerHour"
                                (valueChange)="saveSettings()"></app-number-input>
                            <app-number-input [label]="'GeneralSettings.Setting.CountOfNetcallsPerHour' | texttransform"
                                [(ngModel)]="project.countOfNetcallsPerHour"
                                (valueChange)="saveSettings()"></app-number-input>
                            <app-number-input [label]="'GeneralSettings.Setting.NetCallTarget' | texttransform"
                                [(ngModel)]="project.netCallTarget" (valueChange)="saveSettings()"></app-number-input>
                            <app-number-input [label]="'GeneralSettings.Setting.LeadQuota' | texttransform"
                                [(ngModel)]="project.leadQuota" (valueChange)="saveSettings()"></app-number-input>
                            <app-number-input [label]="'GeneralSettings.Setting.CountFteOperational' | texttransform"
                                [(ngModel)]="project.countFteOperational"
                                (valueChange)="saveSettings()"></app-number-input>
                            <app-number-input [label]="'GeneralSettings.Setting.CountProjectHours' | texttransform"
                                [(ngModel)]="project.countProjectHours"
                                (valueChange)="saveSettings()"></app-number-input>
                            <app-select-input [label]="'GeneralSettings.Setting.ReportTypeLookup' | texttransform"
                                [(ngModel)]="project.reportTypeLookupId" [options]="reportTypeLookups"
                                [display]="'defaultName'" bind="id" (valueChange)="saveSettings()"></app-select-input>
                        </div>
                    </app-layout>
                </div>

                <div *ngIf="section === 5">
                    <app-layout [header]="'GeneralSettings.Setting.CallMasking' | texttransform"
                        class="generalSettings__containerMargin">
                        <div class="generalSettings__componentInner">
                            <app-select-input [label]="'GeneralSettings.Setting.AgentMaskingType' | texttransform"
                                [(ngModel)]="project.agentMaskingType" [options]="maskTypes" display="defaultName"
                                bind="id" (valueChange)="saveSettings()"></app-select-input>
                            <app-text-input
                                [label]="'GeneralSettings.Setting.AgentMaskingSpecialNumber' | texttransform"
                                [(ngModel)]="project.agentMaskingSpecialNumber"
                                (valueChange)="saveSettings()"></app-text-input>
                        </div>
                    </app-layout>

                    <app-layout header="GeneralSettings.Setting.AISettings" class="generalSettings__containerMargin">
                        <div class="generalSettings__componentInner">
                            <app-text-input [label]="'GeneralSettings.Setting.AIKey' | texttransform"
                                [(ngModel)]="aiKey.value"
                                (valueChange)="saveApplicationSetting(aiKey)"></app-text-input>
                        </div>
                    </app-layout>

                </div>


                <app-layout [header]="'GeneralSettings.Setting.ProjectTargets' | texttransform" *ngIf="section === 6">
                    <app-main-button type="small" class="generalSettings__marginBottom"
                        label="GeneralSettings.Setting.NewProjectTarget"
                        (onClick)="createProjectTarget()"></app-main-button>
                    <div class="generalSettings__componentInner">
                        <div *ngFor="let projectTarget of projectTargets">
                            <app-generalsettingsprojecttarget [data]="projectTarget"
                                [reportTypeLookups]="reportTypeLookups"></app-generalsettingsprojecttarget>
                        </div>
                    </div>
                </app-layout>

                <div *ngIf="section === 7">
                    <span *ngIf="applicationInfoService.isDeveloper">
                        <app-layout header="Credits verwalten">
                            <div class="creditManagement">
                                <div class="creditInfo" *ngIf="creditItems?.length > 0">Vorhandene Einträge</div>
                                <div *ngFor="let creditItem of creditItems" class="creditItem everleadTableRow">
                                    <div class="creditItem__amount">Gekaufte Menge: {{ creditItem.quotaPurchased }}</div>
                                    <div class="creditItem__purchased">Gültig bis: {{ creditItem.quotaValidUntil | date:'medium' }}</div>
                                    <div class="creditItem__buttons">
                                        <app-jj-icons type="trash" [width]="20" [height]="20" (click)="deleteBrokerQuota(creditItem)"></app-jj-icons>
                                    </div>
                                </div>
                                <div class="newCredits">                                    
                                    <app-text-input label="Menge" [(ngModel)]="newCreditsAmount"></app-text-input>
                                    <!-- <app-date-input label="Gültig bis" [(ngModel)]="newCreditsValidUntil"></app-date-input> -->
                                </div>
                                <app-main-button svg="plus" (onClick)="addBrokerQuota()" label="Credits hinzufügen"
                                    [disabled]="newCreditsAmount == 0" type="small"></app-main-button>
                            </div>
                        </app-layout>

                        <app-layout [header]="'Projekt aktivieren' | texttransform">
                            <app-main-button type="small" label="Projekt aktivieren" (click)="activateProject()" svg="plug"></app-main-button>
                        </app-layout>

                        <app-layout [header]="'GeneralSettings.Setting.Sync' | texttransform"
                            class="generalSettings__containerMargin">
                            <div class="generalSettings__componentInner">
                                <app-checkbox [label]="'GeneralSettings.Setting.ActiveSync' | texttransform"
                                    [(ngModel)]="project.activeSync" (valueChange)="saveSettings()"></app-checkbox>
                                <app-text-input [label]="'GeneralSettings.Setting.DmcAccountId' | texttransform"
                                    [(ngModel)]="project.dmcAccountId" (valueChange)="saveSettings()"></app-text-input>
                                <app-text-input [label]="'GeneralSettings.Setting.TrackingSchema' | texttransform"
                                    [(ngModel)]="project.trackingSchema"
                                    (valueChange)="saveSettings()"></app-text-input>
                            </div>
                        </app-layout>

                        <app-layout [header]="'GeneralSettings.Setting.RingCentralConnection' | texttransform"
                            class="generalSettings__containerMargin">
                            <div class="generalSettings__componentInner">
                                <app-text-input
                                    [label]="'GeneralSettings.Setting.RingCentralConnectionId' | texttransform"
                                    [(ngModel)]="ringCentralConnectionId" type="password"></app-text-input>
                                <app-text-input
                                    [label]="'GeneralSettings.Setting.RingCentralConnectionSecret' | texttransform"
                                    [(ngModel)]="ringCentralConnectionSecret" type="password"></app-text-input>


                                <div class="generalSettings__buttonContainer">
                                    <app-main-button type="small"
                                        label="GeneralSettings.Setting.RingCentralConnectionSave"
                                        (onClick)="saveRingCentralSettings()" svg="plug"></app-main-button>
                                </div>
                            </div>
                        </app-layout>
                    </span>
                    

                    <div class="generalSettings__containerMargin everleadContentContainer">
                        <div [innerHTML]="innerHTML"></div>
                    </div>
                </div>                

                <div *ngIf="section==8" class="projectSettingsWrapper">
                        <div class="projectSettingsHeader">
                            <app-main-button svg="plus" (onClick)="createProjectSetting()" label="Neues Setting" type="small"></app-main-button>
                        </div>                        
                        <div class="projectSettingItem__container">
                            <div class="projectSettingItem projectSettingItem__header">
                                <div class="projectSettingItem__id">ID</div>
                                <div class="projectSettingItem__setting">Setting</div>
                                <div class="projectSettingItem__setting">Value</div>
                            </div>
                            <div *ngFor="let projectSetting of projectSettings" class="everleadTableRow">
                                <div class="projectSettingItem">
                                    <div class="projectSettingItem__id">{{ projectSetting.id }}</div>
                                    <div class="projectSettingItem__setting">
                                        <input [(ngModel)]="projectSetting.setting" (change)="updateProjectSetting(projectSetting)">
                                    </div>
                                    <div class="projectSettingItem__value">
                                        <input [(ngModel)]="projectSetting.value" (change)="updateProjectSetting(projectSetting)">
                                    </div>                      
                                </div>
                            </div>
                        </div>                
                </div>

                <div *ngIf="section==9" class="projectSettingsWrapper">
                    <div class="projectSettingsClearCacheDesc">
                        {{ 'GeneralSettings.Label.ClearProjectCacheDesc' | texttransform }}
                    </div>
                    <app-main-button svg="arrows-counter-clockwise" (onClick)="clearProject()" 
                        label="GeneralSettings.Label.ClearProjectCache" type="small" ></app-main-button>    
                </div>
            </div>
        </div>
    </app-layout>
</div>