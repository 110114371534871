<div class="adminSettingsPop">
    <div class="adminSettingsPop__inner" *ngIf="!loading">
        <div class="row">
            <div class="col-12">
                <app-number-input [(ngModel)]="data.projectId" label="ProjectId"></app-number-input>
            </div>
            <div class="col-12">
                <app-text-input [(ngModel)]="data.lookupSettingType"
                    label="Admin.Label.lookupSettingType"></app-text-input>
            </div>
            <div class="col-12">
                <app-text-input [(ngModel)]="data.setting" label="Setting"></app-text-input>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <app-text-area-input height="350" [(ngModel)]="data.value" label="Value"
                    resize="none"></app-text-area-input>
            </div>
            <div class="col-6">
                <app-text-area-input height="350" [(ngModel)]="data.comment" label="Comment"
                    resize="none"></app-text-area-input>
            </div>
        </div>

        <div class="row">
            <div class="adminSettingsPop__buttonsContainer" *ngIf="!loading">
                <app-main-button *ngIf="mode === 'create'" type="borderSmall" (onClick)="createSetting()"
                    label="Create Setting"></app-main-button>
                <app-main-button *ngIf="mode === 'update'" type="borderSmall" (onClick)="updateSetting()"
                    label="Update Setting"></app-main-button>
            </div>
            
            <span *ngIf="loading">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform
                }}
            </span>
        </div>
    </div>
</div>

