<app-layout [header]="selectedReport.defaultName" [subheader]="selectedReport?.subHeader">
    <div class="defaultDashboardConsentContentWrapper">
        <div class="defaultDashboardConsentToolbarSelection">
            <div class="defaultDashboardConsentToolbarSelection__tabs">
                <app-select-input label="Account.Label.PoolId" [(ngModel)]="poolId" [options]="pools" display="defaultName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
            </div>             
        </div>
    </div>

    <div *ngIf="loadingData == true">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </div>
    
    <div class="dataDiv" *ngIf="dashboardResultData[this.dashboardId]==null && loadingData == false">
        {{ 'General.Label.NoDataAvailable' | texttransform }}
    </div>

    <div class="dataDiv">
        <div *ngIf="loadingData == false" class="defaultDashboardConsentTableContainer">
            <div *ngFor="let questionnaire of questionnairesList">                    
                <app-layout [header]="questionnaire">
                    <div *ngFor="let question of getQuestions(questionnaire)">
                        <app-bar-chart 
                            height="200px"
                            *ngIf="getQuestionnaireData(question, questionnaire).length > 0"
                            [barHeader]="question"
                            [chartData]="getQuestionnaireData(question, questionnaire)" 
                            component="questionnaireDashoard" 
                            [controlid]="questionnaireDashoardChart">
                        </app-bar-chart>            
                    </div>    
                </app-layout>                    
            </div>
        </div>    
    </div>
</app-layout>