import { Component, OnInit, } from '@angular/core';
import { hasDirectives } from 'apollo-utilities';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { ImportHelperService } from '../../supervisor/import/import-helper.service';
import { WizardService } from '../wizard.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-wizard-dataimport',
  templateUrl: './wizard-dataimport.component.html',
  styleUrls: ['./wizard-dataimport.component.scss']
})
export class WizardDataimportComponent implements OnInit{
  uploadingFile = false;
  uploadingFileError = false;
  
  sheets = [];
  pools = [];
  lookupTables = [];
  savedTemplates = [];
  dataPopupOpen = false;
  templateMode = 0;
  newTemplateName = '';
  newLookupTableName = '';
  newPoolName = '';
  suggestionValues = [];
  headerToModify = null;
  importCurrentlyRunning = false;
  importResult = null;
  automaticAssignmentArray = [];
  isSkipImportOnError = false;
  isCheckIfEmailExists = true;
  memberInfo = [];

  accountMember = [];
  contactMember = [];

  constructor(
    private sharedAPI: SharedAPI,
    private importHelperService: ImportHelperService,
    public wizardService: WizardService,
    public commonService: CommonService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private messagingService: MessagingService,
    private eventService: EventService,
    private axivasTranslationService: AxivasTranslateService
  ) { }


  ngOnInit(): void {
    this.pools = this.applicationInfoService.pools.toArray();
    this.wizardService.wizardArray.poolId = this.applicationInfoService.poolId;
    this.getSuggestions();
    this.getLookupTables();  
    this.getAutoAssignments();
    this.commonService.sortArrayWithTranslationToken(this.applicationInfoService.entities.Item('1').entityMembers);
    this.commonService.sortArrayWithTranslationToken(this.applicationInfoService.entities.Item('4').entityMembers);  

    // this.accountMember = JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item('1').entityMembers));
    // this.contactMember = JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item('4').entityMembers));

    if (this.applicationInfoService.isDeveloper) {
      this.accountMember = JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item('1').entityMembers));
      this.contactMember = JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item('4').entityMembers));  
    } else {
      this.accountMember = this.fillMemberList('account', JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item('1').entityMembers)));
      this.contactMember = this.fillMemberList('contact', JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item('4').entityMembers)));  
    }
    this.commonService.newSortArrayWithTranslationToken(this.accountMember, 'defaultName', false);
    this.commonService.newSortArrayWithTranslationToken(this.contactMember, 'defaultName', false);
  }


  fillMemberList(base, member) {
    const array = [];
    let checkArray = [];
      if (base == 'account') {
        checkArray = this.applicationInfoService.controls.toArray().filter(uicontrol => 
          uicontrol.isActive == true &&
          uicontrol.uiEntityInstanceId == 1
        );
      }
      if (base == 'contact') {
        checkArray = this.applicationInfoService.controls.toArray().filter(uicontrol => 
          uicontrol.isActive == true && 
          uicontrol.uiEntityInstanceId == 4
        );
      }
    member.forEach(memberItem => {
      const foundInUi = checkArray.find(baseItem => baseItem.value == memberItem.defaultName);
      if (foundInUi) {
        array.push(memberItem);
      }      
    });
    return array;
  }

  getAutoAssignments() {
    this.externaldatasourceService.executeExternalDataSource(276).then(result => {
      this.automaticAssignmentArray = result;
    });
  }

  getLookupTables(): Promise<any> {
    return new Promise((getLookupTablesResolve, getLookupTablesReject) => {
      this.lookupTables['data'] = JSON.parse(JSON.stringify(this.applicationInfoService.projectLookupTables.filter(table => 
        table.source == 'data' && 
        table.isActive == true
      )));

      this.lookupTables['data'].forEach(lookupDefinition => {
        if (lookupDefinition.nameTranslationToken) {
          lookupDefinition.translation = this.axivasTranslationService.getTranslationTextForToken(lookupDefinition.nameTranslationToken.tokenFullName);
        } else {
          lookupDefinition.translation = lookupDefinition.definitionName;
        }
        this.lookupTables['data'].sort((a, b) => a.translation < b.translation ? -1 : 1);
      });
      getLookupTablesResolve(this.lookupTables['data']);
    }) 
  }

  getSuggestions() {
    this.externaldatasourceService.executeExternalDataSource(624)
    .then(getSuggestions => {
      this.suggestionValues = getSuggestions;
    });
  }

  uploadFile(event) {
    this.uploadingFile = true;
    this.uploadingFileError = false;
    const files: FileList = event.target.files;
    const formData = new FormData();
    this.wizardService.wizardArray.selectedSheet = null;
    for ( let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }
    this.sharedAPI.uploadFile(formData).subscribe(uploadFileResult => { 
      uploadFileResult.forEach(result => { this.orderSheet(result) })     
      if (uploadFileResult.length > 0) {
        this.wizardService.wizardArray.selectedSheet = uploadFileResult[0];
      }          
      this.sheets = uploadFileResult;
      this.importHelperService.currentImportFile = files[0].name;
      this.importHelperService.currentImportFileFormData = files[0];
      this.wizardService.wizardArray['uploadFile'] = files[0].name;
      this.wizardService.wizardArray['uploadFileFormData'] = files[0];
      this.wizardService.wizardPage = 2;
      this.uploadingFile = false;
      this.suggestValues();
    }
    , (error) => {
      console.warn(error);
      this.uploadingFile = false;
      this.uploadingFileError = true;
    });
  }

  toNumber(value) {
    return Number(value);
  }
  
  getMembers(header) {
    return;
    if (this.commonService.isNullOrUndefined(this.memberInfo[header.entity])) {
      if (this.applicationInfoService.entities.ContainsKey(header.entity)) {
        this.memberInfo[header.entity] = this.applicationInfoService.entities.Item(header.entity).entityMembers;
        return this.applicationInfoService.entities.Item(header.entity).entityMembers;
      } else {
        return [];
      }  
    } else {
      return this.memberInfo[header.entity];
    }
  }

  getMemberName2(member) {   
    if (this.commonService.isNullOrUndefined(this.memberInfo[member.id])) {      
      this.memberInfo[member.id] = this.commonService.getNameTranslationOrDefaultName(member);
      return this.memberInfo[member.id]      
    } else {
      return this.memberInfo[member.id];
    }
  }

  getMemberName(header) {       
    if (this.commonService.isNullOrUndefined(header.member)) {
      return;
    }
    if (this.commonService.isNullOrUndefined(this.memberInfo[header.member])) {      
      const member = this.applicationInfoService.entityMember.toArray().find(member => member.id == header.member);
      if (member) {
        this.memberInfo[header.member] = this.commonService.getNameTranslationOrDefaultName(member);
      } else {
        this.memberInfo[header.member] = header.memberName;
      }  
      return this.memberInfo[header.member]      
    } else {
      return this.memberInfo[header.member];
    }
  }

  checkIfDirty(header) {
    if (header.entity == undefined) { header.entity = null; }
    if (header.showDetails == undefined) { header.showDetails = false; }
    if (header.skipRecordIfEmpty == undefined) { header.skipRecordIfEmpty = false; }
    if (header.isKeyMember == undefined) { header.isKeyMember = false; }
    if (header.checkForDuplicates == undefined) { header.checkForDuplicates = false; }

    header.isDirty = false;
    if (header.entity != null && header.member == null) {
      header.isDirty = true;
    }
    if (header.member == null || header.entity == null) {
      return header.isDirty;
    }

    const headersWithSameMember = this.wizardService.wizardArray.selectedSheet.data.headers.filter(headerItem => headerItem.member == header.member);

    if (headersWithSameMember.length > 1) { header.isDirty = true; }

    // Sonderfall neuer Member
    if (header.member == -1) {
      header.isDirty = false;
      if (header.memberName == '' || this.commonService.isNullOrUndefined(header.memberName)) {
        header.isDirty = true;
      }
      const headersWithSameMemberName = this.wizardService.wizardArray.selectedSheet.data.headers.filter(headerItem => headerItem.memberName == header.memberName);
      if (headersWithSameMemberName.length > 1) { header.isDirty = true; }
      if (!this.isValidNewMemberName(header)) { header.isDirty = true; }
    }
    
    return header.isDirty;
  }

  checkIfAccountNameIsAssigned() {
    return this.wizardService.wizardArray.selectedSheet.data.headers.filter(headerItem => headerItem.member == 1);
  }

  checkIfAccountKeyValuesAreSet() {
    return this.wizardService.wizardArray.selectedSheet.data.headers.filter(headerItem => headerItem.entity == 1 && headerItem.isKeyMember == true);
  }

  checkIfContactKeyValuesAreSet() {
    return this.wizardService.wizardArray.selectedSheet.data.headers.filter(headerItem => headerItem.entity == 4 && headerItem.isKeyMember == true);
  }

  checkIfContactIsAssigned() {
    return this.wizardService.wizardArray.selectedSheet.data.headers.filter(headerItem => headerItem.entity == 4);
  }

  checkForDirtyRows() {
    return this.wizardService.wizardArray.selectedSheet.data.headers.filter(headerItem => headerItem.isDirty == true);
  }

  checkIfImportCanBeStarted() {
    let returnValue = true;
    if (
      this.checkForDirtyRows().length > 0 
      || this.checkIfAccountKeyValuesAreSet().length == 0
      || this.wizardService.wizardArray.poolId == null
      || this.checkIfAccountNameIsAssigned() == 0
    ) {
      returnValue = false;
    }
    if (this.checkIfContactIsAssigned().length > 0) {
      if (this.checkIfContactKeyValuesAreSet().length == 0) { returnValue = false; }
    }
    return returnValue;
  }

  targetTypeNotMatching(header, member) {
    let returnValue = false;
    switch (header.type) {
      case 'int':
        if (member.memberType != 'NUMERIC' && member.memberType != 'TEXT') { returnValue = true; }        
        break;        
      case 'string':
        if (member.memberType != 'TEXT') { 
          if (member.lookupTableId == null) {
            returnValue = true; 
          }          
        }        
        break;        
      }
    return returnValue;
  }

  memberHasLookup(memberId) {
    let returnValue = false;
    if (this.commonService.isNullOrUndefined(memberId) || memberId == -1) {
      return returnValue;
    }
    const member = this.applicationInfoService.entityMember.toArray().find(entityMember => entityMember.id == memberId );
    if (member) {
      if (member.lookupTableId != null) {
        returnValue = true;
      }
    }
    return returnValue;
  }

  resetHeaderIfRequired(header) {
    if (this.commonService.isNullOrUndefined(header.typeOrig)) {
      header.typeOrig = header.type;      
    }
    if (header.lookupTableName == null) {
      header.type = header.typeOrig;
    } else {
      header.type = 'int';
    }
    if (header.entity == null) {
      header.member = null;
      header.isKeyMember = false;
    }
  }


  suggestValues() {
    this.wizardService.wizardArray.selectedSheet.data.headers.forEach(header => {
      this.automaticAssignmentArray.forEach(arrayItem => {        
        if (arrayItem.sourceName == header.name) {
          if (header.member == null) {
            header.entity = arrayItem.entityId;
            header.memberName = arrayItem.entityMemberName;
            header.member = arrayItem.entityMemberId;            
            if (!header.colHasEmptyValue) {
              header.isKeyMember = arrayItem.isKeyMember;
            }            
            header.type = arrayItem.type;
            header.lookupTableName = arrayItem.lookupTableDefinitionName;    
            if (header.lookupTableName != null) {
              header.isCreateNewLookupValues = true;
            }            
            this.enrichHeader(header);
          }
        }
      });

      this.suggestionValues.forEach(suggestion => {
        if (this.commonService.checkIfStringContainsString(header.name, suggestion.defaultName)) {
          if (header.member == null) {
            header.entity = suggestion.entityId;
            header.member = suggestion.memberId;
            if (header.member == 1) {
              header.isKeyMember = true;
            }
            if (header.member == 38) {
              header.isKeyMember = true;
            }
            if (header.member == 44) {
              header.checkForDuplicates = true;
            }
            if (header.colHasEmptyValue == true) {
              header.isKeyMember = false;
            }
            this.enrichHeader(header);
          }
        }
      });
    })
  }

  enrichHeader(header, invert = false) {
    header.isNewMember = false;
    if (header.member == null) {
      return;
    }
    if (header.member == -1) {
      header.isNewMember = true;
      header.memberName = header.name.toLowerCase();
      this.checkMemberName(header);
      return;
    }
    if (invert == false) {
      const member = this.applicationInfoService.entityMember.toArray().find(entityMember => entityMember.id == header.member );
      if (member) {
        header.memberName = member.defaultName;  
        header.lookupTableDefinition = member.lookupTableId;
        if (member.lookupTableId != null) {
          const tempLookupTable = this.lookupTables[member.lookupTableSource].find(table => table.definitionId == member.lookupTableId);
          if (tempLookupTable) {
            header.lookupTableName = tempLookupTable.definitionName;
          } else {
            header.lookupTableName = null;
            console.warn('Not found:',  member.lookupTableId, member);
          }
        } else {
          header.lookupTableName = null;
        }
      }      
    } else {

    }
  }

  importSheet(checkData = false, toTempTable = false) {   
    this.wizardService.wizardArray.selectedSheet.data.headers.forEach(header => {
      if (header.type == null || header.lookupTableDefinitionName != null  ) { header.type = 'string' }
    });
    this.importCurrentlyRunning = true;
    this.messagingService.showDefaultInfo('', 'Import.Label.ImportedStarted')
    this.importHelperService.sendImportDataToBackEnd(
      this.wizardService.wizardArray.selectedSheet,
      this.importHelperService.currentImportFile,
      this.wizardService.wizardArray.poolId,
      this.applicationInfoService.projectID,
      toTempTable,
      checkData,
      this.isCheckIfEmailExists,
      this.isSkipImportOnError
    ).then(importResult => {
      this.importResult = importResult;
      this.templateMode = 5;
      this.dataPopupOpen = true;
      this.importCurrentlyRunning = false;
      this.eventService.dataImportFinishedEvent.emit(importResult);
    })
    .catch(error => {
      console.error(error);
      this.eventService.dataImportFinishedEvent.emit(null);
      this.importCurrentlyRunning = false;
    });
  }

  saveAssignment() {
    this.dataPopupOpen = true;
    this.templateMode = 1;
  }

  loadAssignment() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(627)
    .then(templates => {
      this.savedTemplates = templates;
      this.dataPopupOpen = true;
      this.templateMode = 2;
      this.loaderService.display(false);
    });
  }

  updateImportTemplate(template) {
    this.externaldatasourceService.executeExternalDataSource(637, [
      this.commonService.getModifyArrayBody(template, [])
    ]);
  }

  deleteImportTemplateField(array, templateField) {    
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(640, [templateField.id])
    .then(() => {
      this.commonService.removeItemFromArray(array, templateField);
      this.loaderService.display(false);
    });  
  }

  deleteImportTemplate(template) {    
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(639, [template.id])
    .then(() => {
      this.externaldatasourceService.executeExternalDataSource(638, [template.id])
      .then(() => {
        this.loadAssignment();
        this.loaderService.display(false);
        if (this.wizardService.wizardArray.selectedTemplate.id == template.id) {
          this.wizardService.wizardArray.selectedTemplate = null;
        }
      }).catch(() => { this.loaderService.display(false); });  
    }).catch(() => { this.loaderService.display(false); });  
  }

  createImportTemplate(templateName) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(625, [templateName])
    .then(createImportTemplateResult => {      
      this.insertFieldForTemplate(createImportTemplateResult.id);
      this.loaderService.display(false);
      this.dataPopupOpen = false;
      this.wizardService.wizardArray.selectedTemplate = { name: templateName, id: createImportTemplateResult.id }
    }).catch(() => { this.loaderService.display(false); });  
  }

  insertFieldForTemplate(templateId) {
    const promiseArray: Promise<any>[] = [];
    this.loaderService.display(true);
    this.wizardService.wizardArray.selectedSheet.data.headers.forEach(header => {
      if (header.entity != null && header.member != -1) {                  
        promiseArray.push(this.externaldatasourceService.executeExternalDataSource(626, [
          this.commonService.getModifyArrayBody(
            {
              templateId: templateId,
              name: header.name,
              type: header.type,
              entityId: header.entity,
              entityMemberName: header.memberName,
              isKeyMember: header.isKeyMember,
              lookupTableDefinitionName: header.lookupTableName,
              isNewMember: false,
              entityMemberId: header.member,
              skipRecordIfEmpty: header.skipRecordIfEmpty
            }
          , [])
        ]));
      }
    });
    Promise.all(promiseArray)
    .then(() => {
      this.loaderService.display(false);
    });
  }

  assignByTemplate(template) {
    this.wizardService.wizardArray.selectedTemplate = template
    template.fields.forEach(field => {
      let headerToChange = this.wizardService.wizardArray.selectedSheet.data.headers.find(header => header.name == field.name);
      if (headerToChange) {
        headerToChange.entity = field.entityId;
        headerToChange.member = field.entityMemberId;
        headerToChange.isKeyMember = field.isKeyMember;
        headerToChange.memberName = field.entityMemberName;
        headerToChange.skipRecordIfEmpty = field.skipRecordIfEmpty;
        headerToChange.lookupTableName = field.lookupTableDefinitionName;
      }
    });
    this.dataPopupOpen = false;
  }

  createNewLookupTable(header) {
    this.newLookupTableName = '';
    this.templateMode = 3;
    this.dataPopupOpen = true;
    this.headerToModify = header;
  }
  
  createLookupTable(newLookupTableName) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(194, [newLookupTableName])
    .then(createLookupTableResult => {
      this.getLookupTables().then(() => {
        this.headerToModify.lookupTableDefinition = createLookupTableResult.id;
        this.headerToModify.lookupTableName = newLookupTableName;
        this.loaderService.display(false);
        this.dataPopupOpen = false;
      });
    }).catch(() => { this.loaderService.display(false); });  
  }

  createPool() {
    this.newPoolName = '';
    this.templateMode = 4;
    this.dataPopupOpen = true;
  }

  createNewPool(poolName) {
    const pool = { defaultName: poolName, isActive: true, projectId: this.applicationInfoService.projectID };
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(582, [
      this.commonService.getModifyArrayBody(pool, [])
    ])
    .then(createNewPoolResult => { 
      pool['id'] = createNewPoolResult.id;
      this.pools.push(pool);
      this.applicationInfoService.pools.Add(createNewPoolResult.id, pool);
      this.wizardService.wizardArray.poolId = createNewPoolResult.id;
      this.loaderService.display(false);
      this.dataPopupOpen = false;
    });
  }  

  checkNewMembers() {
    let returnValue = this.wizardService.wizardArray.selectedSheet.data.headers.filter(headerItem => headerItem.isNewMember == true);
    return returnValue;      
  }    

  updateTemplateContent() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(639, [
      this.wizardService.wizardArray.selectedTemplate.id
    ]).then(() => {
      this.loaderService.display(false);
      this.insertFieldForTemplate(this.wizardService.wizardArray.selectedTemplate.id);
    }).catch(() => { this.loaderService.display(false); });  ;    
  }

  isValidNewMemberName(header) {    
    let returnValue = true;
    const member = this.applicationInfoService.entities.Item(header.entity).entityMembers.find(member => member.defaultName == header.memberName);
    const invalidMemberNames = this.applicationInfoService.applicationSettings['invalidMemberNames'].split(';');
    invalidMemberNames.forEach(membername => {
      if (header.memberName == membername) {
        returnValue = false;
      }
    });
    if (member) { returnValue = false; }
    return returnValue;
  }

  checkMemberName(header) {
    header.memberName = header.memberName.replace(/ü/g, "ue");
    header.memberName = header.memberName.replace(/ä/g, "ae");
    header.memberName = header.memberName.replace(/ö/g, "oe");
    header.memberName = header.memberName.replace(/ /g, "");
    header.memberName = header.memberName.replace(/[^a-zA-Z0-9 ]/g, "");
    while(!isNaN(header.memberName[0]) && header.memberName.length > 0) {
      header.memberName = header.memberName.substring(1, header.memberName.length);
    }
  }

  setEntity(header, entity) {
    if (header.entity != entity) {
      header.member = null;
    } 
    header.entity = entity;
    this.resetHeaderIfRequired(header);
  }

  setMember(header, member) {
    header.member = member;
    this.enrichHeader(header);
  }  

  getItemMouseover(header: any, index: number) {
    let returnString = header.name;
    returnString = returnString.concat('\n');
    let counter = 0;
    for (const dataItem of this.wizardService.wizardArray.selectedSheet.data.data) {
      if (returnString !== '') {
        returnString = returnString.concat('\n');
      }
      returnString = returnString.concat(dataItem[index]);
      if (counter > 5) {
        break;
      }
      counter ++;
    }
    return returnString;
  }

  getDeveloperResult() {
    return ''.concat(
      'Sehr geehrte Damen und Herren,',
      '<br><br>',
      'Folgende Datensätze wurden importiert:',
      '<br><br>',
      'Unternehmen: ', this.importResult.importedAccounts,
      '<br>',
      'Ansprechpartner: ', this.importResult.importedContacts,
    );
  }

  copyToClipBoard() {
    let textValue = this.getDeveloperResult();
    textValue = textValue.replace(/<br>/g, '\n')
    this.commonService.copyTextToClipboard(textValue);
      this.messagingService.showDefaultSuccess(
        'General.CopyToClipBoard.Header',
        'General.CopyToClipBoard.Message',
        false
      );
  }

  cloneHeader(header) {
    const newHeader = JSON.parse(JSON.stringify(header));
    newHeader.isDoublicate = true;
    newHeader.doublicateSource = header;
    if (this.commonService.isNullOrUndefined(header.lastDoublicateOrder)) {
      newHeader.oderValue = header.orderValue + 1;
      newHeader.copy = 1;
      header.lastDoublicateOrder = 2;
    } else {
      newHeader.orderValue = header.orderValue + header.lastDoublicateOrder;
      newHeader.copy = header.lastDoublicateOrder - 1;
      header.lastDoublicateOrder ++;
    }
    this.wizardService.wizardArray.selectedSheet.data.headers.push(newHeader);
    this.orderSheet(this.wizardService.wizardArray.selectedSheet, false);
  }

  removeHeader(header) {
    this.commonService.removeItemFromArray(this.wizardService.wizardArray.selectedSheet.data.headers, header);
  }

  orderSheet(sheet, init = true) {
    if (init) {
      let order = 100;
      let dataOrder = 0;
      sheet.data.headers.forEach(header => {
        header.orderValue = order;
        header.dataOrder = dataOrder;
        order = order + 100;
        dataOrder ++;        
      });  
    }
    sheet.data.headers.sort((a, b) => a.orderValue < b.orderValue ? -1 : 1);
  }

  exportData(importData) {    
    let fileName = this.importHelperService.currentImportFile;
    fileName = fileName.replace('.xlsx', '');
    this.sharedAPI.exportNotImportedData(this.applicationInfoService.projectID, this.importResult.guid).subscribe(result => {
      this.commonService.downloadFile(result, 'application/' + 'xlsx', 
        fileName + ' ' + this.commonService.getDateTimeString(new Date(Date.now()))  + '_notImportetData.xlsx'
      );
    });
  }

  getColor(header) {
    let color = 'secondary';
    if (header.isDirty) { color = 'red'; }
    if (!header.isDirty && header.member != null) { color = 'green'; }
    return color;
  }
}
