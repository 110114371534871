<div *ngIf="featureNotFound == true" class="featureNotAvailableDiv">
    <app-features [fixFeatureId]="featureId"></app-features>
</div>

<div class="everleadContentContainer prospect maxHeight" *ngIf="applicationInfoService.applicationSettings['isDemoproject'] !== 'true' && featureNotFound == false">
    <div class="prospect__layout">
        <div class="prospect__tabsMobile">
            <div class="everleadMobileTab">
                <div class="everleadMobileTab__valueWrapper">
                    <select [(ngModel)]="viewMode" class="selectTab__box">
                        <option [ngValue]="0">{{ 'ProspectsDashboard.Tab.Table' | texttransform }}</option>
                        <option [ngValue]="1">{{ 'ProspectsDashboard.Tab.Chart' | texttransform }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="prospect__tabs">
            <app-tab svg="list-bullets" [active]="viewMode === 0" label="ProspectsDashboard.Tab.Table" (onClick)="viewMode=0"></app-tab>
            <app-tab svg="chart-line" [active]="viewMode === 1" label="ProspectsDashboard.Tab.Chart" (onClick)="viewMode=1"></app-tab>
        </div>

        <div *ngIf="viewMode === 0">
            <div [outerHTML]="innerHTML"></div>
        </div>
        <div *ngIf="viewMode === 1">
           <!--  <app-prospects-activity-dashboard></app-prospects-activity-dashboard> -->
          <app-prospects-dashboard></app-prospects-dashboard>
        </div>
    </div>    
</div>

<div class="everleadContentContainer prospect maxHeight" *ngIf="applicationInfoService.applicationSettings['isDemoproject'] == 'true' && featureNotFound == false">
    <div [outerHTML]="innerHTML"></div>
</div>

