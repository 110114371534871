import { Component, Input, OnInit, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jj-icons',
  templateUrl: './jj-icons.component.html',
  styleUrl: './jj-icons.component.scss'
})
export class JjIconsComponent implements OnInit, AfterViewInit, OnDestroy {  
  customEventSubscriptions: Subscription = new Subscription();
  iconId = null;
  @Input() type:string= '';
  @Input() width:number = 32;
  @Input() height: number = 32;
  @Input() color: string ='secondary'
  @Input() strokeColor: string ='secondary'
  @Input() pointer: boolean = true;
  @Input() disabled: boolean = false;
  iconAdded = false;

  constructor(
    private commonService: CommonService,
    private renderer: Renderer2,
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService
  ) {}

  private isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
  }

  ngOnInit(): void {
    this.iconId = 'everleadIcon'.concat(this.commonService.createGuid());
    this.customEventSubscriptions = this.eventService.customEvent.subscribe(event => {      
      if (event.id == 'applicationSettingsLoaded') { 
        setTimeout(() => { this.addIcon(); }, 0);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customEventSubscriptions) { this.customEventSubscriptions.unsubscribe(); }
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.addIcon(); }, 0);
  }



  getHeight() {
    if(!this.height){ 
      return '20px' 
    } else {
      return this.isNumeric(this.height) ? `${this.height}px` : this.height;
    }

  }

  getWidth(){
    if(!this.width){ 
      return '20px' 
    } else {
    return this.isNumeric(this.width) ? `${this.width}px` : this.width;
    }
  }

  getColor(){

    if(!this.color){
      return '#5465FF';
    } else {
      switch(this.color){
        case 'secondary':
          return '#5465FF';
        case 'primaryLight':
          return '#FFFFFF';
        case 'secondaryShade2':
          return '#788BFF';
        case 'primaryDark':
          return '#FBFBFB';
        case 'tertiary':
          return '#E2FDFF';
        case 'red':
          return '#FF5050';
        case 'green':
          return '#3FE163';        
        case 'yellow':
          return '#FFD600';
        case 'orange':
          return '#FF6B00';
        case 'disabledGray':
          return '#808080';
        case 'gray0':
          return '#DFDFDF';
        case 'disabled':
          return '#d2d2d2';
        case 'fontDark':
          return'#333333'
        default:
          return this.color;
      }
    } 
  }

  getStrokeColor(){
    if(!this.strokeColor){
      return '#5465FF';
    } else {
      switch(this.strokeColor){
        case 'secondary':
          return '#5465FF';
        case 'primaryLight':
          return '#FFFFFF';
        case 'secondaryShade2':
          return '#788BFF';
        case 'primaryDark':
          return '#FBFBFB';
        case 'tertiary':
          return '#E2FDFF';
        case 'red':
          return '#FF5050';
        case 'green':
          return '#3FE163';        
        case 'yellow':
          return '#FFD600';
        case 'orange':
          return '#FF6B00';
        case 'disabledGray':
          return '#808080';
        case 'gray0':
          return '#DFDFDF';
        case 'disabled':
          return '#d2d2d2';
        default:
          return this.color;
      }
    } 
  }

  addIcon() { 
    const iconElement = document.getElementById(this.iconId);
    if(!iconElement){
      return;
    }

    if (this.applicationInfoService?.applicationSettings['iconMode']=='1' || this.applicationInfoService?.applicationSettings['iconMode']=='3') {
      if (this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['phosphorIcons'])) {
        return;
      }

      if (this.iconAdded) {
        return;
      }

      const iconArray = JSON.parse(this.applicationInfoService.applicationSettings['phosphorIcons']);
      const iconPath = iconArray.find(icon => icon.id == this.type);        
      if (iconPath) {
        iconPath.objects.forEach(object => {
          const svgPath = this.renderer.createElement("path", 'http://www.w3.org/2000/svg');
          svgPath.setAttribute(object.type, object.path);  
          const element = document.getElementById(this.iconId);    
          this.renderer.appendChild(element, svgPath);  
          this.iconAdded = true;  
        });
      } else {
        console.warn(this.type, iconArray)
      }     
    }
  }
}
