import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { error } from 'console';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-application-settings',
  templateUrl: './admin-application-settings.component.html',
  styleUrl: './admin-application-settings.component.scss'
})
export class AdminApplicationSettingsComponent implements OnInit, OnDestroy{

  constructor(
    private externalDatasourseService: ExternaldatasourceService,
    private eventService: EventService,
    public applicationInfoService: ApplicationInfoService
  ){}


  //projectId: 409 ? 419
 customEventSubscription: Subscription = new Subscription();
  settings=[]
  filteredArray=[];
  filterOptions=[];
  filterMode
  noData:boolean= true;
  loading:boolean=true;
  header:any=[]


  ngOnInit(){
    this.getApplicationSetings();

    this.customEventSubscription = this.eventService.customEvent.subscribe((event) =>{
      if(event.id === 'AdminSettingEditor'){
        this.getApplicationSetings();
      }
    })
  }

  getApplicationSetings(){
    // 120 ? 119
    this.noData=true;
    this.loading=true;
    this.externalDatasourseService.executeExternalDataSource(120).then((result) =>{
      console.log('result', result)

      if(result.length > 0){
        this.settings = JSON.parse(JSON.stringify(result.sort((a,b) => a.id - b.id)));
        this.settings = this.settings.map((item) =>{
          return{
            ...item,
            id: item.id,
            projectId: item.projectId
          }
        })
        this.filterOptions = JSON.parse(JSON.stringify(Object.keys(this.settings[0])));
        this.filteredArray = this.settings;
        this.noData=false;
        this.loading=false;
      } else {
        this.noData=true;
        this.loading=false;
      }

      this.settings= result
    }).catch((error) =>{
      this.noData=true;
      this.loading=false;
      console.log('errror', error);
    })
  }

  createSetting(){
    const setting={
      id:null,
      projectId:null,
      lookupSettingType:null,
      setting:"",
      value:"",
      comment:""
    }
    this.applicationInfoService.miscSettings["adminApplicationSettings"] = { data: setting, mode: 'create'};
    this.eventService.showJjPopup('Admin.Label.CreateSetting',
      "adminsettingspopup",
      "1020px; 750px"
      );
  }


/*   updateSetting(setting){
    this.applicationInfoService.miscSettings["adminApplicationSettings"] = { data: setting, mode: 'update'};
  this.eventService.showJjPopup('Admin.Label.UpdateSetting',
  "adminsettingspopup",
  "1020px; 750px"
  );
  } */

  updateSetting = (setting) =>{
    this.applicationInfoService.miscSettings["adminApplicationSettings"] = {
      mode: 'update', 
      data: {
      ...setting,
/*       id:setting?.id,
      projectId:setting?.projectId, */
    },
    };

    this.eventService.showJjPopup('Admin.Label.UpdateSetting',
    "adminsettingspopup",
    "1020px; 750px"
    );
  }

  deleteSetting=(setting)=>{
    throw new Error('Method not implemented.');
  }

  filterTable(event){
    if(event){
      this.filteredArray = this.settings.filter((item) => 
      item.id == event || 
      item.projectId == event ||
      item.lookupSettingType == event ||
      (typeof item.setting === 'string' && item.setting.includes(event)) ||
      item.setting == event ||
      (typeof item.value === 'string' && item.value.includes(event)) ||
      item.value == event ||
      (typeof item.comment === 'string' && item.comment.includes(event)) ||
      item.comment == event )
    } else {
      this.filteredArray = this.settings
    }
  }


  ellipsis(string, number){
    return string?.length > number ? string.slice(0, number) + '... ' : string
  }

  ngOnDestroy(): void {
    if(this.customEventSubscription){
      this.customEventSubscription.unsubscribe()
    }
  }

}
