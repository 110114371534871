import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-defaultdashboard-accountowner',  
  templateUrl: './defaultdashboard-accountowner.component.html',
  styleUrl: './defaultdashboard-accountowner.component.scss'
})

export class DefaultdashboardAccountownerComponent implements OnInit {
  @Input() reports = null;
  @Input() selectedReport = null;
  loadingData = false;
  dashboardResultData = [];
  poolId = null;
  pools = [];
  dashboardId = null;

  constructor(
      public applicationInfoService: ApplicationInfoService,
      private dashboardcoreService: DashboardcoreService,
      private commonService: CommonService,
      public userService: UserService,
      private externaldatasourceService: ExternaldatasourceService
  ) {}

  ngOnInit(): void {
    this.dashboardId = this.selectedReport.dashboardId
    this.pools = this.applicationInfoService.pools.toArray();
    this.poolId = this.applicationInfoService.poolId;
    this.getDashboardDataArray(this.dashboardId);
  }

  getDashboardDataArray(dashboardId) {    
    this.loadingData= true
    let params = this.fillParams();
    this.dashboardResultData[dashboardId] = null;
    this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(getDashboardDataArrayResult => {
      this.dashboardResultData[dashboardId] = null;
      if (getDashboardDataArrayResult) {
        if (getDashboardDataArrayResult[0]?.length > 0) {
          this.loadingData = false;          
          this.dashboardResultData[dashboardId] = JSON.parse(JSON.stringify(getDashboardDataArrayResult[0]));               
        } else {
          this.loadingData = false;
        } 
      } else {
        this.loadingData = false;
      }
    }).catch((error)=>{
      console.log(error);
      this.loadingData = false;
    });
  }

  fillParams() {   
    const params = [];
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@pool_id', value: this.poolId });
    params.push({ key: '@language_id', value: this.applicationInfoService.languageId });    
    return params;
  }

  getPoolData(pool) {
    if (this.dashboardResultData[this.dashboardId] == null) {
      return [];
    }
    return this.dashboardResultData[this.dashboardId].filter(item => item.pool_id == pool.id);
  }  
}
