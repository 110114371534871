import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { JJEvent } from 'app/jollyjupiter/service/event.service';
import { InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CustomControlBaseClass } from '../../custom/customcontrolbaseclass';
import { UserService } from 'app/core/authentication/services/user.service';

@Component({
  selector: 'app-compact-tasklist',
  templateUrl: './compact-tasklist.component.html',
  styleUrls: ['./compact-tasklist.component.scss']
})
export class CompactTasklistComponent extends CustomControlBaseClass implements OnInit {
  subDataLists = [];
  updateCombinedActivityListSubScription: Subscription = new Subscription();
  deleteDataSource = null;
  queryDataSource = 484;
  queryDataSourceContact = 491;
  updateDataSource = null;
  createDataSource = null;
  projectUsers = [];
  workflows = [];
  campaigns = [];
  contacts = [];
  loadingData = true;
  additionalItemsFound = false;
  listMode = 2; // 1 Account 2 Contact
  controlView = 1; // 1 Small (for Demoproject) 2 Large (for call projects)
  deleteTaskUserRightName = '';
  darkMode = false;
  quickTaskStepId = null;
  dropItemId: any= null;

  constructor(
    private injector: Injector,
    private projectService: ProjectService,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService,
    public userService: UserService
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;
  }


  ngOnInit() {
    if (
      this.controlDefinition.additionalSetting1 == 'large' ||
      this.uiService.getControlSettingOrDefault(this.controlDefinition, 'compactTaskListUseLargeMode', false) == true
    ) {
      this.controlView = 2;
    }
    this.deleteTaskUserRightName = this.applicationInfoService.applicationSettings['deleteTaskUserRigthName'];

    this.updateCombinedActivityListSubScription = this.eventService.updateCombinedActivityList.subscribe(() => {
      this.getContacts();
      this.getData();
    });

    this.updateControlAttributesEventSubscription  = this.eventService.updateControlAttributesEvent.subscribe(() => {
      this.getContacts();
      this.getData();
    });

    if (this.contactAvailable()) {
      this.listMode = 1;
    }

    if (this.applicationInfoService.compactListLastListMode == 1) {
      this.listMode = 1;
    } else {
      if (this.applicationInfoService.currentContact != null) {
        this.listMode = 2;
      }
    }

    this.getCampaigns();
    this.getWorkflows();
    this.getProjectUsers();
    this.getContacts();
    this.getData();

    this.getSubDataLists();
    if (this.controlDefinition.additionalSetting2 == 'darkMode') {
      this.darkMode = true;
    }


    if (!this.commonService.isNullOrUndefined( this.applicationInfoService.userSettings['quickTaskStepId'
      .concat(this.applicationInfoService.projectID.toString())])) {
      this.quickTaskStepId = this.applicationInfoService.userSettings['quickTaskStepId'.concat(this.applicationInfoService.projectID.toString())];
    } else {
      if (this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['quickTaskCreateStepId'])) {
        this.quickTaskStepId = this.applicationInfoService.applicationSettings['quickTaskCreateStepId'];
      }
    }
  }

  ngOnDestroy() {
    if (this.updateCombinedActivityListSubScription) { this.updateCombinedActivityListSubScription.unsubscribe(); }
    if (this.updateControlAttributesEventSubscription) { this.updateControlAttributesEventSubscription.unsubscribe(); }
  }

  checkQuickTaskId() {
    if (this.commonService.isNullOrUndefined(this.quickTaskStepId)) {
      return false;
    } else {
      if (this.quickTaskStepId == 'null') {
        return false;
      } else {
        return true;
      }      
    }
  }

  getSubDataLists() {
    this.controlDefinition.childs.forEach(child => {
      if (child.isActive && child.controlType == '20' && child.uiEntityInstanceId && child.value) {
        this.additionalItemsFound = true;
        const member = this.entityService.getEntityMemberByName(child.uiEntityInstanceId, child.value);
        if (member.externalDataSource) {
          this.externalDatasourceService.executeExternalDataSource(member.externalDataSource.id)
          .then(getSubDataListsResult => {
            this.subDataLists[child.id] = getSubDataListsResult;
          });
        }
      }
    });
  }

  getProjectUsers() {
    if (Number(this.applicationInfoService.projectID) == 164) {
      this.projectUsers = [this.applicationInfoService.user];
      //console.log('projectUsers -> 164', this.projectUsers)
    } else {
      this.externalDatasourceService.executeExternalDataSource(931, []).then(result => {
        this.projectUsers = result
      })
    }
  }

  getWorkflows() {
    this.externalDatasourceService.executeExternalDataSource(350, []).then(result => { this.workflows = result; })
  }

  getCampaigns() {
    this.externalDatasourceService.executeExternalDataSource(123, []).then(result => { this.campaigns = result; })
  }

  getContacts() {
    if (this.applicationInfoService.currentAccount) {
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.currentAccount.id)) {
        this.externalDatasourceService.executeExternalDataSource(492, []).then(result => { 
          this.contacts = result;
          // const preContact = result;
          // this.contacts = preContact.map((item) => {
          //   return {
          //     id: item.id,
          //     name: `${item.firstName} ${item.lastName}`
          //   }
          // })          
        })
      }
    }
  }

  changeListMode(mode) {
    if (!this.contactAvailable()) {
      return;
    }
    this.listMode = mode;
    this.applicationInfoService.compactListLastListMode = mode;
    this.getData();
  }

  contactAvailable() {
    if (this.applicationInfoService.currentContact == null) {
      return false;
    } else {
      return true;
    }
  }

  toNumber(value) {
    return Number(value);
  }

  getData() {
    this.controlDefinition.uiControlExternalDataSources.forEach(dataSource => {
      if (dataSource.externalDataSource.queryType === 'create') { this.createDataSource = dataSource.externalDataSource.id; }
    });

    let contactId = null;
    if (!this.applicationInfoService.currentAccount) {
      return;
    }
    const accountId = this.applicationInfoService.currentAccount.id;

    if (this.applicationInfoService.currentAccount.isDummyAccount) {
      this.listMode = 2;
      if (this.applicationInfoService.currentContact == null) {
        return;
      }
    }

    if (this.listMode == 2) {
      if (this.applicationInfoService.currentContact != null) {
        contactId = this.applicationInfoService.currentContact.id;
      }
    }

    let dataSource = this.queryDataSource;
    if (this.listMode == 2) {
      dataSource = this.queryDataSourceContact;
    }

    this.loadingData = true;
    if (this.queryDataSource != null) {
      this.externalDatasourceService.executeExternalDataSource(dataSource, [accountId, contactId])
      .then(result => {
        this.data = result;
        this.loadingData = false;
      })
    }
  }

  getStepname(step) {
      return this.applicationInfoService.steps.Item(step).defaultName;
  }

  isValidStep(step) {
    let returnValue = true;
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.steps.Item(step))) {
      if (this.applicationInfoService.steps.Item(step).typeLookupId == 3842) {
        returnValue = false;
      }
      if (this.applicationInfoService.steps.Item(step).typeLookupId == 4906) {
        returnValue = false;
      }
    }
    return returnValue;
  }

  resultCount(step) {
    let returnValue = 0;
    if (this.commonService.isNullOrUndefined(this.applicationInfoService.steps.Item(step))) {
      return 0;
    } else {
      this.applicationInfoService.steps.Item(step).results.forEach(result => {
        // console.warn('result', result)
        // returnValue ++;
        if (result.isActive == true && result.isEventResult == false) {
          returnValue ++;
        }
      });
      return returnValue;
    }
  }

  changeRow(task) {
    this.loaderService.display(true);
    this.externalDatasourceService.executeExternalDataSource(418, [
      this.commonService.getModifyArrayBody(task, ['stepLookupTypeId'])
    ])
    .then(() => { this.loaderService.display(false); })
    .catch(() => { this.loaderService.display(false); })
  }

  editTask(task) {
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, ['15', '-1'])
    .then(() => {
      this.methodService.launchInternalMethod(InternalMethodType.PrepareTaskForShowing, ['15', task])
      .then(() => {
        this.applicationInfoService.miscSettings['preventStepChange'] = true;
        this.eventService.showJjPopup('General.Label.EditTask', 'booking', '80');
      });
    });
  }

  createCustomTask() {
    let contactId = null;
    if (this.applicationInfoService.currentContact) {
      contactId = this.applicationInfoService.currentContact.id;
    }
    this.methodService.launchInternalMethod(InternalMethodType.CreateNewTaskForContact, [contactId]);
  }

  addNew() {
    this.loaderService.display(true);
    const promiseArray: Promise<any>[] = [];
    promiseArray.push(this.externalDatasourceService.executeExternalDataSource(1008, [Number(this.quickTaskStepId)]));      
        
    Promise.all(promiseArray).then(() => {
      this.loaderService.display(false);
      this.getData();
      this.eventService.customEvent.emit({ id: 'updateEverleadAccountHeader' });
    })
    .catch(error => {
      this.loaderService.display(false);
      console.error(error);
    });
  }

  deleteTask(task) {
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('CreateTask.Message.DeleteTaskMessage'),
      header: this.axivasTranslateService.getTranslationTextForToken('CreateTask.Message.DeleteTaskHeader'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loaderService.display(true);
        // const deletedTask = new Object();
        // deletedTask['id'] = task.id;
        // deletedTask['isDeleted'] = true;
        this.externalDatasourceService.executeExternalDataSource(504, [
          task.id
        ]).then(() => {
          this.loaderService.display(false);
          this.eventService.updateMicroCampaignViewEvent.emit();
          this.eventService.updateControlContentEvent.emit(new JJEvent('24915', []))
          this.getData();
          this.eventService.closeJjPopup();
        }).catch(() => {
          this.loaderService.display(false);
        });
      },
    })
  }

  loadTask(task) {
    this.methodService.launchInternalMethod(InternalMethodType.ShowTaskByTaskId, [task.id]);
  }

  bookSingleResult(task) {
    task.resultId = this.applicationInfoService.steps.Item(task.stepId).results[0].id;
    task.isFollowUpAppointment = false;
    this.applicationInfoService.entities.Item('15').data = task;
    this.projectService.bookTask('15').then(() => {
      this.getData();
    });
  }

  getMemberInfo(child) {
    let returnValue =  null;
    let member = null;
    if (child.uiEntityInstanceId && child.value) {
      member = this.entityService.getEntityMemberByName(child.uiEntityInstanceId, child.value);
    }

    if (member) {
      returnValue = member.memberType;
    }
    return returnValue;
  }

  taskIsInfoOnly(dataItem) {
    let returnValue = false;
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['infoStepTypes'])) {
      const infoStepTypeArray = this.applicationInfoService.applicationSettings['infoStepTypes'].split(';');
      if (this.commonService.checkIfItemIsInArray(infoStepTypeArray, dataItem.stepLookupTypeId.toString())) {
        returnValue = true;
      }
    }
    return returnValue;
  }

  getControlHeight(designControl, substractValue = 0) {
    let controlHeight = Number(this.uiService.getDesignSpanPosition(designControl, 40));
    if (this.applicationInfoService.innerWidth < 1400) {
      return null;
    } else {
      return (controlHeight - substractValue) + 'px'; 
    }    
  }

}
