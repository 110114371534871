import { Component } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-admin-settings-popup',
  templateUrl: './admin-settings-popup.component.html',
  styleUrl: './admin-settings-popup.component.scss'
})
export class AdminSettingsPopupComponent {
  constructor(
    public applicationInfoService : ApplicationInfoService,
    private externaldatasouceService: ExternaldatasourceService,
    private commonService: CommonService,
    private eventService: EventService,
  ){}

    mode: 'create' | 'update' | null = null;
    data;
    loading=false;;


    ngOnInit(): void {
      this.getAdminApplicationData()
    }

    getAdminApplicationData(){
      this.data =  this.applicationInfoService.miscSettings["adminApplicationSettings"].data;
      this.mode = this.applicationInfoService.miscSettings["adminApplicationSettings"].mode;
    }


  createSetting() {
    // 647
    this.loading = true;
    this.externaldatasouceService.executeExternalDataSource(674, [
      this.commonService.getModifyArrayBody(
        {
          projectId: this.data.projectId ? this.data.projectId : this.applicationInfoService.projectID,
          setting: this.data.setting,
          value: this.data.value,
          comment: this.data.comment
        },
        [])]).then((result) => {
          this.loading = false
          this.eventService.customEvent.emit({ id: 'AdminSettingEditor' })
          this.eventService.closeJjPopup()
        }).catch((error) => {
          this.loading = false
          console.log('createSetting', error)
        })
  }


    updateSetting(){
      // 673
      this.loading=true
      this.externaldatasouceService.executeExternalDataSource(673, [
        this.commonService.getModifyArrayBody(
          {
            id: this.data.id, 
            projectId: this.data.projectId ? this.data.projectId : this.applicationInfoService.projectID ,
            setting:this.data.setting,
            value:this.data.value,
            comment:this.data.comment
          },
          [])]).then((result)=>{
        this.loading= false;
        this.eventService.customEvent.emit({id:'AdminSettingEditor'})
        this.eventService.closeJjPopup()
      }).catch((error) =>{
        this.loading = false;
        console.log('updateSetting -> error', error)
      })
    }
}
