
<span id="accountMainContainer"></span>
<div class="accountWrapper">  
  <div *ngIf="!applicationInfoService.isMobile()"
    [ngClass]="{
      scrollInfo__active: !checkIfScrolled()
      && showAccountInformation() == true
      && !applicationInfoService.isMobile()
      && applicationInfoService.currentAccount != null,
      scrollInfo__inactive: checkIfScrolled(),
      scrollInfo__stickyNav: applicationInfoService.navigationExpandedFixed,
      hideInDom: applicationInfoService.currentAccount == null && checkIfScrolled()
    }" class="scrollInfo">

  </div>
 
  <app-card-accordion [closeWhenExpanding]="true" [ngClass]="{
      appCardRowHidden: !applicationInfoService.isCardsVisible, 
      appCardRow: applicationInfoService.isCardsVisible, 
      appCardRowHeaderHidden: !applicationInfoService.isHeaderVisible 
    }" class="appCardAccordion" 
    [account]="applicationInfoService.currentAccount" 
    [contact]="applicationInfoService.currentContact"
  >
    <div class="appCardAccordionRowInner">
<!--       <div class="closeCardDetailMobile">
        <div class="closeCardDetailMobile__inner" (click)="closeCardDetails()">
          <app-jj-icons type="caret-up" [width]="24" [height]="12" [pointer]="true"></app-jj-icons>
        </div>
      </div>  -->


      <div class="appCardRowItem" *ngIf="applicationInfoService.currentAccount">
        <app-card type="account" [data]="applicationInfoService.currentAccount"></app-card>
<!--         <div class="closeCardDetailMobile">
          <div class="closeCardDetailMobile__inner" (click)="closeCardDetails()">
            <app-jj-icons type="caret-up" [width]="24" [height]="12" [pointer]="true"></app-jj-icons>
          </div>
        </div>  -->
      </div>
      <div class="appCardRowItem appCardRowItemContact" [ngClass]="{ noContactBottomBorder: !applicationInfoService.currentContact }">
        <app-card type="contact" [data]="applicationInfoService.currentContact" [noRightBorder]="true" 
          *ngIf="applicationInfoService.currentContact"></app-card>
      </div>
<!--       <div class="appCardToggle">
        <div class="appCardCloseDetailDiv" (click)="closeCardDetails()">
          <app-jj-icons type="caret-up" [width]="24" [height]="12" [pointer]="true"></app-jj-icons>
        </div>
      </div> -->
    </div>
  </app-card-accordion>

  <ng-container *ngIf="applicationInfoService.projectLoaded">    
    <div class="accountMainContainer" class="accountFormContainer accountFormAccordion">
      <div [innerHTML]="innerHTMLFooter"></div>
    </div>

  </ng-container>
</div>