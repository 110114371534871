<div class="defaultDashboardConsentWrapper">
    <app-layout header="Defaultdashboard.Label.ConsentOverview" [subheader]="selectedReport?.subHeader">
        <div class="defaultDashboardConsentContentWrapper">
            <div class="defaultDashboardConsentToolbarSelection">
                <div class="defaultDashboardConsentToolbarSelection__tabs">
                    <app-date-input label="OptInOptOut.Label.StartDate" [calendarOnly]="true" [(ngModel)]="createdDateFrom" 
                        (dateChange)="getDashboardDataArray(dashboardId)"></app-date-input>	
                    <app-date-input label="OptInOptOut.Label.EndDate" [calendarOnly]="true" [(ngModel)]="createdDateUntil" 
                        (dateChange)="getDashboardDataArray(dashboardId)"></app-date-input>	
                    <app-select-input [label]="'Defaultdashboard.Label.SortBy' | texttransform" [(ngModel)]="sortArrayId" 
                        [options]="sortArray" display="displayName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>                    
                    <app-select-input [label]="'Defaultdashboard.Label.GroupByEntrySource' | texttransform" [(ngModel)]="isGroupByEntrySourceSelect" 
                        [options]="isGroupByEntrySourceArray" display="displayName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>                    

                    <!-- <app-checkbox [label]="'Defaultdashboard.Label.GroupByEntrySource' | texttransform" [(ngModel)]="isGroupByEntrySource" 
                        (valueChange)="getDashboardDataArray(dashboardId)"></app-checkbox>     -->
                </div>
                <div class="defaultDashboardConsentToolbarSelection__viewType" *ngIf="loadingData == false && mediaArray.length > 0">
                    <app-main-button [type]="viewChart === true ? 'small' : 'borderSmall'" svg="chart-bar-horizontal" 
                        label="Dashboard.Label.ViewChart" (onClick)="viewChart=true"></app-main-button>
                    <app-main-button [type]="viewChart === false ? 'small' : 'borderSmall'" svg="list" 
                        label="Dashboard.Label.ViewList" (onClick)="viewChart=false"></app-main-button>
                </div>    
            </div>
        </div>
        <div *ngIf="loadingData == true">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
        </div>

        <div *ngIf="loadingData == false && viewChart == false" class="defaultDashboardConsentTableContainer">        
            <div *ngFor="let mediaArrayItem of mediaArray">
                <app-layout [header]="mediaArrayItem">
                    <div class="defaultDashboardConsentTableItem defaultDashboardConsentTableItemHeader">
                        <div class="defaultDashboardConsentTableItem__medium">{{ 'OptInOptOut.Label.MediumRef' | texttransform}}</div>                        
                        <div class="defaultDashboardConsentTableItem__typ">{{ 'OptInOptOut.Label.TypeRef' | texttransform}}</div>                        
                        <div class="defaultDashboardConsentTableItem__entrySource">{{ 'Defaultdashboard.Label.Source' | texttransform}}</div>
                        <div class="defaultDashboardConsentTableItem__count">{{ 'Report.ProjectOverview.Anzahl' | texttransform}}</div>
                    </div>
                    <div class="defaultDashboardConsentTableItem everleadTableRow" 
                        *ngFor="let mediaData of getMediaDataArray(mediaArrayItem)">
                        <div class="defaultDashboardConsentTableItem__medium">{{ mediaData.medium }}</div>                        
                        <div class="defaultDashboardConsentTableItem__typ">{{ mediaData.typ }}</div>                        
                        <div class="defaultDashboardConsentTableItem__entrySource">{{ mediaData.entry_source }}</div>
                        <div class="defaultDashboardConsentTableItem__count">{{ mediaData.count }}</div>
                    </div>
                </app-layout>
            </div>
        </div>
        <div *ngIf="loadingData == false && viewChart == true" class="defaultDashboardConsentTableContainer">
            <div *ngFor="let mediaArrayItem of mediaArray">
                <app-layout [header]="mediaArrayItem">
                    <app-bar-chart 
                        [height]="getChartHeight(getMediaDataArray(mediaArrayItem))" 
                        [chartData]="getMediaDataArray(mediaArrayItem, true)" 
                        component="consentDashboardBar" 
                        [consentRef]="sortArrayId"
                        [controlid]="agentwvlChart">
                    </app-bar-chart>
                </app-layout>
            </div>
        </div>
    </app-layout>
</div>