import { Component, OnInit,Inject } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { MiddlewareSignalrService } from 'app/jollyjupiter/signalr/middleware-signalr-service.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';
import { environment } from 'environments/environment';
import {DOCUMENT} from "@angular/common";
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';

@Component({
  selector: 'app-ajando-header',
  templateUrl: './ajando-header.component.html',
  styleUrls: ['./ajando-header.component.scss']
})
export class AjandoHeaderComponent implements OnInit {
  availableActivitys = [];
  searchActive = false;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    private methodService: MethodService,
    private commonService: CommonService,
    private userService: UserService,
    public middlewareSignalrService: MiddlewareSignalrService,
    private awsCognitoService: AwsCognitoService,
    public ringcentralwebphoneService: RingcentralwebphoneService,
    @Inject(DOCUMENT) private document: Document

  ) { }

  ngOnInit() {
    // this.eventService.customEvent.subscribe(event => {
    //   if (event.id == 'toggleMobileMenu') {
    //     this.toggleNavigation()
    //   }
    // });
  }

  onClickAxivasLogo() {
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'dashboard']);
  }

  showProjectSelection() {
    this.eventService.showJjPopup('StaticForm.ProjectSelection.Header.Header', 'projectselection', '700px;80');
    this.applicationInfoService.campaingnModeId
  }

  showProjectSettings() {
    if (this.applicationInfoService.blockSettingChange == true) {
      return;
    }
    this.applicationInfoService.miscSettings['showProjectSettings'] = true;
    this.eventService.showJjPopup('StaticForm.ProjectSelection.ProjectSettings', 'projectselection', '50');
  }

  toggleNavigation(e) {
    // document.body.classList.toggle("overflowHidden");
    // e.target.classList.toggle("show");
    this.applicationInfoService.navigationExpandedMobile = !this.applicationInfoService.navigationExpandedMobile;
    this.applicationInfoService.navigationExpanded = !this.applicationInfoService.navigationExpanded;
  }

  changeView() {
    if (this.applicationInfoService.isDeveloper) {
      this.applicationInfoService.useNewDesign = !this.applicationInfoService.useNewDesign;
    }
  }

  getSubHeaderText() {
    let returnValue = '';
    let userGroupString = null;
    const userGroup = this.applicationInfoService.availableUserGroups.filter(x => x.id ===
      this.applicationInfoService.currentUserGroup)[0];
    if (!this.commonService.isNullOrUndefined(userGroup)) {
      userGroupString = userGroup.defaultName;
    }
    if (!this.commonService.isNullOrUndefined(userGroupString)) {
      userGroupString = this.axivasTranslateService.getTranslationTextForToken('AutomaticRecordAssignment.Label.UserGroup')
      .concat(': ', this.axivasTranslateService.getTranslationTextForToken(userGroupString));
    }

    let poolString = '';
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.projectInfo)) {
       poolString = this.applicationInfoService.projectInfo.pools.filter(x => x.id ===
        this.applicationInfoService.poolId)[0].defaultName;
      if (!this.commonService.isNullOrUndefined(poolString)) {
        poolString = 'Pool'
        .concat(': ', this.axivasTranslateService.getTranslationTextForToken(poolString));
      }
    }

    let userRoleString = '';
    if (!this.commonService.isNullOrUndefined(this.userService)) {
      if (!this.commonService.isNullOrUndefined(this.userService.userRoles)
        && !this.commonService.isNullOrUndefined(this.applicationInfoService.currentUserRoleId)) {
        if ((this.applicationInfoService.currentUserRoleId)[0]) {
        userRoleString = this.userService.userRoles.filter(x => x.id ===
          this.applicationInfoService.currentUserRoleId)[0].nameTranslationToken.tokenName;
        }
      }
      if (!this.commonService.isNullOrUndefined(userRoleString)) {
        userRoleString = this.axivasTranslateService.getTranslationTextForToken('TopLevelHeader.Label.Role')
        .concat(': ', this.axivasTranslateService.getTranslationTextForToken(userRoleString));
      }
    }

    returnValue = ''.concat(userGroupString, ' - ', poolString, ' - ', userRoleString);
    return returnValue;
  }

  getUserRole(): string {
    let returnvalue = '';
    this.userService?.userRoles.forEach(role => {
      if (role.roleId === this.applicationInfoService.currentUserRoleId) {
        returnvalue = role.role.nameTranslationToken?.tokenFullName
      }
    })
    return returnvalue;
  }

  getUserFollowupGroup(): string {
    let returnvalue = '';
    const userGroup = this.applicationInfoService?.availableUserGroups.find(group => group.id == this.applicationInfoService.currentUserGroup);
    if (userGroup) {
      if (userGroup.nameTranslationToken) {
        returnvalue = userGroup.nameTranslationToken?.tokenFullName;
      } else {
        returnvalue = userGroup.defaultName;
      }
    }
    return returnvalue;
  }

  handleQuickSearchKeyDown(event: any) {
    if (event.keyCode === 13 || event.keyCode === 14) {
      this.searchForValue();
    }
  }

  searchForValue() {
    if (this.applicationInfoService.quickSearchValue === '' || this.searchActive) {
      return;
    }
    // console.warn('searchForValue', this.applicationInfoService.quickSearchValue);
    this.searchActive = true;
    this.eventService.showJjPopup(this.axivasTranslateService
      .getTranslationTextForToken('General._.QuickSearch'), 'quicksearch', '80', true);
    setTimeout(() => this.searchActive = false, 1000);
  }

  getModeText(){
    return (this.applicationInfoService.designerMode ? "Designermodus deaktivieren" : "Designermodus aktivieren" )
  }

  showUserSettings() {
    this.applicationInfoService.routerHeaderLabelText = 'General.Menu.Settings';
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'usersettings']);
    // this.router.navigate(['../' + this.applicationInfoService.mainFrameName + '/usersettings']);
    if (this.applicationInfoService.isMobile()) {
      this.applicationInfoService.navigationExpandedMobile = false;
    }
  }

  showLargeSettingButtons() {
    if (this.applicationInfoService.innerWidth < 1100) {
      return false;
    } else {
      return true;
    }
  }

  getPoolName() {
    if (this.applicationInfoService.poolId == null) {
      return '';
    } else {
      const pool = this.applicationInfoService.projectInfo?.pools.find(pool => pool.id == this.applicationInfoService.poolId);
      if (pool) {
        return pool.defaultName;        
      } else {
        return '';
      }
    }
  }

  logout() {
    this.awsCognitoService.logoutUserFromCognito();
  }

  getProjectBackgroundImage(){
    let imageUrl = ''
    if (this.applicationInfoService.projectInfo != null) {
      imageUrl = (this.applicationInfoService.projectInfo.projectBanner) ?
      this.applicationInfoService.projectInfo.projectBanner : "assets/images/everleadtheme/EVERLEAD_header_midblue.png";
    } else {
      imageUrl = "assets/images/everleadtheme/EVERLEAD_header_midblue.png";
      return
    }
    return  "url('" + imageUrl +  "')";
  }

  getCampaignModeCampaign() {
    const campaign = this.applicationInfoService.campaigns.toArray().find(campaign => campaign.id == this.applicationInfoService.campaingnModeId);
    if (campaign) {
      return campaign.defaultName;
    } else {
      return '';
    }
  }
}
