import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-defaultdashboard-opportunity',
  templateUrl: './defaultdashboard-opportunity.component.html',
  styleUrl: './defaultdashboard-opportunity.component.scss'
})
export class DefaultdashboardOpportunityComponent implements OnInit {
  @Input() reports = null;
  @Input() selectedReport = null;

  dashboardResultData = [];
  funnelValue = { data: [], type: 'funnel' };
  funnelAmount = { data: [], type: 'funnel' };
  tableData = [];

  showFunnelData = false;
  showFunnelAmount = false;
  dashboardId = 16;  
  poolId = null;
  pools = [];
  campaignId = null;

  opportunityTimeRanges = [
    { id: 1, defaultName: 'OpportunityDashboard.Label.OpportunityLastYear' },
    { id: 2, defaultName: 'OpportunityDashboard.Label.OpportunityThisYear' },
    { id: 3, defaultName: 'OpportunityDashboard.Label.OpportunityCurrentQuarter' },
    { id: 4, defaultName: 'OpportunityDashboard.Label.OpportunityCustom' }
  ];
  timeRange = 2;
  dateFrom = null;
  dateUntil = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private dashboardcoreService: DashboardcoreService,
    private commonService: CommonService,
    private uiService: UiService
  ) {}

  ngOnInit(): void {    
    this.getPools();
    this.dashboardId = this.selectedReport.dashboardId;
    this.campaignId = this.applicationInfoService.campaingnModeId;
    this.poolId = this.applicationInfoService.poolId;
    this.getDashboardDataArray(this.dashboardId);    
  }

  getDashboardDataArray(dashboardId) {
    let params = this.fillParams();
    this.showFunnelData = false;
    this.showFunnelAmount = false;
    this.dashboardResultData[dashboardId] = null;
    this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(getDashboardDataArrayResult => {
      this.dashboardResultData[dashboardId] = null;
      if (getDashboardDataArrayResult) {
        if (getDashboardDataArrayResult[0]) {
          this.dashboardResultData[dashboardId] = JSON.parse(JSON.stringify(getDashboardDataArrayResult[0]));
          this.tableData = this.dashboardResultData[dashboardId];
          this.getValueFunnelData();
          this.getValueAmountData();
        } else {
          this.dashboardResultData = [];
          this.funnelValue = { data: [], type: 'funnel' };
          this.funnelAmount = { data: [], type: 'funnel' };
          this.tableData = [];
          this.getValueFunnelData();
          this.getValueAmountData();
        }
      }
    }).catch((error)=>{
    });
  }

  getPools() {
    this.pools = this.applicationInfoService.pools.toArray().filter(pool => pool.isActive == true);
  }

  fillParams() {
    const params = [];    
    switch(this.timeRange) {
      case 1:
        this.dateFrom = new Date(new Date().getFullYear(), 0, 1);
        this.dateUntil = new Date(new Date().getFullYear(), 11, 31);
        this.dateFrom.setFullYear( this.dateFrom.getFullYear() - 1 );
        this.dateUntil.setFullYear( this.dateUntil.getFullYear() - 1 );
        break;
      case 2:
        this.dateFrom = new Date(new Date().getFullYear(), 0, 1);
        this.dateUntil = new Date(new Date().getFullYear(), 11, 31);
        break;
      case 3:
        var today = new Date();
        var quarter = Math.floor((today.getMonth() + 3) / 3);
        switch(quarter) {
          case 1:
            this.dateFrom = new Date(new Date().getFullYear(), 0, 1);
            this.dateUntil = new Date(new Date().getFullYear(), 2, 31);    
            break;
          case 2:
            this.dateFrom = new Date(new Date().getFullYear(), 3, 1);
            this.dateUntil = new Date(new Date().getFullYear(), 5, 30);    
            break;
          case 3:
            this.dateFrom = new Date(new Date().getFullYear(), 6, 1);
            this.dateUntil = new Date(new Date().getFullYear(), 8, 30);    
            break;      
          case 4:
            this.dateFrom = new Date(new Date().getFullYear(), 9, 1);
            this.dateUntil = new Date(new Date().getFullYear(), 11, 31);    
            break;        
        }
        break;
    }
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@pool_id', value: this.poolId });
    params.push({ key: '@campaign_id', value: this.campaignId });
    params.push({ key: '@created_date_from', value: this.dateFrom });
    params.push({ key: '@created_date_until', value: this.dateUntil });
    return params;
  }

  getValueFunnelData() {
    this.funnelValue.data = [];
    this.dashboardResultData[this.dashboardId].sort((a, b) => a.factor < b.factor ? -1 : 1);
    this.dashboardResultData[this.dashboardId].forEach(dataItem => {
      this.funnelValue.data.push({        
        name:  dataItem.default_name,
        value: dataItem.value
      }); 
    });
    this.showFunnelData = true;
  }

  getValueAmountData() {
    this.funnelAmount.data = [];
    this.dashboardResultData[this.dashboardId].sort((a, b) => a.factor < b.factor ? -1 : 1);
    this.dashboardResultData[this.dashboardId].forEach(dataItem => {
      this.funnelAmount.data.push({        
        name:  dataItem.default_name,
        value: dataItem.amount
      });       
    });
    this.showFunnelAmount = true;
  }

  opportunityTimeRangeChanged() {

  }


  getMaxHeight(additionalValueToSubstractFromHeight){
    return Number(this.uiService.getDesignSpanPositionMobile('defaultDashboardOpportunity', (50) + additionalValueToSubstractFromHeight))
  }
}
