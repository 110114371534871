<app-layout [header]="'WebsiteDashboard.Label.MyTopContacts' | texttransform" [ellipsisHeader]="true">

    <div class="dashboardMyTopContacts">
        <div class="dashboardMyTopContacts__inner">

            <div class="dashboardMyTopContacts__tabsMobile">
                <div class="everleadMobileTab">
                    <div class="everleadMobileTab__valueWrapper">
                        <select [(ngModel)]="page" class="selectTab__box">
                            <option [ngValue]="0">{{'WebsiteDashboard.Label.ContactPerson' | texttransform }}</option>
                        </select>
                    </div>
                </div>
            </div>


            <div class="dashboardMyTopContacts__tabs">
                <app-tab svg="calendar-dots" [active]="page == 0" [label]=" 'WebsiteDashboard.Label.ContactPerson' | texttransform" (onClick)="page = 0"></app-tab>
            </div>
            <div *ngIf="page === 0" class="dashboardMyTopContacts__page">
                <div class="dashboardMyTopContacts__page__header">
                    <h2>{{ 'WebsiteDashboard.Label.ContactPerson' | texttransform }}</h2>                    
                </div>
                <div [ngClass]="{dashboardMyTopContacts__page__content: dashboard !== 'leadmanagment', dashboardMyTopContacts__page__contentTwo: dashboard === 'leadmanagment'}">
                    <app-mytopcontact [parentHasAI]="checkIfArrayHasAi(contacts)" [isOdd]="commonService.isOdd(rowIndex)"
                        *ngFor="let contact of contacts; let rowIndex = index" [contact]="contact" (click)="loadContact(contact.contactid)"></app-mytopcontact>
                    
                    <div *ngIf="noData" class="dashboardMyTopContacts__page__content__infoLabel">
                        <div >
                            {{ 'General.Label.NoDataAvailable' | texttransform }}<br>
                            <br>
                        </div>
                    </div>
                    <div *ngIf="loading" class="dashboardMyTopContacts__page__content__infoLabel">
                        <span class="loadingDataSpinner">
                            <br><i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
                        </span>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="footer">
            <app-main-button [label]=" 'WebsiteDashboard.Label.ToLeadscoring' | texttransform " svg="rocket" (click)="showContacts()"></app-main-button>
        </div>
    </div>
</app-layout>