<div class="questionnaireExportWrapper">
    <app-layout header="Supervisor.Label.QuestionnaireReport" class="maxHeight">
        <div class="questionnaireExportContent">
            <div class="questionnaireExportHeader">
                {{ 'QuestionnaireExport.Label.Header' | texttransform }}
            </div>
        
            <div class="questionnaireExportData">        
                <div *ngFor="let questionnaire of questionnaires" class="everleadTableRow questionnaireExportItem fingerCursor" 
                    [ngClass]="{ hideInDom: questionnaire.isActive == false }"
                    (click)="questionnaire.isExported = !questionnaire.isExported">
                    <div class="questionnaireExportItem__check">
                        <input type="checkbox" [(ngModel)]="questionnaire.isExported" (click)="$event.stopPropagation()">
                    </div>
                    <div class="questionnaireExportItem__name">
                        {{ questionnaire.defaultName }}
                    </div>                        
                </div>
            </div>
            
            <div class="questionnaireExportFooter">
                <app-main-button svg="image" (onClick)="exportQuestionnaire()" label="QuestionnaireExport.Label.StartExport"></app-main-button>
            </div>        
        </div>
    </app-layout>
</div>